import styled from '@emotion/styled'
import { Link, useStaticQuery, graphql } from 'gatsby'
import React, { useContext } from 'react'

import mailIconSrc from '../icons/mail-icon.svg'
import { headerButtonBaseCss } from './Header-Nav'
import { ContactContext } from './context/contact-context'

const ContactPanel = ({ compact }) => {
  return (
    <Container>
      <TelephoneBlock compact={compact} />
      <OnlineBlock />
    </Container>
  )
}

ContactPanel.defaultProps = {
  compact: false,
}

export default ContactPanel

const Container = styled.div`
  display: flex;
  color: #fff;
  text-align: center;
  > *:first-of-type {
    margin-right: 16px;
  }
`

const ContactBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

const TelContactBlock = styled(ContactBlock)`
  @media (max-width: ${(p) => p.theme.breakpoints.compactNav - 1}px) {
    display: none;
  }
`

const TelephoneBlock = ({ compact }) => {
  const { phonesAnswered } = useContext(ContactContext)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          telUK
        }
      }
    }
  `)

  const { telUK: telNo } = data.site.siteMetadata

  return (
    <TelContactBlock>
      {!compact && (
        <Microcopy>
          {/* <StatusPip active={phonesAnswered} /> */}
          {phonesAnswered === false ? 'Leave a Voicemail' : 'Phone Lines Open'}
        </Microcopy>
      )}
      <TelButton phonesAnswered={phonesAnswered} href={`tel:${telNo}`}>
        {telNo}
      </TelButton>
    </TelContactBlock>
  )
}

// const StatusPip = styled.div`
//   background: ${(p) => p.theme.colors.secondary};
//   border: 1px solid rgba(255, 255, 255, 0.3);
//   height: 0.7em;
//   width: 0.7em;
//   border-radius: 999px;
//   margin-right: 0.4em;
//   position: relative;
//   top: 0.12em;
//   margin-left: -0.2em;
//   ${(p) => p.active !== true && 'display: none;'}
// `

const Microcopy = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 12px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(p) => p.theme.breakpoints.compactNav - 1}px) {
    display: none;
  }
`

const TelButton = styled.a`
  ${headerButtonBaseCss};

  background: ${(p) => p.theme.colors.primary};
  border-color: ${(p) => p.theme.colors.secondary};

  transition: border-color 0.2s;
  ${(p) =>
    p.phonesAnswered !== true && 'border-color: rgba(255, 255, 255, 0.15);'};
`

const OnlineButton = styled(Link)`
  ${headerButtonBaseCss};

  background: ${(p) => p.theme.colors.primary};
  border-color: ${(p) => p.theme.colors.secondary};

  @media (max-width: ${(p) => p.theme.breakpoints.compactNav - 1}px) {
    background: none;
    border: none;
    justify-content: center;
    padding: 0;
    height: 36px;
    width: 36px;

    .icon {
      position: static;
      margin: 0;
      height: 24px;
    }
    .button__text {
      display: none;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpoints.compactNav}px) {
    .icon {
      display: none;
    }
  }
`

const OnlineBlock = ({ compact }) => {
  return (
    <ContactBlock>
      {!compact && <Microcopy>Free Assessment</Microcopy>}
      <OnlineButton to="/contact/">
        <span className="button__text">Contact Online</span>
        <img
          className="icon"
          src={mailIconSrc}
          alt="Contact Visa Kings Global"
          height={24}
          width={24}
        />
      </OnlineButton>
    </ContactBlock>
  )
}
