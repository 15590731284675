import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import ilpaLogoSrc from '../img/ilpa-logo.svg'
import lexcelLogoSrc from '../img/lexcel-logo.svg'
import lawSocietyLogoSrc from '../img/the-law-society-logo.svg'

const WhyVisaKings = () => {
  return (
    <Container>
      <h3 className="heading heading--h4">
        We deliver expert solutions to clients' immigration needs
      </h3>
      <BrochuresImageWrapper>
        <StaticImage
          src="../img/vkg-brochures-fan.png"
          alt="Visa Kings Global immigration brochures"
          loading="eager"
          layout="constrained"
          width={480}
          className="brochuresImage"
          placeholder="none"
          formats={['auto', 'webp', 'avif']}
        />
      </BrochuresImageWrapper>
      <div className="logos">
        <img
          src={lawSocietyLogoSrc}
          style={{ height: 120, width: 'auto' }}
          alt="The Law Society logo"
          width="798"
          height="523"
          loading="lazy"
        />
        <img
          src={lexcelLogoSrc}
          alt="Lexcel Law Society Accredited logo"
          width="122"
          height="62"
          style={{ height: 84, width: 'auto' }}
          loading="lazy"
        />
        <img
          src={ilpaLogoSrc}
          alt="Immigration Law Practitioners' Association logo"
          height="120"
          width="120"
          loading="lazy"
        />
      </div>
    </Container>
  )
}

const BrochuresImageWrapper = styled.div`
  .brochuresImage {
    margin-top: 24px;

    @media (min-width: ${(p) => p.theme.breakpoints.compactNav}px) {
      margin-top: 48px;
      margin-right: -80px;
      width: calc(100% + 80px);
      margin-left: auto;
      transform: rotate(-18deg);
    }
  }
`

const Container = styled.aside`
  flex: 1 0 auto;
  max-width: 320px;
  margin-top: 40px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;

  .heading {
    font-weight: 600;
    margin: 0 auto;
    line-height: 1.3;
  }

  .logos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-top: 48px;
    }

    img {
      filter: grayscale(100%);
      opacity: 0.4;
      margin: 24px 16px;
    }
  }
`

export default WhyVisaKings
