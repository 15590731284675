import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'

const FamilyVisaPage = () => (
  <Layout>
    <Helmet title="UK Family Visa: Live in the UK with Your Family">
      {/* <meta name="description" content="" /> */}
      {/* <meta property="og:title" content="" /> */}
      {/* <meta property="og:description" content="" /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <article>
          <header style={{ marginTop: 40 }}>
            <h1 className="heading heading--h1 heading--center">
              UK Family Visa
            </h1>
            <p className="hook">
              If you are planning to stay in the UK with your family for over
              six months, you will need to apply for a Family visa.
            </p>
          </header>
          <section>
            <h2 className="heading heading--h2">
              Are you looking to settle as a family or married couple in the UK?
            </h2>
            <p>
              Whether you’re joining your partner, a dependent, the spouse of a
              UK/EU national, or looking to bring your spouse and children here,
              we can help. Our team is committed to ensuring that your family is
              reunited and settled as smoothly as possible.
            </p>
            <p>
              If you are a UK citizen or a person with UK settled status and are
              engaged to be married to a person from outside the UK and the
              European Economic Area (EEA), your partner may apply for a Fiancé
              visa in order to join you in the UK. You must have the intention
              of getting married within the six month period of the visa.
            </p>
            <p>
              As with any visa process, the success of your application depends
              on several factors, including the documentation you provide, your
              knowledge of the English language, your current visa status, and
              in some cases, your private life.
            </p>
            <div>
              <h3 className="heading heading--h3">
                We provide a range of services to help demystify the application
                process
              </h3>
              <ul className="list">
                <li>
                  Reviewing your situation and identifying the best way to get
                  your application approved
                </li>
                <li>
                  Helping you gather the documentation required to submit a
                  successful application
                </li>
                <li>
                  In cases where your visa status changes (death of a spouse,
                  divorce, extension of visit, completion of education), we will
                  guide you through the process of switching over to or from a
                  family visa
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Are you a European Economic Area (EEA) citizen?
            </h2>
            <div>
              <h3 className="heading heading--h3">
                Our team can provide the following services
              </h3>
              <ul className="list">
                <li>
                  Establishing your eligibility for "settled status" in the UK
                </li>
                <li>
                  Helping students and young people access educational
                  institutions now that the UK has left the EU
                </li>
                <li>
                  Keeping you up to date with policies affecting tuition fees
                  and your ability to access loans and grants
                </li>
              </ul>
            </div>
          </section>
        </article>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'personalUK',
        services: ['familyVisa'],
      }}
    />
    <TestimonialsSection />
  </Layout>
)

export default FamilyVisaPage
