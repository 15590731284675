import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'

const NotFoundPage = () => (
  <Layout>
    <div className="container article">
      <Helmet title="404 Not Found" />
      <h1>Page Not Found</h1>
    </div>
  </Layout>
)

export default NotFoundPage
