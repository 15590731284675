import styled from '@emotion/styled'
import React from 'react'

const About = () => (
  <Container>
    <div className="container compact" style={{ maxWidth: 620 }}>
      <Heading className="heading heading--accent heading--h2">
        About the <br />
        Innovator visa
      </Heading>
      <p>
        The Innovator visa is the replacement for the Tier 1 (Entrepreneur)
        category. It is for the more experienced, proven business entrepreneur
        who wishes to establish a viable business in the UK with real prospects
        of growth.
      </p>
      <Subheading>
        What are the key criteria to obtain an Innovator visa?
      </Subheading>
      <ul className="list">
        <li>An original business plan demonstrating innovation</li>
        <li>Support from an Endorsing Body</li>
        <li>Business investment from just £50,000</li>
        <li>An acceptable level of English</li>
        <li>Adequate maintenance funds (unless exempted)</li>
      </ul>

      <Subheading>How long can you stay in the UK?</Subheading>
      <p>
        The duration of your entry clearance is three years. After that you can
        apply for ILR (settlement) in the UK. There are various conditions to
        meet. Visa Kings Global can guide you on the best way to meet these
        conditions.
      </p>

      <Subheading>Support from the Endorsing Body</Subheading>
      <p>
        This is absolutely most vital to the success of your application. It is
        there to make sure you will bring benefits to the UK economy.
      </p>
      <p>
        We have worked for six years in the start-up and new business sector as
        our specialism. We have helped to raise millions of pounds for innovator
        companies and have dealt with venture capitalists and Fund Managers. We
        understand what they are looking for and we are rare in the immigration
        field in having that specialised knowledge.
      </p>
    </div>
  </Container>
)

const Container = styled.section`
  padding-top: 48px;
  padding-bottom: 60px;
`

const Heading = styled.h2`
  margin: 0;
  text-align: center;
`

const Subheading = styled.h3`
  font-size: 1.9rem;
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: 400;
`

export default About
