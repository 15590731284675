import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { PageContentWrapper } from 'components/layouts/PageContentWrapper'
import Layout from 'components/layouts/PrimaryLayout'
import PageHeader from 'components/page-header'
import Panel from 'components/panel'
import ServiceEnquirySection from 'components/service-enquiry-section'

const Page = ({ data }) => (
  <Layout>
    <Helmet title="Second Citizenship in Dominica | Kingdom Second Citizenship Plan" />
    <PageHeader
      title="Second citizenship in Dominica"
      bgSrc={data.hero.childImageSharp.gatsbyImageData}
    >
      <strong>Offered through the Kingdom Second Citizenship plan</strong>
    </PageHeader>
    <PageContentWrapper>
      <div className="container compact">
        <p className="hook">
          Offered by the Commonwealth of Dominica, the Dominica Citizenship
          Investment Programme is one of the most affordable and flexible in the
          world. In 1993, the Dominican government waived the requirement to
          reside in Dominica, making the attractive citizenship easier to be
          obtained.
        </p>
        <Panel.Container light>
          <Panel.Header>
            <h2 className="heading heading--h4">What are the benefits?</h2>
          </Panel.Header>
          <Panel.Content>
            <ul className="list">
              <li>
                One of the oldest and most trusted citizenship by investment
                programmes
              </li>
              <li>Visa-free travel to around 120 countries</li>
              <li>The right to live, work, and study in all 28 EU countries</li>
              <li>
                Minimal taxation with no wealth, gift, inheritance, foreign
                income or capital gains tax
              </li>
              <li>Full rights to live and work in Dominica</li>
              <li>
                There is no need to reside in Dominica before or after
                citizenship is granted
              </li>
            </ul>
          </Panel.Content>
        </Panel.Container>
      </div>
      <div className="container compact">
        <section>
          <h3 className="heading--h4">What are the investment options?</h3>
          <ul className="list">
            <li>
              <strong>Real estate</strong>
              <br />
              Invest a minimum of USD 200,000, which must be held for at least
              five years
            </li>
            <li>
              <strong>Government fund</strong>
              <br />
              For single applicant, make a non-refundable USD100,000
              contribution to a government fund. USD200,000 for a family of four
            </li>
          </ul>
        </section>
        <section>
          <h3 className="heading--h4">Additional requirements</h3>
          <p>
            The only additional requirement is that you need to pass the
            due-diligence background check.
          </p>
        </section>
      </div>
    </PageContentWrapper>
    <ServiceEnquirySection
      enquiryType={{
        category: 'goldenInvestor',
        services: ['dominicaCitizenship'],
      }}
    />
  </Layout>
)

export default Page

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "img/scotts-head-dominica.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 3)
      }
    }
  }
`
