import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import KeyBenefits from 'components/cyprus/cyprus-residency-benefits'
import Header from 'components/cyprus/cyprus-residency-header'
import ParallaxImage from 'components/cyprus/parallax-img'
import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'

const CyprusResidencyPage = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "img/cyprus-limassol-marina.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet title="Cyprus Residency by Investment Services">
        <meta
          name="description"
          content="There are several routes to residency on the island of Cyprus but investing in property shortens the procedure considerably."
        />
      </Helmet>
      <Header />
      <KeyBenefits />
      <ParallaxImage
        img={{
          gatsbyImageData: hero.childImageSharp.gatsbyImageData,
          alt: 'Cyprus investment property Limassol Marina',
        }}
      />
      <div
        className="container compact"
        style={{ paddingTop: 40, paddingBottom: 72 }}
      >
        <section>
          <h2 className="heading heading--h2">
            Cyprus residency programme requirements
          </h2>
          <p>
            A Residency Permit grants you the right to live in Cyprus
            permanently, but not to undertake employment. This generally suits
            those with international business concerns or other independent
            financial means, including retirees.
          </p>
          <p>
            Investment effectively shortcuts the normal immigration requirements
            and provides near-immediate permanent residency rights to those
            investing on the island.
          </p>
          <p>
            The primary requirement is the purchase of a new-build property for
            a minimum value of €300,000 + VAT. This property can be purchased
            either by the applicant or jointly by the applicant and their spouse
            and can subsequently be rented to provide income.
          </p>
          <p>
            Secondly you will need to deposit €30,000 (+€5,000 per dependent)
            with a Cypriot bank that will be held as a form of bond for three
            years.
          </p>
          <p>
            In addition to this the state will want to know that you have the
            means to live independently in Cyprus and as such you will need to
            prove that you have income of at least €30,000 (+€5,000 per
            dependent) per annum derived from non-Cypriot sources.
          </p>
          <p>
            You need to have a clean criminal record and must not have had
            property frozen by the EU as a result of sanctions.
          </p>
        </section>
        <div>
          <h3 className="heading heading--h2">Our services</h3>
          <p>
            Visa Kings Global provide a complete service for clients seeking
            residency rights and citizenship for Cyprus.
          </p>
          <p>
            Our Cyprus and UK-based teams will guide you through options,
            identify suitable investment property and handle all legal matters
            required for your application.
          </p>
          <p>
            If you are thinking about emigrating to Cyprus then it makes sense
            to seek the right advice. To find out more about applying for the
            Cyprus Permanent Residency Permit or Citizenship by Investment then
            please get in touch for an initial conversation about how our
            experts can help.
          </p>
        </div>
      </div>
      <ServiceEnquirySection
        heading="Like to know more?"
        enquiryType={{
          category: 'goldenInvestor',
          services: ['cyprusResidency'],
        }}
      />
    </Layout>
  )
}

export default CyprusResidencyPage
