import React from 'react'
import { Helmet } from 'react-helmet'

import TeamGrid from 'components/TeamGrid'
import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const TeamPage = () => (
  <Layout>
    <Helmet title="The Visa Kings Global Team: Experts in Immigration Law &amp; Business Immigration" />
    <ContentSection>
      <header className="container compact " style={{ textAlign: 'center' }}>
        <h1 className="heading heading--h1">The Visa Kings Global Team</h1>
        <p className="hook">
          With a multi-disciplinary team of experienced immigration lawyers and
          specialists, Visa Kings Global can provide expert advice and effective
          management of visa applications and related matters.
        </p>
        <p>
          Several members of our team have themselves made the UK their home –
          having been through UK immigration with their families, and
          understanding better than anyone else how to help you during this
          process.
        </p>
      </header>
      <div className="container collapse" style={{ maxWidth: 840 }}>
        <TeamGrid />
      </div>
    </ContentSection>
    <TestimonialsSection />
    <CallToAction />
  </Layout>
)

export default TeamPage
