import Joi from 'joi'
import { object as yupObject, string as yupString } from 'yup'

const namePattern = /^[\p{L}\s'.,()-]+$/u
const countryPattern = /^[A-Za-z\s]+$/

const schema = yupObject().shape({
  firstName: yupString()
    .min(2, 'Too Short')
    .max(128, 'Too Long')
    .matches(namePattern, { message: 'Please only use letters' })
    .required('Required'),
  lastName: yupString()
    .min(2, 'Too Short')
    .max(128, 'Too Long')
    .matches(namePattern, { message: 'Please only use letters' })
    .required('Required'),
  email: yupString().email('Invalid email').lowercase().required('Required'),
  tel: yupString().min(8, 'Too Short').max(20, 'Too Long').required('Required'),
})

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  tel: '',
}

const webinarForm = {
  schema,
  initialValues,
}

export default webinarForm

export const joiSchema = Joi.object({
  firstName: Joi.string()
    .trim()
    .min(2)
    .max(128)
    .pattern(namePattern)
    .required()
    .label('First Name'),
  lastName: Joi.string()
    .trim()
    .min(2)
    .max(128)
    .pattern(namePattern)
    .required()
    .label('Last Name'),
  email: Joi.string()
    .trim()
    .email({ tlds: false }) // for consistency with yup
    .lowercase()
    .required()
    .label('Email'),
  tel: Joi.string().trim().min(8).max(20).required().label('Telephone'),
  country: Joi.string()
    .trim()
    .min(2)
    .max(128)
    .pattern(countryPattern)
    .label('Country'),
})
