import styled from '@emotion/styled'
import React from 'react'

import ideaIconSrc from '../../icons/idea-icon.svg'

const FindingInnovation = () => (
  <Container>
    <div className="container compact" style={{ maxWidth: 620 }}>
      <img
        src={ideaIconSrc}
        alt="Innovative idea lightbulb icon"
        loading="lazy"
        style={{
          margin: '0 auto 4px',
        }}
        height={54}
        width={54}
      />
      <Heading className="heading heading--h2 heading--accent">
        Discover your <br />
        innovation
      </Heading>
      <p className="hook">
        Having an original business idea is absolutely vital to a successful
        Innovator or Start-up visa application.
      </p>
      <p>
        All types of business are possible — none are excluded. What matters is
        innovation, viability and the ability to scale.
      </p>
      <p className="hook">
        Visa Kings Global have valuable expertise in this area.
      </p>
      <p>
        Our team has a proven track record and through years of experience
        working in the start-up sector, we have directly helped to raise
        millions of pounds for new UK businesses.
      </p>
      <p>
        Very few companies in the immigration field have experience in raising
        funds for young companies in the sub £250,000 sector to assist British
        Citizen Entrepreneurs. This competency is now essential when proposing a
        viable business — the most important aspect of Innovator and Start-up
        visa applications when seeking endorsement.
      </p>
      <p>
        Visa Kings Global apply our significant experience of preparing and
        presenting innovative business plans to ensure our clients have the{' '}
        <strong>greatest chances of success</strong> under the Innovator and
        Start-up visas.
      </p>
    </div>
  </Container>
)

const Container = styled.section`
  padding-top: 40px;
  padding-bottom: 60px;
`

const Heading = styled.h2`
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-size: 4rem;
  }
  text-align: center;
  margin: 0;
`

export default FindingInnovation
