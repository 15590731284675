import React from 'react'
import styled from '@emotion/styled'

export default () => (
  <Panel>
    <p>
      Take your skills and ambition to new heights by establishing and growing a
      start-up business in the UK.
    </p>
    <p>
      Our comprehensive legal and business services help you access the many
      benefits of <strong>UK residency</strong> and start your path to{' '}
      <strong>British Citizenship</strong>.
    </p>
  </Panel>
)

const Panel = styled.div`
  max-width: 360px;
  color: ${(p) => p.theme.colors.primary};
  margin-left: auto;
  margin-right: auto;
  padding: 12px 20px;
  background: rgba(251, 255, 248, 0.9)
    linear-gradient(#fff, rgba(255, 255, 255, 0.5) 57%);
  border: 2px solid #fff;
  box-shadow: 0 2px 30px 3px ${(p) => p.theme.colors.primary}22;
  border-radius: 5px;
  text-align: left;
  p {
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 8px;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 1.9rem;
    }
  }
`
