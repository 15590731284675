import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'

const Breadcrumbs = ({ title }) => {
  const location = useLocation()
  return (
    <List itemScope itemType="https://schema.org/BreadcrumbList">
      <Item
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
      >
        <Link
          to="/uk/tier-1-entrepreneur-visa/"
          itemType="https://schema.org/Thing"
          itemProp="item"
        >
          <span itemProp="name">Tier 1 Entrepreneur Visa</span>
        </Link>
        <meta itemProp="position" content="1" />
      </Item>
      <Item
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
      >
        <Link
          to={location.pathname}
          itemType="https://schema.org/Thing"
          itemProp="item"
        >
          <span itemProp="name">Kingdom {title}</span>
        </Link>
        <meta itemProp="position" content="2" />
      </Item>
    </List>
  )
}

const List = styled.ol`
  list-style: none;
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  padding: 4px 4px 3px;
  font-size: 1.6rem;
  white-space: nowrap;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    display: inline-block;
    font-size: 1.7rem;
    padding-left: 16px;
    padding-right: 16px;
    border-top-left-radius: ${(p) => p.theme.radius}px;
    border-top-right-radius: ${(p) => p.theme.radius}px;
  }
`

const Item = styled.li`
  display: inline;
  &:not(:last-child)::after {
    content: '>';
    margin-left: 8px;
    margin-right: 8px;

    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      margin-left: 6px;
      margin-right: 6px;
    }
  }

  a {
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`

export default Breadcrumbs
