import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ConciergeServices from 'components/packages/concierge-services-section'
import PillFlash from 'components/pill-flash'
import ServiceEnquirySection from 'components/service-enquiry-section'
import CallToAction from 'components/speak-your-lang-cta'

const SoleRepVisaPage = () => (
  <Layout>
    <Helmet title="Sole Representative Visa: UK Residency &amp; British Citizenship through Business Investment">
      <meta
        name="description"
        content="Establish a commercial presence in the UK through an actively trading company by sending over a senior employee as its sole representative."
      />
      {/* <meta
        property="og:title"
        content="Sole Representative Visa: UK Residency &amp; British Citizenship through Business Investment"
      />
      <meta
        property="og:description"
        content="Establish a commercial presence in the UK through an actively trading company by sending over a senior employee as its sole representative."
      /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <header style={{ marginTop: 40 }}>
          <div style={{ textAlign: 'center' }}>
            <PillFlash text="No Visa, No Fee" />
          </div>
          <h1
            className="heading heading--h1 heading--center"
            style={{ marginTop: 12 }}
          >
            Sole Representative Visa
          </h1>
          <p className="hook">
            The Representative of an Overseas Business visa (also known as a
            Sole Representative visa) is intended so that a business outside the
            United Kingdom can establish a commercial presence by sending over a
            senior employee as its sole representative.
          </p>
          <p className="hook">
            Visa Kings Global offer comprehensive Sole Representative programmes
            through the UK and the Isle of Man (part of the British Isles), with
            the Isle of Man offering a number of attractive benefits.
          </p>
        </header>
        <h2 className="heading heading--h2">How it works</h2>
        <section>
          <h3 className="heading heading--h3">
            What is a Sole Representative?
          </h3>
          <p>
            To be a sole representative you must be employed by a parent company
            in a senior job role and must have the authority to take operational
            decisions from the UK or Isle of Man.
          </p>
          <p>
            A sole representative cannot be an agent (self-employed), a sales
            representative, buyer or a secretary/personal assistant accompanying
            a sole representative. To be eligible as a sole representative you
            cannot be a majority shareholder — only those with less than a 50%
            shareholding will be considered.
          </p>
        </section>
        <section>
          <h3 className="heading heading--h3">Forming a company</h3>
          <p>
            Using the experience and support of Canfields Law (which Visa Kings
            Global is part of) we can help set up a genuine active trading
            company that reflects your skills of the non-EEA national seeking
            residence and perhaps citizenship in the United Kingdom.
          </p>
          <p>
            We have a department and close connections on the Isle of Man which
            we can utilise to ensure our clients have the best chance of a
            successful application and the right business support thereafter.
          </p>
          <p>
            Canfields Law will incorporate and help establish a branch of the
            overseas business in the UK or Isle of Man. To meet the requirements
            of the visa we will ensure that the branch exists as a legal entity
            and has a bank account and premises. We will help to employ staff
            and use our business connections wherever possible to help establish
            the new branch. The applicant will need the relevant qualifications,
            skills and experience required and must play an active role as a key
            member of the company. Complying with the requirements will ensure
            that after just five years, the applicant will be on track for
            Indefinite Leave to Remain.
          </p>
        </section>
        <section>
          <h3 className="heading heading--h3">Key benefits</h3>
          <ul className="list">
            <li>
              A sole representative can open a new branch on behalf of the
              overseas business.
            </li>
            <li>
              Excellent opportunity to expand a business into the lucrative UK
              and Irish markets.
            </li>
            <li>
              Pathway to Indefinite Leave to Remain after 5 years representing
              the parent business and thereafter obtaining a UK passport.
            </li>
            <li>
              A sole representative can bring over their family – their spouse,
              civil partner, unmarried or same-sex partner and dependent
              children.
            </li>
          </ul>
        </section>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'businessUK',
        services: ['soleRepresentativeVisa'],
      }}
    />
    <ConciergeServices />
    <TestimonialsSection />
    <CallToAction />
  </Layout>
)

export default SoleRepVisaPage
