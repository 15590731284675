import styled from '@emotion/styled'
import React from 'react'

import optionsIconSrc from '../../icons/options-icon.svg'
import cyprusDotMap from '../../img/cyprus-dot-map.svg'
import {
  BenefitsGroup,
  BenefitListItem as Benefit,
} from '../packages/plan-benefits-panel'

const KeyBenefits = () => {
  return (
    <Wrapper>
      <div className="container">
        <header>
          <h2 className="heading heading--h2">Key benefits</h2>
          <p className="hook">
            For those seeking EU residency, purchasing property in Cyprus
            represents a fast, simple and comparatively low risk option.
          </p>
          <hr className="subtle" />
          <p>
            There are several routes to residency on the island of Cyprus but
            investing in property shortens the procedure considerably.
          </p>
          <p>
            The property market in Cyprus is stable and also diverse making it
            attractive both to investors and those who wish to live on the
            island.
          </p>
          <MapWrapper>
            <img src={cyprusDotMap} alt="Map of Cyprus" loading="lazy" />
          </MapWrapper>
        </header>
        <BenefitsContainer>
          <BenefitsGroup>
            <Benefit
              title="Permanent residency for your family"
              description="Spouse, dependent children up to 25 years old, parents
              and parents in law"
              icon={{ src: optionsIconSrc }}
            />
            <Benefit
              title="Secure, stable environment"
              description="Highly-developed economy and government makes Cyprus a safe haven"
              icon={{ src: optionsIconSrc }}
            />
            <Benefit
              title="Flexible residency"
              description="No minimum stay and just one visit required for your application"
              icon={{ src: optionsIconSrc }}
            />
            <Benefit
              title="Route to Cypriot Citizenship"
              description="Eligible after 5 years providing full EU benefits including unmatched visa-free travel"
              icon={{ src: optionsIconSrc }}
            />
            <Benefit
              title="No language requirements"
              icon={{ src: optionsIconSrc }}
            />
            <Benefit
              title="Valid for life and can be inherited"
              icon={{ src: optionsIconSrc }}
            />
          </BenefitsGroup>
        </BenefitsContainer>
      </div>
    </Wrapper>
  )
}

export default KeyBenefits

const Wrapper = styled.section`
  background: ${(p) => p.theme.colors.lightTintDarker};
  background: linear-gradient(
    to top,
    ${(p) => p.theme.colors.lightTintMid} 480px,
    ${(p) => p.theme.colors.lightTintDarker}
  );
  border-top: 1px solid #fff;
  padding-top: 40px;
  padding-bottom: 40px;

  > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > header {
      margin-left: auto;
      margin-right: auto;
      flex: 1 1 320px;
      max-width: 540px;
    }
  }
`

const BenefitsContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 540px;

  display: flex;
  justify-content: center;

  text-align: center;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    text-align: left;
    padding-left: 24px;
  }
`

const MapWrapper = styled.div`
  opacity: 0.4;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    margin-top: 64px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.large}px) {
    margin-left: -32px;
    margin-right: -32px;
  }
`
