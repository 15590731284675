import styled from '@emotion/styled'
import React from 'react'
import { Helmet } from 'react-helmet'

import IomUk from 'components/business-entrepreneur-visa/iom-uk'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'

const IomTechVisaPage = () => (
  <Layout>
    <Helmet title="Fast-track visas for tech and creative jobs in the UK &amp; British Isles">
      <meta
        name="description"
        content="If you have experience in web, software, or game development (or design), we offer a tech-specific visa programme which qualifies for residence in the Isle of Man (part of the British Isles) and is an attractive path to British Citizenship."
      />
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <header>
          <Heading className="heading heading--h1 heading--center">
            <span className="techJobs">Tech Visa</span>
            <br />
            Fast-track visas for tech jobs
          </Heading>
          <p className="hook">
            If you have experience in web, software, or game development (or
            design), we offer a tech-specific visa programme which qualifies for
            residence in the Isle of Man (part of the British Isles) and is an
            attractive path to British Citizenship.
          </p>
        </header>
        <h2 className="heading heading--h2">How the plan works</h2>
        <p>
          The Isle of Man is strongly encouraging the growth of its tech sector
          and has made special provisions which streamline the process through
          which firms can hire non-EEA employees for tech and creative roles.
        </p>
        <p>
          Applicants are required to provide £100,000 as loan finance to the
          employing business and in return will benefit from a residence visa,
          guaranteed salary and also the IOM's very favourable tax regime.
        </p>
        <p>
          Through our connections, we can put forward applicants for developer,
          creative and technical management roles suited to a wide range of
          skills and experience levels.
        </p>
        <h2 className="heading heading--h2" id="techJobs">
          Tech job vacancies
        </h2>
        <section>
          <h3 className="heading heading--h3">Front End Developer Jobs</h3>
          <ul className="list">
            <li>
              <h4 className="heading heading--h4">
                Vue.js Front End Developer
              </h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Unity Front End Developer</h4>
            </li>
          </ul>
        </section>
        <section>
          <h3 className="heading heading--h3">Back End Developer Jobs</h3>
          <ul className="list">
            <li>
              <h4 className="heading heading--h4">Node.js Developer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">C, C#, C++ Developer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Java Developer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Database Engineer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">DevOps/Platform Engineer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Data Analyst</h4>
            </li>
          </ul>
        </section>
        <section>
          <h3 className="heading heading--h3">Full Stack Web Developer Jobs</h3>
        </section>
        <h2 className="heading heading--h2">Creative job vacancies</h2>
        <section>
          <h3 className="heading heading--h3">Graphic design</h3>
          <ul className="list">
            <li>
              <h4 className="heading heading--h4">Creative Director</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Art Director</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Lead Game Designer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">UX Designer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Graphic Designer</h4>
            </li>
          </ul>
        </section>
        <section>
          <h3 className="heading heading--h3">Game design</h3>
          <ul className="list">
            <li>
              <h4 className="heading heading--h4">Lead Game Designer</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">
                Character Designer / Concept Artist
              </h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Character Modeller</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Scriptwriter</h4>
            </li>
            <li>
              <h4 className="heading heading--h4">Character Animator</h4>
            </li>
          </ul>
        </section>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'businessUK',
        services: ['techVisa'],
      }}
    />
    <IomUk />
  </Layout>
)

const Heading = styled.h1`
  font-family: ${(p) => p.theme.fonts.primary};

  .techJobs {
    font-family: Menlo, Monaco, SFMono-Regular, Courier, monospace;
    font-size: 1.3em;

    &::before,
    &::after {
      color: ${(p) => p.theme.colors.secondary};
    }
    &::before {
      content: '<';
      margin-right: 0.1em;
    }
    &::after {
      content: '/>';
      margin-left: 0.1em;
    }
  }
`

export default IomTechVisaPage
