import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

import Footer from 'components/Footer'
import Header from 'components/Header'
import PromoBar from 'components/PromoBar'
import GlobalStyles from 'components/layouts/GlobalStyles'
import Overlay from 'components/overlay'

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

interface IProps {
  children: ReactNode
  focused?: boolean
}

const Layout = ({ children, focused = false }: IProps) => {
  const { webinarSession: webinars } = useStaticQuery(graphql`
    query webinars {
      webinarSession {
        timestamp
        status
      }
    }
  `)

  return (
    <LayoutContainer>
      <GlobalStyles />
      <Helmet>
        <meta property="og:site_name" content="Visa Kings Global" />
        <meta property="og:type" content="website" />
        <meta property="fb:page_id" content="741168829567836" />
      </Helmet>
      <Header />
      <div>{children}</div>
      <Footer />
      {webinars && !focused && <PromoBar />}
      <Overlay />
    </LayoutContainer>
  )
}

export default Layout
