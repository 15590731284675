import styled from '@emotion/styled'
import React from 'react'

const LocationAddress = ({
  orgName,
  streetAddress1,
  streetAddress2,
  locality,
  district,
  postalCode,
}) => (
  <div className="address">
    <span className="org">{orgName}</span>
    <span className="street1">{streetAddress1}</span>
    {streetAddress2 && <span className="street2">{streetAddress2}</span>}
    {locality && <span className="locality">{locality}</span>}
    {(district || postalCode) && (
      <span>
        {district && <span className="district">{district}</span>}
        {district && postalCode && ', '}
        {postalCode && <span className="postalCode">{postalCode}</span>}
      </span>
    )}
  </div>
)

const LocationBlock = ({
  name,
  orgName,
  address,
  telephone1,
  telephone2,
  className,
}) => (
  <div className={className}>
    <div className="locationInfo">
      <h3 className="heading heading--h4 heading--accent" style={{ margin: 0 }}>
        {name}
      </h3>
      <div className="orgName">{orgName}</div>
      <LocationAddress {...address} />
      <div className="telephoneNumbers">
        <div className="heading--h4 tel1">{telephone1}</div>
        {telephone2 && <div className="heading--h4 tel2">{telephone1}</div>}
      </div>
    </div>
  </div>
)

export default styled(LocationBlock)`
  display: flex;
  max-width: 100%;
  text-align: center;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    flex-direction: column;
    text-align: center;
  }

  .address {
    > * {
      display: block;
    }
  }

  .locationMapWrapper {
    flex: 0 0 auto;
    align-self: center;
    height: 160px;
    width: 160px;
    margin: 0;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-right: 24px;
    }
    border-radius: 999px;
    overflow: hidden;
  }

  .locationInfo {
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      margin-top: 8px;
    }
    .heading {
      font-size: 2.5rem;
    }

    .address {
      margin-top: 12px;
      line-height: 1.3;
      font-weight: 600;
    }

    .telephoneNumbers {
      margin-top: 12px;
      font-size: 2.7rem;
    }
  }
`
