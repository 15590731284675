import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

import monogramSrc from '../img/visa-kings-global-monogram-light.svg'

const KPPanel = ({
  title,
  callout,
  highlights,
  color,
  link,
  noKingdom,
  hero,
}) => {
  const hasPlanPage = !!link
  const ctaLink = hasPlanPage ? link : '/contact/'
  return (
    <KingdomPlanPanel color={color} hasPlanPage={hasPlanPage} hero={hero}>
      <header>
        <Link className="planBranding" to={ctaLink}>
          {!noKingdom && (
            <img
              className="monogram"
              src={monogramSrc}
              alt="Visa Kings Global monogram"
              width="500"
              height="344"
              style={{ height: 18, width: 'auto' }}
              loading="lazy"
            />
          )}
          <h3 className="heading heading--accent planTitle">
            {!noKingdom && <span className="planTitle__kingdom">Kingdom</span>}
            <span className="planTitle__variant">{title}</span>
          </h3>
        </Link>
        {callout && <div className="callout">{callout}</div>}
      </header>
      <div className="planInfo">
        <div className="planHighlights">{highlights}</div>
        {hasPlanPage ? (
          <Link className="ctaButton button" to={ctaLink}>
            View details
          </Link>
        ) : (
          <Link to={ctaLink} className="ctaButton button button--textOnly">
            Enquire for details
          </Link>
        )}
      </div>
    </KingdomPlanPanel>
  )
}

const KingdomPlanPanel = styled('section')`
  max-width: ${(p) => (p.hero ? 440 : 400)}px;
  background: #fff;
  border-bottom-left-radius: ${(p) => p.theme.radius}px;
  border-bottom-right-radius: ${(p) => p.theme.radius}px;
  border-top: 4px solid ${(p) => p.color || p.theme.colors.secondary};
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

  header {
    display: grid;
    grid-template-rows: 1fr 16px;
    justify-content: center;
    align-items: center;

    background: ${(p) => p.theme.colors.primary};
    color: #fff;
    min-height: ${(p) => (p.hero ? 160 : 130)}px;
    padding: 12px 16px 0;
  }

  .monogram {
    margin-top: -2px;
    margin-left: auto;
    margin-right: auto;
  }

  .planTitle {
    margin: 0;
    > * {
      display: block;
    }
    &__kingdom {
      font-size: 2.4rem;
      margin-top: -2px;
    }
    &__variant {
      font-size: ${(p) => (p.hero ? 4 : 3)}rem;
      line-height: 1;
    }
  }

  .callout {
    margin-bottom: -16px;
    color: #fff;
    background: ${(p) => p.color || p.theme.colors.secondary};
    border-radius: 999px;
    border: 2px solid #fff;
    padding: 1px 16px 3px;
    font-weight: 600;
    line-height: 24px;
    justify-self: center;
  }

  .planInfo {
    color: ${(p) => p.theme.colors.primary};
    display: grid;
    grid-template-rows: minmax(80px, 1fr) auto;
    row-gap: 16px;
    justify-content: center;
    padding: 24px 24px 24px;
  }

  .planHighlights {
    align-self: center;
    font-weight: 400;
  }

  .ctaButton {
    justify-self: center;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom-width: 4px;
    ${(p) => p.color && p.hasPlanPage && `background-color: ${p.color};`};
    padding: ${(p) => (p.hero ? '12px 40px' : '8px 32px')};
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
`

export default KPPanel
