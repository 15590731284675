import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Tier1Grid from './tier-1/tier-1-grid'

const KingdomPlansContent = styled.div`
  color: #fff;
  position: relative;
`

const Header = styled.header`
  padding: 32px 0 0;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  .heading {
    color: inherit;
    margin-bottom: 0;
  }
  .hook {
    margin-top: 8px;
    margin-bottom: 24px;
  }
`

const IntroContainer = styled.div`
  margin-bottom: 40px;
  text-align: left;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
`

const Overview = () => (
  <KingdomPlansContainer>
    <StaticImage
      src="../img/visa-gradient-bg.jpg"
      alt=""
      placeholder="none"
      layout="fullWidth"
      formats={['auto', 'webp', 'avif']}
      loading="eager"
      style={{
        position: 'absolute',
        top: 0,
        opacity: '0.1',
        width: '100%',
        objectFit: 'cover',
        minHeight: '400px',
      }}
    />
    <KingdomPlansContent>
      <div className="container compact">
        <Header>
          <h1 className="heading heading--h1">Tier 1 Entrepreneur Visa</h1>
          <p className="hook">
            A route to live and work in the UK through business investment
          </p>
        </Header>
        <IntroContainer>
          <p className="hook">
            Creating or funding a new company is a very attractive visa route
            for entrepreneurs wanting to make the UK their home.
          </p>
          {/* <p>
            Once in the UK, you and your family can enjoy many of the same
            significant benefits as citizens. This includes exceptional public
            schooling, free medical care through the National Health Service and
            a host of other advantages that come from living in a highly
            developed country with one of world's leading economies.
          </p> */}
          <p>
            Visa Kings Global offer a comprehensive Entrepreneur plan which
            provides all advisory, legal, business planning, application and
            corporate services required for an Entrepreneur visa and formation
            of your new company.
          </p>
        </IntroContainer>
      </div>
      <div className="container" style={{ maxWidth: 960 }}>
        <Tier1Grid />
      </div>
    </KingdomPlansContent>
  </KingdomPlansContainer>
)

const KingdomPlansContainer = styled.article`
  position: relative;
  background-color: ${(p) => p.theme.colors.darkTint};
  text-align: center;
  padding-bottom: 80px;
`

export default Overview
