import styled from '@emotion/styled'
import { transparentize } from 'polished'
import React from 'react'

import xIconSrc from '../../icons/x-icon.svg'

const CloseButton = ({ handleClose }) => {
  return (
    <Button onClick={handleClose} aria-label="Close">
      <img src={xIconSrc} alt="Close" height="14" width="14" />
    </Button>
  )
}

const Button = styled.button`
  position: absolute;
  right: 8px;
  top: 8px;
  height: 32px;
  width: 32px;
  padding: 0;
  border-radius: 999px;
  background: ${(p) => transparentize(0.7, p.theme.colors.darkTint)};
  box-shadow: 0 0 0 0 ${(p) => p.theme.colors.secondary};
  transition: box-shadow 0.2s;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    &:focus,
    &:active {
      outline: none;
      box-shadow: 0 0 0 3px ${(p) => p.theme.colors.secondary};
    }
  }
`

export default CloseButton
