import styled from '@emotion/styled'
import React from 'react'
import { Helmet } from 'react-helmet'

import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import Panel from 'components/panel'
import CallToAction from 'components/speak-your-lang-cta'

const LegalServicesPage = () => (
  <Layout>
    <Helmet title="UK Legal Services" />
    <ContentSection>
      <div className="container compact">
        <header>
          <h1 className="heading heading--h1 heading--center">
            UK Legal Services
          </h1>
        </header>
        <BodyContent />
      </div>
    </ContentSection>
    <CallToAction />
  </Layout>
)

export default LegalServicesPage

const LegalServicesList = styled.ul`
  display: grid;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 16px;
  margin-top: 32px;
  > li {
    font-size: 1.9rem;
    font-weight: 600;
    list-style: none;
    border-left: 4px solid ${(p) => p.theme.colors.secondary};
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
    background: ${(p) => p.theme.colors.lightTint};
    padding: 12px 16px;
    border-bottom-right-radius: ${(p) => p.theme.radius}px;
  }
`

const BodyContent = () => (
  <>
    <div>
      <p className="hook">
        Visa Kings Global's objective is always to deliver exceptional client
        service combined with clear strategic immigration advice. Our
        full-service immigration team undertake the simplest to the most complex
        of matters.
      </p>
      <p>
        As an integral part of Canfields Law, our team can assist clients with
        other related services relevant to your immigration work such as all
        types of business contracts, property acquisition and renting. We can
        also assist in finding you a suitable property to buy or rent.
      </p>
      <p>
        We provide a dedicated service to support investors, entrepreneurs and
        families moving to the UK, advising on the best solution and providing a
        high level service to help our clients relocate, obtain permanent
        residence and British Citizenship including the ability to handle
        litigation matters, judicial review cases and appeals. We can advise on
        all related issues of moving to and living in the UK through our
        concierge services.
      </p>

      <Panel.Container>
        <Panel.Header>
          <h4 className="heading heading--h4">
            Our immigration service includes
          </h4>
        </Panel.Header>
        <Panel.Content>
          <ul className="list">
            <li>Assessment of your case</li>
            <li>Consultations to explain and discuss options</li>
            <li>
              Provision of a checklist of documents required for your
              application
            </li>
            <li>
              Advice and guidance on the application process and legal
              requirements
            </li>
            <li>Full legal representation</li>
            <li>Communication with the Home Office</li>
            <li>Applications to and representations in tribunals and courts</li>
          </ul>
        </Panel.Content>
      </Panel.Container>
    </div>
    <div className="container" style={{ maxWidth: 860, marginTop: 48 }}>
      <h3
        className="heading--h3 heading--accent"
        style={{ textAlign: 'center' }}
      >
        Some of the matters we offer assistance with:
      </h3>
      <LegalServicesList>
        <li>Entry Clearance Visa / Leave to Remain Services</li>
        <li>Immigration Appeal</li>
        <li>Immigration Judicial Review</li>
        <li>Administrative Review</li>
        <li>Indefinite Leave to Remain</li>
        <li>Permanent residency</li>
        <li>10 Years Settlement Applications</li>
        <li>Long Residence Application</li>
        <li>Human Rights Law</li>
        <li>Article 8 and Article 3 Human Rights Application</li>
        <li>Fiancé / Spouse Visa Application - Appeal</li>
        <li>Parent Settlement Application</li>
        <li>Tourist Visit Visas</li>
        <li>Tier 1 Entrepreneur Visa Application and Review</li>
        <li>Tier 2 Sponsorship Licence Audit, Maintenance and Compliance</li>
        <li>Tier 2 Work Permit Application and Review</li>
        <li>Tier 2 Intra Company Transfer Visas and Review</li>
        <li>Tier 2 Sports Person Visas and Review</li>
        <li>Tier 4 Student Visa Application and Review</li>
        <li>Sole Representative Visa Applications and Review</li>
        <li>Sponsorship Licence Applications and Review</li>
        <li>Domestic Worker Visa Applications and Review</li>
        <li>Asylum and Human Rights Application</li>
        <li>Naturalisation</li>
        <li>British Citizenship</li>
        <li>British Passport Applications</li>
        <li>Deportation and Removal Injunctions</li>
        <li>Advice on Complex Immigration Matters</li>
      </LegalServicesList>
    </div>
  </>
)
