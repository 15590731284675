import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import canfieldsLogoSrc from '../img/canfields-law-logo.svg'
import ilpaLogoSrc from '../img/ilpa-logo.svg'
import lexcelLogoSrc from '../img/lexcel-logo.svg'

const Wrapper = styled.section`
  content-visibility: auto;
  contain-intrinsic-size: 0 2600px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    contain-intrinsic-size: 0 2000px;
  }

  background: ${(p) => p.theme.colors.lightTintMid};
  border-top: 4px solid ${(p) => p.theme.colors.primary};
  border-bottom: 1px solid ${(p) => p.theme.colors.primary};
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    border-bottom-width: 2px;
  }
  padding-top: 60px;

  > img {
    margin-top: 60px;
    width: 960px;
    margin-left: auto;
    margin-right: auto;
  }

  header {
    text-align: center;
    margin-bottom: 50px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    p {
      font-size: 1.9rem;
      font-weight: 400;
    }
  }
`

export const CanfieldsSection = () => (
  <div className="container" style={{ maxWidth: 600 }}>
    <p>
      Visa Kings Global is the immigration department of Canfields Law – a
      Lexcel accredited, London law practice regulated by the Solicitors
      Regulation Authority.
    </p>
    <Accreditations>
      <img
        className="canfieldsLogo"
        src={canfieldsLogoSrc}
        alt="Canfields Law logo"
        width="364"
        height="81"
        loading="lazy"
        style={{ position: 'relative', top: -4, height: 72, width: 'auto' }}
      />
      <img
        src={lexcelLogoSrc}
        alt="Lexcel Practice Management Standard Law Society Accredited"
        width="122"
        height="62"
        style={{ height: 68, width: 'auto' }}
        loading="lazy"
      />
      <img
        src={ilpaLogoSrc}
        alt="Immigration Law Practitioners' Association Member Logo"
        height="96"
        width="96"
        loading="lazy"
      />

      <JcwiAccreditation>
        <abbr title="Joint Council for the Welfare of Immigrants">JCWI</abbr>
        <p>
          Member Joint Council for
          <br />
          the Welfare of Immigrants
        </p>
      </JcwiAccreditation>
    </Accreditations>

    <LexcelReport>
      <h4 className="heading heading--h5">Our latest report</h4>

      <StaticImage
        src="../img/inspiring-business-performance-logo.png"
        alt="Inspiring Business Performance logo"
        layout="fixed"
        style={{ margin: '20px 16px 16px' }}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        width={168}
        height={72}
      />

      <div>
        <blockquote>
          <p>
            &ldquo;On behalf of IBP Limited and the Lexcel team I would like to
            take this opportunity of congratulating you on this important
            achievement.&rdquo;
          </p>
          <cite>— Lexcel Quality Manager</cite>
        </blockquote>
      </div>
    </LexcelReport>
  </div>
)

const Accreditations = styled.div`
  display: flex;
  flex-flow: row wrap;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  align-items: center;
  justify-content: center;
  justify-items: center;
  // grid-gap: 24px 4px;

  margin-top: 32px;
  margin-bottom: 32px;

  > * {
    margin: 12px; // space logos with flex fallback
  }
`

const JcwiAccreditation = styled.div`
  abbr {
    font-size: 4.1rem;
    font-weight: bold;
    text-decoration: none;
    line-height: 0.8;
  }
  p {
    font-size: 0.75em !important;
    margin-top: 0.4em;
    margin-bottom: 0;
  }
`

const LexcelReport = styled.aside`
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 40px;

  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  flex-flow: row wrap;
  align-items: center;

  blockquote {
    max-width: 380px;
    margin: 0;
    font-size: 1.6rem;
    p {
      margin: 4px 0;
      font-size: inherit;
    }
  }

  .heading {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 14px 6px;
    border-bottom-right-radius: ${(p) => p.theme.radius}px;

    background: ${(p) => p.theme.colors.darkTint};
    color: #fff;
  }

  background: #fff;
  border: 2px solid ${(p) => p.theme.colors.darkTint};
  padding: 24px 24px 32px;
  border-radius: ${(p) => p.theme.radius}px;
  box-shadow: 0 8px 40px -8px ${(p) => p.theme.colors.secondary}22;
`

const TestimonialsSection = () => {
  return (
    <Wrapper>
      <div className="container" style={{ maxWidth: 960 }}>
        <header>
          <h3 className="heading heading--h1">
            First-class service from our international team
          </h3>
          <p>
            <strong>Be confident that you are in good hands</strong> with an
            experienced team of immigration lawyers and business specialists
            around the world.
          </p>
          <CanfieldsSection />
        </header>
        <ClientTestimonials />
      </div>
      <div className="imageWrapper">
        <StaticImage
          src="../img/international-landmarks-line-drawing.png"
          alt=""
          width={900}
          style={{ margin: '40px auto 0' }}
          formats={['auto', 'webp', 'avif']}
        />
      </div>
    </Wrapper>
  )
}

export default TestimonialsSection

export const ClientTestimonials = () => {
  return (
    <TestimonialsGrid>
      <h3 className="heading heading--h4">What our clients say</h3>
      <QuotesContainer>
        <Quote featured>
          <p>
            I am impressed with the way and manner your team has carried on and
            delivered on their promise […] I have so far recommended your firm
            to two of my colleagues
          </p>
        </Quote>

        <Quote>
          <p>
            I am very pleased with the quality of services the company provides.
          </p>
        </Quote>
        <Quote>
          <p>I am particularly impressed with the team’s dedication.</p>
        </Quote>
        <Quote>
          <p>I will never forget your help and support in my life.</p>
        </Quote>
        <Quote>
          <p>Without you this journey was impossible.</p>
        </Quote>
        <Quote>
          <p>
            Very well put together. <br />
            Appreciate it – First Class
          </p>
        </Quote>
        <Quote>
          <p>
            I don't have words… how much time and effort you put into my case.
          </p>
        </Quote>
        <Quote featured>
          <p>
            I have had expert advice and guidance throughout […]
            <br />
            this firm is immeasurably awesome
          </p>
        </Quote>
      </QuotesContainer>
    </TestimonialsGrid>
  )
}

const TestimonialsGrid = styled.div`
  margin-bottom: 80px;

  .heading {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 32px;
  }
`

const QuotesContainer = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: start;
  grid-gap: 32px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Blockquote = styled.blockquote`
  max-width: 440px;
  width: 100%;
  margin: 0;
  padding-left: 24px; // prevent overlap with opening quote
  margin-left: 16px; // compensate for quote
  position: relative;
  quotes: '“' '”' '‘' '’';
  color: ${(p) => p.theme.colors.primary};

  ${(p) =>
    p.featured &&
    `
    @media (min-width: ${p.theme.breakpoints.medium}px) {
    grid-column: 1 / 3;
    max-width: 600px;
    margin: 16px 0 24px;
  }
  `}

  &::before {
    content: open-quote;
    font-family: ${(p) => p.theme.fonts.accent};
    color: ${(p) => p.theme.colors.primaryIntense};
    font-weight: 800;
    opacity: 0.5;
    position: absolute;
    top: 1.6rem;
    left: -1.6rem;
    font-size: 5.8rem;
    line-height: 0;
  }

  &::after {
    content: no-close-quote;
  }

  p {
    font-family: ${(p) => p.theme.fonts.primary};
    font-size: 2.2rem;
    font-weight: 600;
    margin: 0;
  }
`

const Quote = ({ children, featured, clientName, date }) => {
  return (
    <Blockquote featured={featured}>
      {children}
      {clientName && (
        <cite>
          — {clientName}
          {date && `, ${date}`}
        </cite>
      )}
    </Blockquote>
  )
}
