import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import About from 'components/innovator-visa/about'
import CallToAction from 'components/innovator-visa/cta'
import FindingInnovation from 'components/innovator-visa/finding-innovation'
import Header from 'components/innovator-visa/header'
import KeyInfo from 'components/innovator-visa/key-info'
import Layout from 'components/layouts/PrimaryLayout'
import ConciergeServices from 'components/packages/concierge-services-section'

const InnovatorVisaPage = () => (
  <Layout>
    <Helmet title="UK Innovator Visa Experts: No Visa, No Fee Immigration Solutions With Expert Legal & Business Support">
      <meta
        name="description"
        content="Comprehensive legal and business support for Innovator Visa applicants. Establish a business in the UK and start your path to British Citizenship."
      />
      {/* <meta
        property="og:title"
        content="UK Innovator Visa Legal & Business Immigration Services"
      />
      <meta
        property="og:description"
        content="Legal advice and comprehensive business support for Innovator Visa applicants. Establish a business in the UK and start your path to British Citizenship."
      /> */}
    </Helmet>
    <Header />
    <KeyInfo />
    <FindingInnovation />
    <CallToAction />
    <About />
    <ConciergeServices />
    <TestimonialsSection />
  </Layout>
)

export default InnovatorVisaPage
