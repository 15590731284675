import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'

const StudentVisaPage = () => (
  <Layout>
    <Helmet title="Visas for Studying in the UK: Tier 4 Student Visa Services">
      {/* <meta name="description" content="" /> */}
      {/* <meta property="og:title" content="" /> */}
      {/* <meta property="og:description" content="" /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <article>
          <header style={{ marginTop: 40 }}>
            <h1 className="heading heading--h1 heading--accent heading--center">
              Visas for Studying in the UK
            </h1>
          </header>
          <section>
            <h2 className="heading heading--h2">
              Are you looking to study in the UK?
            </h2>
            <p>
              Embarking on an educational journey in the UK is an exciting
              experience that will open the doors to endless opportunities.
              Whether you are planning to attend a boarding school, university,
              or a post-graduate programme, we will provide all the assistance
              you need to easily complete the paperwork for a student visa,
              leaving you to focus on adjusting to your new life and
              concentrating on your course of study.
            </p>
            <p>
              Tier 4 Student visas are subject to approval, based on a
              points-based system (PBS) and you will have to be sponsored by an
              educational institution that has a Tier 4 Sponsor Licence. Your
              ability to cover course fees and living expenses, as well as your
              previous immigration history will also be factors in the decision
              whether to approve your visa application.
            </p>
            <div>
              <h3 className="heading heading--h3">
                How we can help you obtain a student visa
              </h3>
              <ul className="list">
                <li>
                  Obtaining a Tier 4 Student visa for a person over 17 years of
                  age, taking a full-time course for longer than six months.
                </li>
                <li>
                  Navigating the Short-Term Student visa process for applicants
                  attending a short course or planning to study in the UK for
                  less than six months.
                </li>
                <li>
                  Applying for a Tier 4 Child Student visa, for candidates
                  between 4 and 17 years of age who wish to attend a primary or
                  secondary school in the UK.
                </li>
                <li>
                  Helping you bring a family member or dependent to live with
                  you, while you complete your course of study.
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Do you want somewhere to rent while studying?
            </h2>
            <p>
              If, while studying, you are planning to rent accommodation that is
              not student housing provided by your educational organisation, you
              will be asked to provide documents proving you have the right to
              rent and are allowed to reside in the UK.
            </p>
            <div>
              <h3 className="heading heading--h3">
                We can guide you through the process by
              </h3>
              <ul className="list">
                <li>
                  Gathering and organising the documentation proving you have
                  permission to reside in the UK
                </li>
                <li>
                  Liaising with your agent or landlord and reviewing tenancy
                  contracts
                </li>
                <li>
                  Ensuring that all required documents are up to date in the
                  case of renewing a tenancy contract
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Switching from a Student visa to a Work visa
            </h2>
            <p>You’ve got your degree – now it’s time to work!</p>
            <p>
              After completing your studies, you may have an opportunity to
              begin a career right here in the UK. Don’t get overwhelmed by
              having to apply for a new visa, instead you can rely on our expert
              advice.
            </p>
            <div>
              <h3 className="heading heading--h3">
                We can help you explore your options
              </h3>
              <ul className="list">
                <li>
                  Tier 4 Doctorate Extension Scheme: helping you get work
                  experience while completing your degree, without needing
                  advance sponsorship from employers
                </li>
                <li>
                  Switching from a Tier 4 to a Tier 2 visa once you have
                  received a job offer, a process which is much less time
                  consuming if initiated while you are still in the UK
                </li>
                <li>
                  Transitioning to a business investor/entrepreneur visa helping
                  to ensure your plan to start your own business isn’t derailed
                  by red tape
                </li>
                <li>
                  Other routes into employment: Tier 5 temporary worker visas,
                  UK ancestry applications, or special applications for
                  exceptional talent as a leader within your field
                </li>
              </ul>
            </div>
          </section>
        </article>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'personalUK',
        services: ['studentVisa'],
      }}
    />
    <TestimonialsSection />
  </Layout>
)

export default StudentVisaPage
