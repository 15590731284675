import styled from '@emotion/styled'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'

import roundedTriangleSrc from '../icons/rounded-triangle-icon.svg'
import useOutsideClick from '../lib/useClickOutside'
import { NavItemButton as DropdownButton } from './Header-Nav'

const dropdownVariants = {
  hidden: { y: 8, opacity: 0 },
  show: { y: 0, opacity: 1 },
  exit: { y: -4, opacity: 0 },
}

const DropdownNav = ({ title, navItems, children }) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)
  const node = useOutsideClick(open, () => setOpen(false))

  useEffect(() => {
    const escapeKeyHandler = (e) => {
      if (open && e.key === 'Escape') setOpen(false)
    }
    if (open) window.addEventListener('keydown', escapeKeyHandler)
    return () => {
      window.removeEventListener('keydown', escapeKeyHandler)
    }
  }, [open])

  return (
    <div ref={node} style={{ position: 'relative' }}>
      <DropdownButton onClick={toggle}>
        {title}
        <img className="icon" src={roundedTriangleSrc} alt="" />
      </DropdownButton>
      <AnimatePresence>
        {open && (
          <Dropdown
            variants={dropdownVariants}
            initial="hidden"
            animate={open ? 'show' : 'hidden'}
            exit="exit"
            transition={{ duration: 0.2 }}
          >
            {navItems && <DropdownNavList navItems={navItems} />}
            {children && children}
          </Dropdown>
        )}
      </AnimatePresence>
    </div>
  )
}

export default DropdownNav

const navItemVariants = {
  hidden: { y: 8 },
  show: { y: 0 },
}

const DropdownNavList = ({ navItems }) => {
  return (
    <motion.ul
      variants={{
        show: { transition: { staggerChildren: 0.03 } },
      }}
    >
      {navItems.map((item, i) => (
        <motion.li key={i} variants={navItemVariants}>
          {item}
        </motion.li>
      ))}
    </motion.ul>
  )
}

const Dropdown = styled(motion.div)`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: #fff;
  padding: 8px 8px;
  min-width: 160px;
  border-radius: 6px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0;
  }

  a {
    display: flex;
    padding: 8px 12px;
    white-space: nowrap;
    font-weight: 500;
    border-radius: 6px;
    &:hover {
      color: ${(p) => p.theme.colors.primaryIntense};
      background: rgba(0, 0, 0, 0.05);
    }
  }
`
