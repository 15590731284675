import styled from '@emotion/styled'
import {
  format,
  differenceInCalendarDays,
  isToday,
  isTomorrow,
  isPast,
} from 'date-fns'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState, useEffect, MouseEvent } from 'react'

import Countdown from './Countdown'

const DISMISSED_KEY = 'webinarBarDismissed'

const PromoBar = () => {
  const { webinarSession: nextWebinar } = useStaticQuery(graphql`
    query nextWebinar {
      webinarSession {
        name
        dateTime
        status
      }
    }
  `)

  if (!nextWebinar || nextWebinar.status !== 'scheduled') return null

  const eventDateObj = new Date(nextWebinar.dateTime) // Should be timeZoned
  const localEventDateObj = new Date(nextWebinar.dateTime) // Takes UTC String

  // Localised
  const eventDay = format(localEventDateObj, 'EEEE') // day of week
  const eventTime = format(localEventDateObj, 'HH:mm')

  const eventStarted = isPast(eventDateObj)
  const formattedDate = format(localEventDateObj, 'do LLL')

  let dateOrDay = formattedDate
  if (differenceInCalendarDays(localEventDateObj, new Date()) < 7) {
    dateOrDay = isToday(localEventDateObj)
      ? 'Today'
      : isTomorrow(localEventDateObj)
      ? 'Tomorrow'
      : eventDay
  }

  if (eventStarted) return null

  const [collapsed, setCollapsed] = useState<boolean | null>(null)

  const collapse = () => {
    setCollapsed(true)
    window.sessionStorage.setItem(DISMISSED_KEY, 'true')
  }

  useEffect(() => {
    if (collapsed === null)
      setCollapsed(window.sessionStorage.getItem(DISMISSED_KEY) === 'true')
  })

  const handleBarClick = (e: MouseEvent) => {
    e.stopPropagation()
    if (collapsed) {
      setCollapsed(false)
      window.sessionStorage.removeItem(DISMISSED_KEY)
    }
  }

  return (
    <Bar onClick={handleBarClick} collapsed={!!collapsed}>
      {!collapsed && <button onClick={collapse}>&times;</button>}
      <div className="container collapse">
        <div className="info container">
          <h4 className="heading heading--h5 ctaTitle">
            👉 Live Webinar {`${dateOrDay} ${eventTime}`}
          </h4>
          <div className="countdownContainer smallHide">
            {/* Should be UTC string */}
            {!collapsed && <Countdown dateString={nextWebinar.dateTime} />}
          </div>
        </div>
        <div className="content container">
          <h5 className="heading heading--h5 eventTitle">{nextWebinar.name}</h5>
          {!collapsed && (
            <>
              <p className="eventDescription smallHide">
                Expert Analysis + Live Q&amp;A with Harvey Shulman
              </p>
              <a
                href="/webinar/register"
                className="button button--small button--onDark"
              >
                Register a Free Place
              </a>
            </>
          )}
        </div>
      </div>
    </Bar>
  )
}

type BarProps = {
  collapsed?: boolean
}

const Bar = styled.aside`
  background: linear-gradient(
    to bottom,
    #112959,
    ${(p) => p.theme.colors.primary} 40px
  );

  padding-bottom: 6px;

  border-top: 2px solid ${(p) => p.theme.colors.secondary};

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  ${(p: BarProps) => p.collapsed && 'cursor: pointer;'}

  > button {
    position: absolute;
    right: 12px;
    top: 4px;
    background: 0;
    border: 0;
    color: #fff;
    font-size: 2.4rem;
    height: 24px;
    width: 24px;
  }

  > .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  color: #fff;

  .info {
    text-align: center;
    margin-top: 10px;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-top: 10px;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .content {
    max-width: 540px;
    text-align: center;
    margin-top: 10px;
    @media (min-width: ${(p) => p.theme.breakpoints.compactNav}px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
      text-align: left;
    }
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      ${(p) => p.collapsed && 'display: none;'}
      margin-top: 0;
    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 8px;
    line-height: 1.3;

    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      font-size: 1.8rem;
    }
  }

  .eventDescription {
    font-size: 0.9em;
    margin: 0;
    margin-top: -6px;
  }
  .button {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .smallHide {
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      display: none;
    }
  }
`

export default PromoBar
