import styled from '@emotion/styled'
import { format } from 'date-fns'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'

import Countdown from 'components/Countdown'
import WebinarRegisterForm from 'components/form/webinar-register-form'
import {
  ClientTestimonials,
  CanfieldsSection,
} from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/LandingPageLayout'
import { ModalWrapper, Modal } from 'components/webinar/RegisterModal'

import scrollToEl from '../../lib/scroll-to-el'

const WebinarRegisterPage = () => {
  const { webinarSession: nextWebinar } = useStaticQuery(graphql`
    query nextWebinarReg {
      webinarSession {
        name
        dateTime
        status
      }
    }
  `)

  if (!nextWebinar || nextWebinar.status !== 'scheduled') navigate('/')

  const formattedDate = format(
    new Date(nextWebinar.dateTime),
    'do LLLL h:mm b O',
  )

  const [modalVisible, setModalVisible] = useState(false)
  const [registerSuccess, setRegisterSuccess] = useState(false)
  const registrationPanelRef = useRef(null)

  return (
    <Layout>
      <Helmet title="Book a place for our live webinar">
        <meta name="robots" content="noindex" />
      </Helmet>
      <ContentSection>
        <header
          className="container compact"
          style={{ textAlign: 'center', marginTop: 8, marginBottom: 40 }}
        >
          <h1 className="heading heading--h2" style={{ marginTop: 0 }}>
            UK Business Visas:
            <br />
            The simplest path to UK residency
          </h1>
          <p className="hook">
            Our immigration experts help clients from around the world to
            achieve permanent residency in the United Kingdom with minimal risk.
          </p>
          <div>
            <h2 className="heading--h5" style={{ marginBottom: 8 }}>
              Our Simple, Unbeatable Guarantee
            </h2>
            <Guarantee>No Visa, No Fee!</Guarantee>
          </div>
        </header>

        <FeatureWrapper>
          <div className="container">
            <RegistrationSection
              webinarDateTime={nextWebinar.dateTime}
              showModal={() => {
                setModalVisible(true)
              }}
              registered={registerSuccess}
              ref={registrationPanelRef}
            />
            <section className="whyChoose">
              <h2 className="heading heading--h3">
                Why choose a business visa?
              </h2>
              <p>
                Investing in a new or existing UK business is a reliable
                immigration route that is achievable for entrepreneurs with a
                wide range of business backgrounds.
              </p>
              <p>
                Business visas offer a straightforward path for your family to
                enjoy the many advantages of life in the UK as well as the
                benefit of developing a business in this vibrant, modern
                economy.
              </p>
            </section>
          </div>
        </FeatureWrapper>

        <div className="container compact" style={{ maxWidth: 600 }}>
          <section className="services">
            <h2 className="heading--h3 heading--center">About our services</h2>
            <p>
              Unlike other advisors, we manage your entire immigration process;
              from business planning and visa applications to legal and
              corporate matters. We even assist as you get settled in the UK and
              can help you get a Driving Licence, make school applications and
              more.
              <br />
              We take care of everything.
            </p>
            <p>
              Not only does this make it easy for you, it also ensures fast,
              efficient processing and the greatest chance of success.
            </p>
            <p>
              Our London-based team offers professional advice and creates
              bespoke UK immigration solutions suited to every client's skills,
              budget and goals.
            </p>
            <p>
              If you have an entrepreneurial background, innovative idea, or
              skills that would be attractive to UK businesses, we may be able
              to help.
            </p>
          </section>

          <AboutSpeakers />
        </div>
        <div className="container">
          <h2 className="heading--h3 heading--center">Why Visa Kings Global</h2>
          <CanfieldsSection />
          <ClientTestimonials />

          <div style={{ textAlign: 'center', marginBottom: 40 }}>
            {registerSuccess ? (
              <>
                <h3
                  className="heading--h5 heading--secondary"
                  style={{ margin: 0 }}
                >
                  ✓&nbsp;&nbsp;Registration Successful
                </h3>
                <p style={{ margin: '4px auto 0' }}>
                  We have emailed you a personal link.
                </p>
              </>
            ) : (
              <>
                <h3 className="heading--h4">Find out more</h3>
                <button
                  className="button"
                  style={{ lineHeight: 1.3, fontSize: '2rem' }}
                  onClick={() => {
                    setModalVisible(true)
                  }}
                >
                  Register for our webinar
                </button>
              </>
            )}
          </div>
        </div>
      </ContentSection>
      <ModalWrapper visible={modalVisible}>
        <Modal
          title="Register for our free webinar"
          visible={modalVisible}
          handleClose={() => setModalVisible(false)}
        >
          <WebinarDate>{formattedDate}</WebinarDate>
          <BenefitsList>
            <li>Learn about our visa solutions</li>
            <li>Free consultations (usually £95)</li>
            <li>Ask your immigration questions</li>
            <li>
              <strong>No visa, no fee – special offer</strong>
            </li>
          </BenefitsList>
          <WebinarRegisterForm
            eventId={nextWebinar.id}
            onSuccess={() => {
              setRegisterSuccess(true)
              setModalVisible(false)
              scrollToEl(registrationPanelRef)
            }}
          />
        </Modal>
      </ModalWrapper>
    </Layout>
  )
}

export default WebinarRegisterPage

const FeatureWrapper = styled.div`
  background: linear-gradient(
    20deg,
    ${(p) => p.theme.colors.primary},
    ${(p) => p.theme.colors.primaryIntense}
  );
  color: #fff;
  padding-top: 24px;
  padding-bottom: 32px;
  margin-top: 32px;
  margin-bottom: 16px;

  > .container {
    max-width: 840px;
    @media (min-width: 760px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  .whyChoose {
    margin: 0 auto;
    max-width: 430px;

    @media (min-width: 760px) {
      padding-right: 40px;
    }
    .heading {
      margin-top: 24px;
    }
  }
`

const RegisterPanel = styled.section`
  background: #fff;
  color: ${(p) => p.theme.colors.darkTint};
  padding: 20px 12px;

  margin: 24px -12px;

  border-radius: 4px;
  text-align: center;

  max-width: 360px;
  @media (min-width: 400px) {
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 24px;
  }

  .heading {
    margin-top: 0;
  }

  .countdownWrapper {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

const RegistrationSection = React.forwardRef(
  ({ webinarDateTime, showModal, registered }, ref) => {
    return (
      <RegisterPanel ref={ref}>
        <h2 className="heading heading--h4">
          Register for our Webinar
          <br />
          <span style={{ fontSize: '.88em' }}>
            Expert Analysis + Live Q&amp;A
          </span>
        </h2>
        <div className="countdownWrapper">
          <Countdown dateString={webinarDateTime} />
        </div>

        {registered ? (
          <div>
            <h3
              className="heading--h5 heading--secondary"
              style={{ margin: 0 }}
            >
              ✓&nbsp;&nbsp;Registration Successful
            </h3>
            <p style={{ margin: '4px 0 0' }}>
              We have emailed you a personal link.
            </p>
          </div>
        ) : (
          <button
            className="button"
            style={{ lineHeight: 1.3, fontSize: '2rem' }}
            onClick={showModal}
          >
            View Offers and <br />
            Book a Free Place
          </button>
        )}
      </RegisterPanel>
    )
  },
)

const AboutSpeakers = () => {
  return (
    <section>
      <h2 className="heading--h3 heading--center">Our speakers</h2>
      <Speakers>
        <Speaker name="Harvey Shulman" title="Senior Immigration Solicitor">
          <p>
            Harvey holds a Master’s Degree in Law and has over three decades'
            experience working with start-ups and advising clients on business
            immigration and other legal matters. Harvey is an active member of
            the angel community in the UK and is regularly invited to speak at
            angel events to share his expertise.
          </p>
        </Speaker>
        <Speaker name="Muzaffar Saydiganiev" title="Senior Business Executive">
          <p>
            Muzaffar has held senior positions in investment banking, where he
            managed investment projects with a value of USD 2 Billion. He has a
            BSc Diploma in Banking, followed by a MSc in International Business
            Economics and a Diploma in Project Preparation and Appraisal.
          </p>
        </Speaker>
      </Speakers>
    </section>
  )
}

const Speaker = ({ name, title, photo, children }) => {
  return (
    <SpeakerBlock>
      <h3 className="name heading--h5">{name}</h3>
      <div>{title}</div>
      {children}
    </SpeakerBlock>
  )
}

const Speakers = styled.div`
  display: grid;
  grid-gap: 12px;
`

const SpeakerBlock = styled.div`
  background: #fff;
  padding: 12px 16px;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.lightTintDarker};

  .name {
    margin: 0;
  }
`

const Guarantee = styled.div`
  display: inline-block;
  border: 2.5px solid ${(p) => p.theme.colors.alert}55;
  color: ${(p) => p.theme.colors.alert};
  padding: 8px 40px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 2.2rem;
  letter-spacing: 0.01em;
`

const BenefitsList = styled.ul`
  list-style-position: inside;
  line-height: 1.2;
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 1.7rem;
  list-style: none;

  @media (min-width: 640px) {
    margin-top: 16px;
    margin-bottom: 20px;
  }

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, auto));
  justify-content: center;
  grid-gap: 3px 12px;

  max-width: 532px;
  margin-left: auto;
  margin-right: auto;

  > li {
    margin: 0;

    &::before {
      content: '✓';
      color: ${(p) => p.theme.colors.secondary};
      margin-right: 8px;
    }

    strong {
      color: ${(p) => p.theme.colors.secondary};
    }
  }
`

const WebinarDate = styled.div`
  text-align: center;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${(p) => p.theme.colors.primaryIntense};
`
