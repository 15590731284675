import styled from '@emotion/styled'
import React from 'react'

import PillFlash from '../pill-flash'

const Panel = styled.aside`
  max-width: 660px;

  font-size: 1.7rem;
  text-align: center;

  border-radius: 5px;
  box-shadow: 0 2px 40px ${(p) => p.theme.colors.alert}22;
  border: 1px solid ${(p) => p.theme.colors.alert}44;

  padding: 24px 24px 32px;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.3)
  );

  .button {
    border: 1px solid ${(p) => p.theme.colors.alert}aa;
    background-color: ${(p) => p.theme.colors.alert}11;
    color: inherit;
    text-shadow: none;
  }

  ${(p) =>
    p.darkPanel &&
    `
    background: rgba(0,0,0,.3);
    color: #fff;
    max-width: 400px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-color: ${p.theme.colors.alert}aa;
    border-width: 1.5px;

    .button {
    border-color: ${p.theme.colors.alert}aa;
    background-color: ${p.theme.colors.alert}1e;
  }
  `}
  p:last-of-type {
    margin-bottom: 12px;
  }
`

const ClosedPanel = ({ darkPanel }) => (
  <Panel darkPanel={darkPanel}>
    <PillFlash text="Updated Visas" />
    <p>
      The Tier 1 (Entrepreneur) category has closed but we offer a number of
      visas ideally suited to business entrepreneurs.
    </p>
    <p>
      These visas also lead to British Citizenship and provide similar (or
      better) benefits.
    </p>
  </Panel>
)

export default ClosedPanel
