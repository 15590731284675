import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import GlobalStyles from './GlobalStyles'

import Overlay from '../overlay'

import vkgLogoStackedSrc from 'img/vkg-logo-stacked.svg'
import vkgLogoStackedOnDarkSrc from 'img/vkg-logo-stacked-on-dark.svg'
import planesPatternSrc from 'img/planes-repeating-pattern.png'

interface IProps {
  children: ReactNode
  dark?: boolean
}

const darkGlobalStyles = css`
  body {
    background: #54516b;

    @media (min-width: 640px) {
      background-image: url(${planesPatternSrc});
      background-size: 400px 400px;
      background-position: center center;
    }
  }
`

const LandingPageLayout = ({ children, dark = false }: IProps) => {
  return (
    <LayoutContainer>
      <GlobalStyles styles={dark ? darkGlobalStyles : undefined} />
      <Helmet>
        <meta property="og:site_name" content="Visa Kings Global" />
        <meta property="og:type" content="website" />
        <meta property="fb:page_id" content="741168829567836" />
      </Helmet>
      <Header>
        <img
          className={`logo ${dark && 'logo--dark'}`}
          src={dark ? vkgLogoStackedOnDarkSrc : vkgLogoStackedSrc}
          alt="Visa Kings Global logo"
          height={42}
        />
      </Header>
      <div>{children}</div>
      <Overlay />
    </LayoutContainer>
  )
}

export default LandingPageLayout

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

const Header = styled.header`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  margin-bottom: 20px;

  .logo--dark {
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.35));
  }
`
