import { captureException } from '@sentry/browser'
import { isWeekend, isWithinInterval, set } from 'date-fns'

const UK_HOURS = {
  weekday: {
    start: '09:00',
    end: '18:00',
  },
  weekend: {
    start: null,
    end: null,
  },
}

export const checkPhonesAnswered = (
  date,
  openingHours = UK_HOURS,
  timeZone = 'Europe/London',
) => {
  const zonedDate = new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  )

  const hours = isWeekend(zonedDate)
    ? openingHours.weekend
    : openingHours.weekday

  if (hours.start === null || hours.end === null) return false

  const [startHours, startMinutes] = hours.start.split(':')
  const [endHours, endMinutes] = hours.end.split(':')

  try {
    return isWithinInterval(zonedDate, {
      start: set(zonedDate, { hours: startHours, minutes: startMinutes }),
      end: set(zonedDate, { hours: endHours, minutes: endMinutes }),
    })
  } catch (err) {
    captureException(err, {
      contexts: {
        'Tel Hours': {
          Hours: hours,
          'Zoned Date': zonedDate.toString(),
        },
      },
    })
    return false
  }
}
