import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import monogramSrc from '../../img/visa-kings-global-monogram-light.svg'
import Breadcrumbs from '../breadcrumbs'

const HeaderContainer = styled('header')`
  min-height: 360px;
  position: relative;

  display: flex;
  flex-direction: column;

  text-align: center;
  color: #fff;
  background-color: ${(p) => p.theme.colors.darkTint};

  > .container {
    flex: 1;
    position: relative;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .panel {
    background: ${(p) => p.theme.colors.primary};
    border-bottom-left-radius: ${(p) => p.theme.radius}px;
    border-bottom-right-radius: ${(p) => p.theme.radius}px;
    border-top: 4px solid ${(p) => p.theme.colors.secondary};
    padding: 28px 16px 32px;
    box-shadow: 0 6px 2px rgba(0, 0, 0, 0.2);

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      min-width: 400px;
    }
  }

  .monogram {
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    margin: 0;
    color: inherit;
    > * {
      display: block;
    }
    &__kingdom {
      font-size: 4rem;
      margin-top: -6px;
    }
    &__variant {
      font-size: 5rem;
      line-height: 0.9;
    }
  }

  .subhead {
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    font-size: 2rem;
    margin-top: 16px;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
`

const KingdomPlanHeader = ({ title, subhead }) => (
  <HeaderContainer>
    <StaticImage
      src="../../img/visa-gradient-bg.jpg"
      alt=""
      placeholder="none"
      layout="fullWidth"
      formats={['auto', 'webp', 'avif']}
      loading="eager"
      style={{
        position: 'absolute',
        top: 0,
        opacity: '0.1',
        width: '100%',
        objectFit: 'cover',
        minHeight: '400px',
      }}
      transformOptions={{ cropFocus: 'top' }}
    />
    <div className="container">
      <div className="panel">
        <img
          className="monogram"
          src={monogramSrc}
          alt="Visa Kings Global brand mark"
          width="500"
          height="344"
          style={{ height: 32, width: 'auto' }}
        />
        <h1 className="heading heading--accent heading--h1 title">
          <span className="title__kingdom">Kingdom</span>
          <span className="title__variant">{title}</span>
        </h1>
      </div>
      <div className="subhead">{subhead}</div>
    </div>
    <BreadcrumbsWrapper>
      <Breadcrumbs title={title} />
    </BreadcrumbsWrapper>
  </HeaderContainer>
)

const BreadcrumbsWrapper = styled.aside`
  position: relative;
  border-bottom: 4px solid rgba(0, 0, 0, 0.4);
`

export default KingdomPlanHeader
