import styled from '@emotion/styled'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useState, useEffect } from 'react'

import Layout from 'components/layouts/LandingPageLayout'
import Panel from 'components/panel'

import calendarIconSrc from '../../icons/calendar-icon.svg'

// TODO Event Tracking - localStorage + thank you page?
// TODO background pattern
// TODO trust logos
// TODO copyright

enum BookingType {
  Call = 'CALL',
  Consultation = 'CONSULTATION',
}

interface iUser {
  attended: boolean
  status: string
  firstName: string
  lastName: string
  email: string
  phone: string
  countryOfOrigin: string
  booking: {
    title: string
    url: string
    type: BookingType
    manager?: string
  }
}

const WebinarOfferPage = () => {
  const [ineligible, setIneligible] = useState<boolean | null>(null)
  const [eligible, setEligible] = useState<boolean | null>(null)
  const [user, setUser] = useState<iUser | null>(null)

  useEffect(() => {
    if (user === null) return
    if (user.booking.type === BookingType.Consultation) setEligible(true)
  }, [user])

  useEffect(() => {
    if (eligible === null || user === null) return
    const url = '/.netlify/functions/webinar-consultation'
    window
      .fetch(url, {
        method: 'POST',
        body: JSON.stringify({ email: user.email, eligible }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin', // increase browser support
      })
      .catch((err) => {})
  }, [eligible])

  const showUnavailable = ineligible === true || eligible === false
  const bookingType = user?.booking?.type

  return (
    <Layout dark>
      <div
        className="container"
        style={{ maxWidth: 600, paddingLeft: 8, paddingRight: 8 }}
      >
        <StyledPanel>
          <Panel.Header>
            <h1 className="heading heading--h2 heading--match heading--center">
              <span style={{ fontSize: '.65em' }}>Webinar Exclusive</span>
              <br />
              Free Consultation Offer
            </h1>
            <BenefitsBar>
              <strong>Limited Availability</strong>
              <strong>Normally £95</strong>
            </BenefitsBar>
          </Panel.Header>
          {user === null && ineligible !== true && (
            <LogInSection setUser={setUser} setIneligible={setIneligible} />
          )}
          {user?.attended && eligible === null && ineligible !== true && (
            <EligibilityDeclarationSection
              setEligible={setEligible}
              user={user}
            />
          )}
          {eligible === true && user && bookingType && (
            <Panel.Content>
              <h2 className="heading heading--center heading--h3">
                Great, let's talk!
              </h2>
              {bookingType === BookingType.Call && (
                <p>
                  Please choose a convenient time for a quick call before your
                  free consultation with our experienced advisers.
                </p>
              )}
              {bookingType === BookingType.Consultation && (
                <p>
                  Choose a convenient time for your Consultation with one of our
                  experienced advisers.
                </p>
              )}
              <a
                className="button button--large"
                href={`${prefilledBookingUrl(
                  user,
                )}&utm_source=webinar&utm_campaign=1982138`}
              >
                <img
                  src={calendarIconSrc}
                  alt="Calendar Icon"
                  height={22}
                  width={22}
                  style={{
                    display: 'inline',
                    marginRight: 10,
                    marginLeft: -2,
                    marginBottom: -2,
                    boxShadow: '0 2px 1px rgba(0,0,0,.1)',
                  }}
                />
                Schedule a {bookingType.toLowerCase()}
              </a>
              {user.booking.title === 'Initial Call' && (
                <p style={{ fontWeight: 600 }}>
                  Calls usually take about 10 minutes
                </p>
              )}
            </Panel.Content>
          )}

          {showUnavailable && (
            <Panel.Content>
              <h3 className="heading heading--center heading--h4">
                Unfortunately we aren't able to offer a free consultation at
                this time
              </h3>
              <p>
                Our team is committed to finding solutions to clients' needs so
                please use our contact form if you would like to discuss
                alternative opportunities.
              </p>
            </Panel.Content>
          )}
        </StyledPanel>
      </div>
    </Layout>
  )
}

export default WebinarOfferPage

const EligibilityDeclarationSection = ({ user, setEligible }) => {
  return (
    <Panel.Content>
      <h2 className="heading heading--h3 heading--center">
        Hello {user.firstName},{' '}
        <span style={{ whiteSpace: 'nowrap' }}>thanks for attending!</span>
      </h2>
      <p>Please confirm whether you meet our eligibility criteria:</p>
      <EligibilityCriteria>
        <li>
          You have access to at least £40,000
          <div>
            This level of funds is realistic for a successful business visa
            application and to support yourself. The finances will be required
            when your visa is granted (deposit due on signing agreement).
          </div>
        </li>
        <li>
          You speak English to a good standard
          <div>
            UK business visas typically require proficiency at level 'B2' or a
            degree taught in English. Don't worry, if you speak English well we
            can help you provide the correct evidence.
          </div>
        </li>
      </EligibilityCriteria>
      <div className="buttonGroup" style={{ maxWidth: 400 }}>
        <button
          className="button button--expand button--large"
          onClick={() => {
            setEligible(true)
          }}
        >
          Yes, I meet these criteria
        </button>
        <button
          className="button button--expand button--large"
          onClick={() => {
            setEligible(false)
          }}
        >
          I don't meet these criteria
        </button>
      </div>
    </Panel.Content>
  )
}

type LogInSectionProps = {
  setUser(user: object): void
  setIneligible(eligible: boolean): void
}

const LogInSection = ({ setUser, setIneligible }: LogInSectionProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  return (
    <Panel.Content>
      {errorMessage && (
        <StyledError style={{ textAlign: 'center' }}>
          {errorMessage}
          <br />
          Please try again.
        </StyledError>
      )}
      <h2 className="heading heading--h3 heading--center">
        Let's check that you qualify
      </h2>
      <p style={{ maxWidth: 'none' }}>
        Enter the email address that you used to register for our webinar.
      </p>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={async (values, { setFieldError }) => {
          try {
            const res = await getFreeConsultationLink(values)
            if (errorMessage) setErrorMessage(null)
            setUser({
              ...res,
            })
          } catch (err) {
            switch (err.message) {
              case 'Email not recognised': {
                setFieldError('email', 'Sorry, email not recognised')
                break
              }
              case 'Did not attend webinar': {
                setIneligible(true)
                break
              }
              case 'Ineligible': {
                setIneligible(true)
                break
              }
              default: {
                setErrorMessage('Sorry, something went wrong.')
              }
            }
          }
        }}
      >
        {({ isSubmitting, dirty }) => (
          <Form style={{ width: '100%', maxWidth: 300 }}>
            <Field
              name="email"
              type="email"
              placeholder="Email address"
              required
            />
            <ErrorMessage name="email">
              {(msg) => <StyledError>{msg}</StyledError>}
            </ErrorMessage>
            <div className="buttonGroup">
              <button
                type="submit"
                disabled={isSubmitting || !dirty}
                className="button button--large button--expand"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Panel.Content>
  )
}

async function getFreeConsultationLink({ email }: { email: string }) {
  const url = '/.netlify/functions/webinar-consultation'

  const res = await window.fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin', // increase browser support
  })

  //* Check for non-200 response
  if (res.ok !== true) {
    const resJSON = await res.json().catch(() => {
      throw new Error('Unknown error')
    })
    throw new Error(resJSON.message)
  }

  const resJSON = await res.json()

  //* Handle 'soft' errors with 200 status codes
  if (resJSON.success !== true) throw new Error(resJSON.message)

  return resJSON.data
}

function prefilledBookingUrl({
  booking,
  firstName,
  lastName,
  email,
  phone,
}: iUser) {
  // ! TODO standardise questions, name format and UTM
  return `${booking.url}?name=${firstName}%20${lastName}&email=${email}&a1=${phone}`
}

const BenefitsBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;

  > * {
    margin: 10px 12px 0;
  }
`

const EligibilityCriteria = styled.ol`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.05em;
  padding-left: 1.3em;
  max-width: 500px;

  > li {
    margin-bottom: 20px;
  }

  div {
    margin-top: 4px;
    font-weight: 400;
    font-size: 0.85em;
    color: ${(p) => p.theme.colors.darkTint};
  }
`

const StyledPanel = styled(Panel.Container)`
  padding-bottom: 40px;
  margin-top: 12px;
  box-shadow: 0 6px 20px -4px rgba(0, 0, 0, 0.2);

  p {
    text-align: center;
    max-width: 440px;
  }
`

export const StyledError = styled.div`
  margin-top: 6px;
  margin-left: 12px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.alert};
`
