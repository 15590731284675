import { useState, useEffect } from 'react'
import parseMs from 'parse-ms'

interface useCountdown {
  remaining?: number
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

function useCountdown(dateString: string): useCountdown {
  const eventTime = Date.parse(dateString)
  const [remaining, setRemaining] = useState<number|null>(null)
  const init = remaining !== null

  useEffect(() => {
    if (remaining === null) setRemaining(eventTime - Date.now())

    if (eventTime - Date.now() > 0) {
      const interval = setInterval(() => {
        setRemaining(eventTime - Date.now())
      }, 1000) // poll every second
      return () => {
        clearInterval(interval)
      }
    }
  }, [eventTime, init])

  if (remaining === null) return {}

  const hasPassed = remaining < 0
  const timeComponents = hasPassed ? {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  } : parseMs(remaining)

  return {
    remaining,
    ...timeComponents,
  }
}

export default useCountdown
