import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Header = () => {
  return (
    <Container>
      <StaticImage
        className="heroImage"
        src="../../img/apartments-blue-sky.jpg"
        alt=""
        placeholder="none"
        layout="constrained"
        width={2560}
        quality={40}
        formats={['auto', 'webp', 'avif']}
        loading="eager"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />

      <div className="container">
        <HeadlineContainer>
          <h1 className="heading heading--match heading--h1">
            Cyprus <br />
            <span style={{ fontSize: '.8em' }}>Residency by Investment</span>
          </h1>

          <div>
            <p className="heading heading--h3 heading--match">
              A fast, reliable route to a European residency
            </p>
          </div>
        </HeadlineContainer>

        <Panel>
          <h2
            className="heading heading--match heading--h4"
            style={{ maxWidth: 460 }}
          >
            Get a European Permanent Residence Permit with property investment
            from €300,000
          </h2>
        </Panel>
      </div>
    </Container>
  )
}

const Container = styled.div`
  background: #4fa2e4;
  padding-top: 64px;
  min-height: 540px;
  position: relative;

  display: flex;
  align-items: center;

  > .container {
    margin-top: -64px;
    width: 100%;
    position: relative;
  }

  .heroImage {
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      background: linear-gradient(
        160deg,
        ${(p) => p.theme.colors.primaryIntense}dd,
        ${(p) => p.theme.colors.primary}10 85%
      );
    }
  }
`

const HeadlineContainer = styled.header`
  color: #fff;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);

  .heading--h1 {
    font-size: 4.2rem;
    line-height: 1;
    margin: 0;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 6rem;
    }
  }
`

const Panel = styled.div`
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.primaryIntense},
    ${(p) => p.theme.colors.primary} 50%
  );
  box-shadow: inset 0 0 2px ${(p) => p.theme.colors.primary};
  border-left: ${(p) => p.theme.radius}px solid
    ${(p) => p.theme.colors.secondary};
  border-top-right-radius: ${(p) => p.theme.radius}px;
  border-bottom-right-radius: ${(p) => p.theme.radius}px;
  padding: 0 24px 24px 26px;
  color: ${(p) => p.theme.colors.secondary};
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  margin: 48px 0;
`

export default Header
