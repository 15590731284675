import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { BannerContent } from 'components/layouts/BannerContent'
import { PageContentWrapper } from 'components/layouts/PageContentWrapper'
import Layout from 'components/layouts/PrimaryLayout'
import PageHeader from 'components/page-header'
import Panel from 'components/panel'
import ServiceEnquirySection from 'components/service-enquiry-section'
import VideoPlayer from 'components/video-player'

const Page = ({ data }) => (
  <Layout>
    <Helmet title="Second Citizenship in Portugal | Kingdom Second Citizenship Plan" />
    <PageHeader
      title="Second citizenship in Portugal"
      bgSrc={data.hero.childImageSharp.gatsbyImageData}
    >
      <strong>Offered through the Kingdom Second Citizenship plan</strong>
    </PageHeader>
    <PageContentWrapper>
      <div className="container compact">
        <p className="hook">
          Portugal has a rich and diverse culture with a Mediterranean climate.
          The country offers safety and stability, making it an ideal country
          for raising families, conducting business or travelling.
        </p>
        <p>
          Portugal is also a rapidly-developing market and offers many
          opportunities for investors.
        </p>
        <p>
          Obtain permanent residency after five years as a temporary resident
          and Citizenship after 6 years.
        </p>
        <Panel.Container light>
          <Panel.Header>
            <h2 className="heading heading--h4">What are the benefits?</h2>
          </Panel.Header>
          <Panel.Content>
            <ul className="list">
              <li>Extremely low minimum stay periods</li>
              <li>
                No requirement for leave to enter in Portugal once the "Golden
                Visa" is granted
              </li>
              <li>
                Live and work in Portugal, even with residency in a different
                country
              </li>
              <li>Residency extended to family members</li>
              <li>Low taxes</li>
              <li>Visa-free travel within the Schengen member states</li>
            </ul>
          </Panel.Content>
        </Panel.Container>
      </div>
      <BannerContent>
        <VideoPlayer
          url="https://youtu.be/iJjmwcliqOM"
          title="The benefits of second citizenship in Portugal explained…"
        />
      </BannerContent>
      <div className="container compact">
        <section>
          <h2 className="heading--h2">Golden Visa scheme</h2>
          <p>
            Widely supported by local government, the Golden Visa Scheme offers
            investors{' '}
            <strong>
              permanent residency after 5 years and citizenship after 6 years
              without the need to emigrate to Portugal
            </strong>
            . The required investment amount comes with a flexibility of two
            options: the standard €500,000 or a reduced option of €350,000.
          </p>
          <h3 className="heading--h3">How long is the investment for?</h3>
          <p>
            The investment must be kept for a minimum period of 5 years and the
            funds for investment should come from abroad
          </p>
          <h3 className="heading--h3">Who qualifies?</h3>
          <p>
            You have to be a non-EU citizen and make one of the following
            investments:
          </p>
          <ol className="list">
            <li>
              <strong>Acquisition of property</strong>
              <br />
              Purchase of one or multiple properties (all property types
              qualify, possibility of co-ownership, freedom to use as residence
              or rent)
            </li>
            <li>
              <strong>Capital investments</strong>
            </li>
            <li>
              <strong>Job creation in Portugal</strong>
              <br />
              Must last for at least 5 years
            </li>
          </ol>
          <h3 className="heading--h3">
            How long is the Golden Visa valid for?
          </h3>
          <p>
            Once issued, the Golden Visa will be valid for an initial period of
            1 year and then will be renewed for subsequent periods of 2 years.
          </p>
        </section>
      </div>
    </PageContentWrapper>
    <ServiceEnquirySection
      enquiryType={{
        category: 'goldenInvestor',
        services: ['portugalCitizenship'],
      }}
    />
  </Layout>
)

export default Page

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "img/porto-portugal.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 3)
      }
    }
  }
`
