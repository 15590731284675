import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { useState, useEffect } from 'react'

import EnquiryForm from './form/enquiry-form'
import Notices from './form/lead-form-notices'

const NoticesContainer = styled.div`
  margin-top: 24px;
`

const ExpandHintButton = styled.button`
  box-shadow: 0 2px 24px -6px ${(p) => p.theme.colors.secondary};
  border-radius: 999px;
  display: block;
  margin: 20px auto -12px;
  &::after {
    content: '…';
  }
`

const ServiceEnquiryForm = ({ enquiryType }) => {
  const [expanded, setExpanded] = useState(false)
  const [expandReported, setExpandReported] = useState(false)

  const expand = () => {
    if (!expanded) setExpanded(true)
  }

  useEffect(() => {
    // TODO: if allowing expanded by default, prevent reporting
    if (expanded && !expandReported) {
      window.dataLayer.push({
        event: 'enquiry-form-expand',
        enquiryType,
      })
      setExpandReported(true)
    }
  }, [expanded, expandReported, enquiryType])

  return (
    <motion.div
      animate={expanded ? 'expanded' : 'collapsed'}
      expanded={expanded}
    >
      <FormWrapper onClick={expand} expanded={expanded}>
        <h4 className="heading heading--h4 heading--center">
          Request a free assessment
        </h4>
        <EnquiryForm enquiryType={enquiryType} expandable expanded={expanded} />
        {!expanded && (
          <ExpandHintButton onClick={expand} className="button">
            Continue
          </ExpandHintButton>
        )}
        <Notices
          enquiryType={enquiryType}
          includeGlobal
          containerEl={NoticesContainer}
        />
      </FormWrapper>
    </motion.div>
  )
}

export default ServiceEnquiryForm

const FormWrapper = styled(motion.div)`
  width: 100%;
  max-width: 640px;

  background: ${(p) => p.theme.colors.lightTintDarker};

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    background: linear-gradient(
      to bottom,
      ${(p) => p.theme.colors.lightTintMid},
      ${(p) => p.theme.colors.lightTintDarker} 200px
    );
  }

  ${(p) => !p.expanded && 'cursor: pointer;'}

  margin: 0 auto;
  padding: 24px 24px 40px;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    border: 2px solid ${(p) => p.theme.colors.secondary};
    border-radius: 8px;
    padding: 24px 32px 32px;
    box-shadow: 0 8px 32px -4px rgba(0, 25, 0, 0.3);
  }

  > .heading {
    margin-top: 0;
    margin-bottom: 16px;
    color: ${(p) => p.theme.colors.secondary};
  }
`
