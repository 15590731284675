import React from 'react'
import { InlineWidget } from 'react-calendly'
import { Helmet } from 'react-helmet'

import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'

const ConsultationBookPage = () => {
  return (
    <Layout focused>
      <Helmet title="Book a Consultation">
        <meta name="robots" content="noindex" />
      </Helmet>
      <ContentSection>
        <div className="container compact" style={{ textAlign: 'center' }}>
          <header>
            <h1 className="heading heading--h1">Book a Consultation</h1>
          </header>
          <div>
            {/* <p className="hook">
              Your consultation has been scheduled with our immigration team.
            </p> */}
            <InlineWidget
              url="https://calendly.com/visakingsglobal/business-immigration-consultation-remote"
              styles={{
                height: '1000px',
              }}
              pageSettings={{
                hideEventTypeDetails: false,
              }}
            />
          </div>
        </div>
      </ContentSection>
    </Layout>
  )
}

export default ConsultationBookPage
