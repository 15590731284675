import styled from '@emotion/styled'
import React from 'react'

import ServiceEnquiryForm from 'components/service-enquiry-form'

import stopwatchIconSrc from '../../icons/stopwatch-icon.svg'

const Cta = () => (
  <Container>
    <div className="container compact" style={{ maxWidth: 400 }}>
      <img
        src={stopwatchIconSrc}
        alt="Innovative idea lightbulb icon"
        loading="lazy"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          height: 40,
          width: 40,
        }}
        height={64}
        width={64}
      />
      <h3
        className="heading heading--h2 heading--accent"
        style={{ marginTop: 0 }}
      >
        Don't delay
      </h3>
      <p className="hook">
        Start-up visas depend on approval from a Government-approved Endorsing
        Body.
      </p>
      <p>
        Unlike many immigration advisors we can present your business to
        Endorsing Bodies <strong>which are accepting applications</strong>.
      </p>
      <p>
        Endorsing bodies have a strict approval quota{' '}
        <strong>which limits the cases we can accept.</strong>
      </p>
    </div>
    <div style={{ marginTop: 40 }}>
      <ServiceEnquiryForm
        enquiryType={{ category: 'businessUK', services: ['startupVisa'] }}
      />
    </div>
  </Container>
)

const Container = styled.section`
  padding-top: 48px;
  padding-bottom: 72px;
  background: ${(p) => p.theme.colors.lightTintMid};
  border-top: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  border-bottom: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  text-align: center;
`

export default Cta
