import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'
import USAVisaServicesOverview from 'components/usa-visa-services-overview'

const KingdomPlansIndexPage = () => (
  <Layout>
    <Helmet title="USA Visa Services - Investor Green Card Packages">
      <meta
        name="description"
        content="Obtain a Green Card and live permanently in the USA by investing in an enterprise that creates jobs for qualifying workers in the USA."
      />
    </Helmet>
    <USAVisaServicesOverview />
    <ServiceEnquirySection
      enquiryType={{
        category: 'goldenInvestor',
        services: ['usaVisa'],
      }}
    />
  </Layout>
)

export default KingdomPlansIndexPage
