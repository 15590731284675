import * as Sentry from '@sentry/browser'

// import { RECAPTCHA_SITE_KEY } from '../../config'

const SUBMIT_URL = '/.netlify/functions/webinar-register'

// const getRecaptchaToken = () =>
//   new Promise((resolve, reject) => {
//     if (typeof window.grecaptcha?.execute !== 'function') {
//       setTimeout(() => {
//         if (typeof window.grecaptcha?.execute !== 'function')
//           return reject(new Error('Error loading captcha'))
//         window.grecaptcha
//           .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
//           .then(resolve) // resolve token to native promise
//       }, 2000)
//     } else {
//       window.grecaptcha
//         .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
//         .then(resolve) // resolve token to native promise
//     }
//   })

// const setHasEnquired = (enquiryId) => {
//   const current = getHasEnquired()
//   const prevEnq = current?.enquiries || []

//   const data = {
//     date: new Date().toISOString(),
//     enquiries: [...prevEnq, enquiryId],
//   }
//   window.localStorage.setItem('hasEnquired', window.btoa(JSON.stringify(data)))
// }

// const getHasEnquired = () => {
//   const data = window.localStorage.getItem('hasEnquired')
//   if (!data) return null
//   try {
//     return JSON.parse(window.atob(data))
//   } catch (err) {
//     Sentry.captureException(err)
//     console.log('Error decoding previous enquiry data', err.message)
//     return null
//   }
// }

export const prewarm = () =>
  window
    .fetch(SUBMIT_URL, {
      method: 'GET',
      headers: new Headers({ 'X-Prewarm': 'interaction' }),
      credentials: 'same-origin', // increase browser support
    })
    .then((res) => {
      if (res.ok !== true)
        throw new Error(
          `Error ${res.status} prewarming lambda: '${res.statusText}'`,
        )
    })
    .catch((err) => {
      Sentry.captureException(err)
      console.log(err.message)
    })

export const handleSubmit = async ({
  eventId,
  formData,
  locationData = {},
}) => {
  /*
  const recaptchaToken = await getRecaptchaToken().catch((err) => {
    //! error getting token, not verifying request is human
    // TODO enhance Sentry exception
    Sentry.captureException(err)
    console.log('ReCaptcha Error', err.message)
    throw new Error('Verification error')
  })
  */

  const url = SUBMIT_URL + `?eventid=${eventId}`

  try {
    const res = await window.fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        formData,
        recaptchaToken: null,
        locationData,
      }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin', // increase browser support
    })

    //* Check for non-200 response
    if (res.ok !== true) {
      //* Validation error
      if (res.status === 422) throw new Error('Invalid request data')

      try {
        //* Other non-200 responses may still contain useful info in body
        const body = await res.json()
        throw new Error(`${res.status} ${res.statusText}: ${body.message}`)
      } catch (e) {
        throw new Error(`${res.status} ${res.statusText}`)
      }
    }

    const resJSON = await res.json()
    // Unlikely that success not true with 200 status but check anyway
    if (resJSON.success !== true) throw new Error(resJSON.message)

    trackRegistration()
  } catch (err) {
    Sentry.captureException(err)
    console.log(err.message)

    const invalid = err.message.match(/invalid/i) !== null

    const userError = invalid ? 'Invalid submission' : 'Network error'
    throw new Error(userError)
  }
}

function trackRegistration() {
  const event = { event: 'webinar-register' }
  window.dataLayer.push(event)
}
