import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import { BannerContent } from 'components/layouts/BannerContent'
import { PageContentWrapper } from 'components/layouts/PageContentWrapper'
import Layout from 'components/layouts/PrimaryLayout'
import PageHeader from 'components/page-header'
import Panel from 'components/panel'
import ServiceEnquirySection from 'components/service-enquiry-section'
import VideoPlayer from 'components/video-player'

const Page = ({ data }) => (
  <Layout>
    <Helmet title="Cyprus Citizenship by Investment: Golden Visa for High Net Worth Investors" />
    <PageHeader
      title="Cyprus Citizenship by Investment"
      bgSrc={data.hero.childImageSharp.gatsbyImageData}
    >
      <strong>Golden Visa for High-Net-Worth Investors</strong>
    </PageHeader>
    <PageContentWrapper>
      <div className="container compact">
        <p className="hook">
          The application time of a Cypriot citizenship is one of the shortest
          among others and can often be done in three months. Compared to other
          investment visas, the Cypriot citizenship by investment provides a
          fast and efficient solution to obtaining a second passport.
        </p>
        <Panel.Container light>
          <Panel.Header>
            <h2 className="heading heading--h4">What are the benefits?</h2>
          </Panel.Header>
          <Panel.Content>
            <ul className="list">
              <li>No language requirements</li>
              <li>
                Excellent education system as well as first-rate health care
              </li>
              <li>The right to live, work, and study in all 27 EU countries</li>
              <li>
                Fast-track procedure. Approval of citizenship application within
                three months
              </li>
              <li>
                Full citizenship and passports are granted to the applicant and
                included family members (dependents)
              </li>
              <li>Visa-free travel to over 170 countries around the world</li>
            </ul>
          </Panel.Content>
        </Panel.Container>
      </div>
      <BannerContent>
        <VideoPlayer
          url="https://youtu.be/2Dg1GtC4mQg"
          title="The benefits of second citizenship in Cyprus explained…"
        />
      </BannerContent>
      <div className="container compact">
        <section>
          <h3 className="heading--h4">What are the investment options?</h3>
          <p>Invest at least €2.15 million in one of the following:</p>
          <ul className="list">
            <li>
              Real estate, infrastructure or land development projects or
              construction of buildings
            </li>
            <li>
              Purchase, participation or formation of Cypriot companies and
              businesses
            </li>
            <li>Financial assets of Cypriot organisations or companies</li>
            <li>
              Combination of the above as long as the amount of investment
              totals at a minimum of €2.15 million
            </li>
          </ul>
        </section>
        <section>
          <h3 className="heading--h4">Additional requirements</h3>
          <p>
            The applicant's name cannot be on the list of persons whose property
            is ordered to be frozen within the EU.
          </p>
          <p>
            One visit to Cyprus is required every seven years along with basic
            requirements like a clean criminal record and a valid passport.
          </p>
        </section>
      </div>
    </PageContentWrapper>
    <ServiceEnquirySection
      enquiryType={{
        category: 'goldenInvestor',
        services: ['cyprusCitizenship'],
      }}
    />
  </Layout>
)

export default Page

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "img/ithaca-cyprus.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 3)
      }
    }
  }
`
