import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Header = () => {
  return (
    <Container>
      <StaticImage
        src="../../img/board-room-hero.jpg"
        alt=""
        layout="fullWidth"
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="heroImage"
        loading="eager"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        imgStyle={{
          objectPosition: '50% 80%',
        }}
      />

      <HeadlineContainer className="container compact">
        <h1 className="heading heading--h1">
          Business Entrepreneur <br />
          <span style={{ fontSize: '.9em' }}>Visa Plan</span>
        </h1>

        <motion.div
          initial={{
            y: 12,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            delay: 0.8,
            duration: 0.4,
          }}
        >
          <Subhead className="heading heading--h2">
            Your passport to British Citizenship
          </Subhead>
        </motion.div>
      </HeadlineContainer>
    </Container>
  )
}

export default Header

const Container = styled.div`
  background: ${(p) => p.theme.colors.lightTintMid};
  text-align: center;
  padding-top: 32px;
  padding-bottom: 160px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-top: 60px;
    padding-bottom: 320px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.xl}px) {
    padding-bottom: 460px;
  }

  position: relative;
  > .container {
    position: relative;
  }

  .heroImage {
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      margin-top: 160px;
      &::after {
        content: '';
        height: 40%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
          to bottom,
          ${(p) => p.theme.colors.lightTintMid},
          ${(p) => p.theme.colors.lightTintMid}00
        );
      }
    }
  }
`

const HeadlineContainer = styled.header`
  color: ${(p) => p.theme.colors.primary};
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    padding-left: 12px;
    padding-right: 12px;
    max-width: 440px !important;
  }

  .heading--h1 {
    text-shadow: 0 0 24px ${(p) => p.theme.colors.lightTintMid}ff;
    font-size: 4.2rem;
    line-height: 1;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 6rem;
    }
    margin-top: 4px;
  }
`

const Subhead = styled.p`
  margin-top: 32px;
  text-shadow: 0 2px 16px ${(p) => p.theme.colors.lightTintMid}aa;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    margin-top: 40px;
  }
`
