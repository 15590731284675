import React from 'react'
import { Helmet } from 'react-helmet'

import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const CovidPage = () => (
  <Layout>
    <Helmet title="COVID-19 (Coronavirus): How this might affect immigration applications">
      <meta
        name="description"
        content="We have tried to address the natural concerns during the COVID-19 pandemic and explain why a sensible approach may indeed be to advance your preparations."
      />
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <header style={{ marginTop: 40 }}>
          <h1 className="heading heading--h2 heading--center">
            COVID-19 (Coronavirus)
          </h1>
        </header>
        <div>
          <section style={{ margin: '40px 0' }}>
            <p>
              Our operations are unaffected by the current pandemic and we
              remain committed to delivering a high level of service which looks
              after all our clients.
            </p>
            <p>
              Our team continue to advise international clients on visa
              applications.
            </p>
          </section>
          <section>
            <h2 className="heading heading--h3">How might this affect me?</h2>
            <p>
              It is understandable that the global disruption may be a concern
              for anyone currently making immigration plans. We have tried to
              address these concerns and explain why a sensible approach may
              indeed be to advance your preparations.
            </p>
            <p>
              The first consideration is that the visa process can take a few
              months under normal circumstances. It looks likely that travel
              bans will be lifted and normality will be largely resumed within
              such a time frame.
            </p>
            <p>
              Secondly, the Pound Sterling is now approaching parity with the US
              Dollar and the Euro. This unprecedented exchange rate is extremely
              favourable for some overseas investors.
            </p>
            <p>
              Thirdly, the UK’s medical capabilities and expertise are
              recognised as being among the best in the world, particularly in
              the field of virology. This, in conjunction with the significant
              Government stimulus packages, has led economists to forecast a
              significantly faster economic recovery compared to previous
              recessions.
            </p>
            <p>
              Finally, there may be a significant advantage in preparing your
              application now rather than waiting for "things to get back to
              normal". Postponing risks further long delays as there will be a
              pent up demand once the emergency ends and there is likely to be a
              backlog of cases causing undue delays which you may be able to
              avoid.
            </p>
            <p>
              We are happy to discuss the best options for you, please get in
              touch for an initial conversation about how our experts can help
              you.
            </p>
          </section>
        </div>
      </div>
    </ContentSection>
    <CallToAction headline="Contact our team" />
  </Layout>
)

export default CovidPage
