import styled from '@emotion/styled'
import React from 'react'

const ServiceGroupSwitcher = ({
  serviceGroups,
  activeGroupIndex,
  handleClick,
}) => {
  return (
    <SwitchBg>
      <SwitchContainer>
        {serviceGroups.map((group, i) => (
          <Button
            className="button button--onDark"
            key={group.title}
            onClick={() => handleClick(i)}
            active={activeGroupIndex === i}
          >
            {group.title}
            <span className="immigration"> Immigration</span>
          </Button>
        ))}
      </SwitchContainer>
    </SwitchBg>
  )
}

const Button = styled.button`
  font-size: 1.8rem;
  min-width: 230px;
  ${(p) =>
    !p.active &&
    `
    background: transparent;
    border: transparent;
    `}

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    min-width: 140px;
    .immigration {
      display: none;
    }
  }
`

const SwitchBg = styled.div`
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.secondary}11,
    rgba(0, 0, 0, 0) 60px
  );
  display: flex;
  justify-content: center;
  padding: 12px 0 0;
`

const SwitchContainer = styled.nav`
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.primary} 50%,
    ${(p) => p.theme.colors.primaryIntense}
  );
  border-radius: 9px;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border: 1px solid ${(p) => p.theme.colors.primaryIntense};
`

export default ServiceGroupSwitcher
