import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'
import VideoPlayer from 'components/video-player'

const ServiceBenefitsPage = () => (
  <Layout>
    <Helmet title="Service Benefits" />
    <ContentSection>
      <div className="container compact">
        <header style={{ textAlign: 'center' }}>
          <h1 className="heading heading--h1">Service Benefits</h1>
        </header>
        <AboutBody />
      </div>
    </ContentSection>
    <TestimonialsSection />
    <CallToAction />
  </Layout>
)

export default ServiceBenefitsPage

const AboutBody = () => (
  <div>
    <p className="hook">
      The Visa Kings Global immigration team can help both individuals and a
      wide range of commercial employers meet their global mobility needs,
      including Innovator visa and Tier 1 visa applications.
    </p>
    <p>
      An Innovator visa (successor to Tier 1 Entrepreneur) requires you to
      establish a new business satisfying a number of specific tests. Upon being
      granted an Innovator visa you can live in the UK and also bring family
      members and dependants with you.
    </p>
    <p>
      We can advise you on the most appropriate category of visa for your
      circumstances and to make the process as smooth as possible.
    </p>
    <p>
      We have worked to provide our clients with the highest standards of legal
      advice and service built on our focus and commitment to our clients in a
      variety of areas by ensuring that we understand your needs and keeping you
      up to date and well informed. For that reason we are Lexcel accredited.
    </p>
    <p>
      We also understand the stresses involved with moving across the world, and
      take great pride in playing a supportive role to ease the pressure by
      providing concierge services through our trusted providers.
    </p>
    <VideoPlayer
      title="Client services for after you obtain your visa"
      url="https://youtu.be/B-Ts8vVV1Ig"
    />
    <div>
      <p>We aim to offer:</p>
      <ul className="list">
        <li>
          An efficient, first-class, professional service while retaining the
          personal touch
        </li>
        <li>The benefit of experience and in-depth knowledge</li>
        <li>A plan tailored to your specific requirements</li>
        <li>The assurance of our confidentiality and trustworthiness</li>
      </ul>
    </div>
  </div>
)
