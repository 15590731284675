import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

import VideoPlayer from './video-player'

const ExplanationContainer = styled('section')`
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.lightTintMid};
  padding-top: 40px;
  padding-bottom: 60px;

  .heading {
    margin-bottom: 32px;
    text-align: center;
  }

  p strong {
    font-size: 2.1rem;
  }
`

const Heading = styled.h1``

const ExplanationSection = ({ teaser }) => {
  return (
    <ExplanationContainer>
      <div className="container compact">
        <Heading as={`h${teaser ? '2' : '1'}`} className="heading heading--h2">
          About UK Business and Investor Visas
        </Heading>

        <p className="hook">
          A UK visa through business investment is the most straightforward path
          to UK residency and provides a route to British Citizenship.
        </p>
        <p>
          By investing in or taking over a business in the United Kingdom, you
          will be entitled to take up residence, along with your family.
        </p>
        <p>
          Creating or funding a new start-up company is a very attractive option
          for skilled individuals and we can provide a range of services and
          business opportunities that are ideally suited to supporting an
          Innovator or Tier 1 visa application.
        </p>

        <VideoPlayer
          title="Our Expertise in Business Finance"
          url="https://youtu.be/yACIcuJmobw"
          light
          playing
        />

        {teaser && (
          <div className="buttonGroup">
            <Link
              to="/about-entrepreneur-visas/"
              className="button button--large"
            >
              About Entrepreneur Visas
            </Link>
          </div>
        )}

        {!teaser && (
          <>
            <p>
              Once in the UK, you and your family can enjoy many of the same
              significant benefits as citizens, including free public schooling,
              free medical care through the NHS and a host of other advantages
              that come from living in a highly developed country with one of
              world's leading economies.
            </p>
            <p>
              With experienced teams around the world, Visa Kings Global can
              ensure you meet the requirements for a Tier 1 Visa by matching you
              with investment opportunities that perfectly complement your
              skills, no matter how specific they are.
            </p>
          </>
        )}

        {!teaser && (
          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <Link
              to="/uk/business-investor-visas/"
              className="button button--large"
            >
              UK Business &amp; Investor Services
            </Link>
          </div>
        )}
      </div>
    </ExplanationContainer>
  )
}

export default ExplanationSection
