import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const ContentWrapper = styled.section`
  background-color: ${(p) => p.theme.colors.lightTintMid};
  position: relative;
  padding-top: 16px;
  padding-bottom: 80px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-top: 40px;
  }
`

const ContentInner = styled.div`
  position: relative;
`

type ContentSectionProps = {
  className?: string
  children?: ReactNode
}

export const ContentSection = ({
  children,
  className,
}: ContentSectionProps) => (
  <ContentWrapper className={className}>
    <ContentInner>{children}</ContentInner>
  </ContentWrapper>
)