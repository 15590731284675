import React from 'react'
import { Helmet } from 'react-helmet'

import CitizenshipOverview from 'components/citizenship-overview'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const CitizenshipIndexPage = () => (
  <Layout>
    <Helmet title="Second Citizenship by Investment Services">
      <meta
        name="description"
        content="A second citizenship by investment provides an opportunity for individuals to become a citizen of another country without having to live there."
      />
    </Helmet>
    <CitizenshipOverview />
    <CallToAction />
  </Layout>
)

export default CitizenshipIndexPage
