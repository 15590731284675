import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { useState } from 'react'

import { theme } from '../config'
import expandDiscIconSrc from '../icons/expand-disc-icon.svg'
import menuIconSrc from '../icons/menu-icon.svg'
import useClickOutside from '../lib/useClickOutside'
import DropdownNav from './Header-Nav-Dropdown'
import {
  YouTubeGlyph,
  FacebookGlyph, // InstagramGlyph,
  // LinkedInGlyph,
} from './icons'
import Meganav from './meganav'

const Nav = () => {
  return (
    <NavContainer>
      <MeganavItem />
      <MediumShow>
        <MediumUpNav>
          <DropdownNav
            title="About us"
            navItems={[
              <Link to="/the-team/">Our Team</Link>,
              <Link to="/uk/legal-services/">UK Legal Services</Link>,
              <Link to="/service-benefits/">Service Benefits</Link>,
              <Link to="/contact/">Contact Details</Link>,
              <Link to="/covid-19/">COVID-19 Statement</Link>,
              <a href="https://medium.com/visa-kings-global">
                Immigration Blog
              </a>,
            ]}
          />
          <SocialContainer>
            <IconLink
              href="https://www.youtube.com/c/visakingsglobal"
              title="Visa Kings Global YouTube channel"
            >
              <YouTubeGlyph height="1em" />
            </IconLink>
            <IconLink
              href="https://www.facebook.com/VisaKingsGlobal/"
              title="Visa Kings Global on Facebook"
            >
              <FacebookGlyph height="1em" />
            </IconLink>
            {/* <IconLink
              href="https://www.instagram.com/visakingsglobal/"
              title="Visa Kings Global on Instagram"
            >
              <InstagramGlyph height=".9em" />
            </IconLink> */}
            {/* <IconLink
              href="https://linkedin.com/company/visa-kings-global"
              title="Visa Kings Global on LinkedIn"
            >
              <LinkedInGlyph height=".9em" />
            </IconLink> */}
          </SocialContainer>
        </MediumUpNav>
      </MediumShow>
    </NavContainer>
  )
}

export default Nav

const IconLink = styled.a`
  display: flex;
  align-items: center;

  svg {
    fill: ${(p) => p.theme.colors.lightTintDarker};
  }

  &:hover,
  &:focus {
    svg {
      fill: rgba(255, 255, 255, 1);
    }
  }
`

const MediumShow = styled.div`
  flex: 1;
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    display: none;
  }
`

const MediumUpNav = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
`

const MeganavItem = () => {
  const [visible, setVisible] = useState(false)
  const toggleMeganav = () => setVisible(!visible)
  const node = useClickOutside(visible, () => setVisible(false))
  return (
    <div ref={node}>
      <MeganavButton active={visible} onClick={toggleMeganav}>
        <picture>
          <source
            media={`(max-width: ${theme.breakpoints.medium - 1}px)`}
            srcSet={menuIconSrc}
          />
          <source
            media={`(min-width: ${theme.breakpoints.medium}px)`}
            srcSet={expandDiscIconSrc}
          />
          <img className="icon" src={menuIconSrc} alt="Menu" />
        </picture>
        <span className="button__text">Immigration Services</span>
      </MeganavButton>
      <MeganavWrapper>
        <Meganav
          isVisible={visible}
          handleClose={() => {
            setVisible(false)
          }}
        />
      </MeganavWrapper>
    </div>
  )
}

export const headerButtonBaseCss = css`
  color: #fff;
  font-weight: 600;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  padding: 8px 14px;
  font-size: 1.6rem;
  line-height: 1;
  white-space: nowrap;

  display: flex;
  align-items: center;

  > .icon {
    height: 0.6em;
    margin-left: 8px;
    margin-right: -2px;
    position: relative;
    top: 1px;
  }
`

export const NavItemButton = styled.button`
  ${headerButtonBaseCss};
  background: none;
  border-color: transparent;
  margin-left: 4px;

  &:focus {
    outline: 0;
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
  }
`

export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    flex-grow: 1;
  }
`

const MeganavButton = styled.button`
  ${headerButtonBaseCss};
  background: ${theme.colors.secondary};
  background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0) 50%
    ),
    ${theme.colors.secondary};
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px;

  .icon {
    top: 1;
    height: 16px;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-left: -2px;
      margin-right: 8px;
      transition: transform 0.2s;
      ${(p) => p.active && 'transform: rotate(45deg);'}
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    background: none;
    border: none;
    padding: 0;
    justify-content: center;
    height: 36px;
    width: 36px;

    .icon {
      position: static;
      margin: 0;
      height: 24px;
    }
    .button__text {
      display: none;
    }
  }

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    &:hover,
    &:focus {
      transition: background 0.2s, border-color 0.2s;
      background: rgba(32, 187, 125, 0.9);
    }

    &:focus {
      outline: none;
    }
  }

  ${(p) =>
    p.active &&
    `
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0) 50%
    ),
    rgba(32, 187, 125, 0.8);
    border-color: rgba(32, 187, 125, 0.8);
  `}
`

const MeganavWrapper = styled.div`
  position: fixed;
  top: 68px;
  left: 0;
  right: 0;
  @media (min-width: ${(p) => p.theme.breakpoints.compactNav}px) {
    top: 96px;
  }
`

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  > * {
    padding-left: 6px;
    padding-right: 6px;
  }
`
