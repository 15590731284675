import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'

import { HighlightsList, HighlightBlock } from '../highlight-blocks'

const KeyInfo = () => {
  const [blocksVisible, setBlocksVisible] = useState(false)
  return (
    <Container>
      <motion.div
        className="container"
        initial="hidden"
        animate={blocksVisible ? 'visible' : 'hidden'}
      >
        <Waypoint bottomOffset={160} onEnter={() => setBlocksVisible(true)} />

        <motion.header
          variants={{
            hidden: {
              opacity: 0,
              scale: 0.95,
            },
            visible: {
              opacity: 1,
              scale: 1,
            },
          }}
        >
          <Heading className="heading heading--h2 heading--accent">
            Innovator visa key information
          </Heading>
        </motion.header>

        <HighlightsList
          variants={{
            visible: { transition: { staggerChildren: 0.3 } },
          }}
        >
          <HighlightBlock
            title="Establish a UK business"
            extra="which must be endorsed by a Government-approved body"
          />
          <HighlightBlock
            title="Receive 3 years’ UK residency"
            extra="after which you will then qualify to apply for settlement (ILR)"
          />
          <HighlightBlock
            title="£50,000 minimum investment"
            extra="reduced from £200,000 under the previous Tier 1 Entrepreneur visa"
          />
        </HighlightsList>
      </motion.div>
    </Container>
  )
}

const Container = styled.section`
  padding-top: 32px;
  padding-bottom: 60px;
  text-align: center;
  background: ${(p) => p.theme.colors.lightTintMid};
  border-top: 1px solid #fff;
  border-bottom: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  min-height: 400px;
  display: flex;
  align-items: center;
`

const Heading = styled.h2`
  max-width: 240px;
  margin: 0 auto;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-size: 4rem;
    max-width: none;
  }
`

export default KeyInfo
