import { css } from '@emotion/react'

import { theme } from '../../config'

export default css`
  html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 1.8rem;

    // Override Meta's oldstyle figure default
    font-variant-numeric: lining-nums;
    @supports not (font-variant-numeric: lining-nums) {
      font-feature-settings: 'lnum' on;
    }

    line-height: 1.4;
    color: ${theme.colors.darkTint};
  }

  a {
    text-decoration: none;
  }

  p {
    max-width: 80ch;
  }

  p a {
    font-weight: 400;
    text-decoration: underline;
    text-decoration-color: ${theme.colors.darkTint}77;
    transition: text-decoration-color 0.15s;

    &:hover {
      text-decoration-color: inherit;
    }
  }

  p strong {
    font-weight: 600;
  }

  p.hook {
    font-size: 1.12em;
    letter-spacing: -0.01em;
  }

  hr {
    width: 100%;
    height: 1px;
    border: 0;
    background: currentColor;

    &.subtle {
      background: ${theme.colors.tintOverlay};
    }
  }

  p,
  ul,
  ol {
    font-size: inherit;
  }

  ul.list,
  ol.list {
    margin-left: 1em;
    padding-left: 1.2em;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    font-weight: 400;
  }

  .heading {
    margin-top: 0.9em;
    margin-bottom: 0;
    line-height: 1.25;

    &--h1 {
      font-family: ${theme.fonts.accent};
      color: ${theme.colors.primary};
      letter-spacing: -0.03em;
      line-height: 1.2;
      font-weight: 800;

      font-size: 3.4rem;
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 4.6rem;
      }
    }

    &--h2,
    &--h3,
    &--h4,
    &--h5,
    &--h6 {
      font-weight: 600;
    }

    &--h2 {
      font-size: 2.9rem;
      color: ${theme.colors.primary};
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 3.5rem;
      }
    }

    &--h3 {
      font-size: 2.4rem;
      @media (min-width: ${theme.breakpoints.medium}px) {
        font-size: 2.6rem;
      }
    }

    &--h4 {
      font-size: 2.3rem;
    }
    &--h5 {
      font-size: 2rem;
    }
    &--h6 {
      font-size: 1.7rem;
    }

    &--primary {
      color: ${theme.colors.primary};
    }
    &--secondary {
      color: ${theme.colors.secondary};
    }
    &--match {
      color: inherit;
    }
    &--accent {
      font-family: ${theme.fonts.accent};
      letter-spacing: -0.03em;
      font-weight: 800;
    }
    &--center {
      text-align: center;
    }
    &--upcase {
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
  }

  /* cite {
    font-size: 1.8rem;
    &::before {
      content: '— ';
    }
  } */

  /* --- FORM --- */

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  select,
  textarea {
    width: 100%;
    font-size: 1.9rem;
    color: ${theme.colors.darkTint};
    line-height: 1.3;
    border-radius: ${theme.radius}px;
    border: 2px solid ${theme.colors.primary};
    padding-left: 12px;
    padding-right: 12px;

    &:not(textarea) {
      height: 48px;
    }

    transition: all 0.3s ease;

    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.15);

    &:focus {
      outline: none;
      border-color: ${theme.colors.secondary};
      box-shadow: 0 2px 6px 1px ${theme.colors.secondary}22;
    }

    &::placeholder {
      color: #888;
    }
  }

  select {
    background-size: 22px 22px;
    background-position: calc(100% - 12px) 50%;
  }

  textarea {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  label,
  fieldset legend {
    font-weight: 600;
    color: ${theme.colors.primary};
  }

  form .required::after {
    content: '*';
    margin-left: 2px;
  }
`
