import styled from '@emotion/styled'
import React from 'react'

const ExistingClients = () => {
  return (
    <Panel>
      <h4 className="heading heading--h4">
        Services for existing Tier 1 (Entrepreneur) applicants
      </h4>
      <p>
        If you have previously been granted a Tier 1 (Entrepreneur) visa, our
        experienced UK immigration team can assist with Extension applications,
        appeals, transfers to other visa categories and many other matters.
      </p>
    </Panel>
  )
}

export default ExistingClients

const Panel = styled.section`
  padding: 16px;
  border-radius: ${(p) => p.theme.radius}px;
`
