import styled from '@emotion/styled'
import React from 'react'
import { Helmet } from 'react-helmet'

import WhyVisaKings from 'components/contact-why-visa-kings'
import EnquiryForm from 'components/form/enquiry-form'
import { GlobalDisclaimer } from 'components/form/lead-form-notices'
import Layout from 'components/layouts/PrimaryLayout'
import Locations from 'components/offices/locations'
import Panel from 'components/panel'

const ContactPage = () => (
  <Layout focused>
    <Helmet title="Request a Free Assessment: Contact Visa Kings Global">
      <meta
        name="description"
        content="No visa, no fee immigration solutions and expert advice from our lawyers."
      />
    </Helmet>
    <Wrapper>
      <ContactFormContainer className="container collapse">
        <ContactPanel />
        <WhyVisaKings />
      </ContactFormContainer>
      <OfflineSection>
        <div className="container">
          <Locations />
        </div>
      </OfflineSection>
    </Wrapper>
  </Layout>
)

export default ContactPage

const Wrapper = styled.div`
  background: ${(p) => p.theme.colors.lightTintMid};
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    background: linear-gradient(
      160deg,
      ${(p) => p.theme.colors.lightTintMid} 80px,
      #ddd 720px
    );
  }
`

const ContactFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  padding-bottom: 72px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-top: 56px;
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
`

const FormPanelWrapper = styled.div`
  z-index: 10;
  align-self: flex-start;
  flex-grow: 1;

  max-width: 680px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: ${(p) => p.theme.radius}px;
    border-bottom-right-radius: ${(p) => p.theme.radius}px;
  }
`

const Header = styled(Panel.Header)`
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    text-align: center;
    border-top: 0;
  }

  .highlight {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 2rem;
  }
`

const ContentWrapper = styled.div`
  background: ${(p) => p.theme.colors.lightTintDarker};
  padding: 20px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`

const Footer = styled(Panel.Footer)`
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    border-radius: 0;
    border-bottom: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  }
`

const ContactPanel = () => (
  <FormPanelWrapper>
    <Header>
      <h1
        className="heading heading--h3 heading--accent heading--match"
        style={{ margin: 0 }}
      >
        Request a free assessment
      </h1>
      <p className="highlight">
        In many cases we offer <strong>“No Visa, No Fee”</strong> solutions.
      </p>
    </Header>
    <ContentWrapper>
      <EnquiryForm />
    </ContentWrapper>
    <Footer>
      <GlobalDisclaimer />
    </Footer>
  </FormPanelWrapper>
)

const OfflineSection = styled.section`
  background: ${(p) => p.theme.colors.primary};
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    margin-top: 40px;
  }
  color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;

  position: relative;

  .locations {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 40px 24px;
    justify-content: center;
    justify-items: center;
  }
`
