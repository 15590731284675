import React from 'react'
import styled from '@emotion/styled'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'

const CTAContainer = styled.aside`
  content-visibility: auto;
  contain-intrinsic-size: 0 500px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    contain-intrinsic-size: 0 400px;
  }

  background: ${(p) => p.theme.colors.primary};
  position: relative;
  padding-top: 40px;
  padding-bottom: 60px;
  color: #fff;
  text-align: center;

  >.container {
    max-width: 640px;
    position: relative;
  }

  // .microcopy {
  //   font-size: 1.9rem;
  //   font-weight: 400;
  // }

  // .languages {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  //   grid-gap: 4px 16px;
  //   margin 8px 32px 32px;

  //   list-style: none;
  //   font-family: ${(p) => p.theme.fonts.accent};
  //   font-weight: 800;
  //   font-size: 2rem;
  // }

  .contactPanel {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 16px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    border: 2px solid ${(p) => p.theme.colors.secondary};
    border-radius: ${(p) => p.theme.radius}px;
    background: ${(p) => p.theme.colors.primary};
    padding: 24px;
  }

  .tel {
    white-space: nowrap;
    font-size: 2.3rem;
    font-weight: 600;
  }
`

type CTAProps = {
  headline: string,
}

const CTA = ({ headline }: CTAProps) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          telUK
        }
      }
    }
  `)
  const { telUK: telNo } = site.siteMetadata

  return (
    <CTAContainer>
      <StaticImage
        src="../img/filaments-bg.jpg"
        alt="" layout="constrained"
        formats={['auto', 'webp', 'avif']}
        objectFit="cover"
        objectPosition="bottom center"
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />

      <div className="container">
        <div>
          <header>
            <h3 className="heading heading--h2 heading--match heading--accent">
              {headline}
            </h3>
          </header>
          <div className="contactPanel">
            <div className="tel">{telNo}</div>

            <Link
              to="/contact/"
              className="button button--large button--onDark"
            >
              Contact online
            </Link>
          </div>
        </div>
        {/* <div style={{ marginTop: 40 }}> */}
        {/* <h3 className="heading heading--h4">We speak your language</h3>
          <div>
            <p className="microcopy">
              We can discuss your requirements in a number of languages
              including
            </p>
            <ul className="languages">
              <li>Arabic</li>
              <li>Cantonese</li>
              <li>Italian</li>
              <li>Mandarin</li>
              <li>Portuguese</li>
              <li>Russian</li>
              <li>Spanish</li>
              <li>Urdu</li>
            </ul>
          </div> */}

        <p style={{ marginTop: 24 }}>
          We regularly visit the Middle East, China, Vietnam and many other
          countries to meet clients in person.{' '}
          <Link to="/contact/">Contact us</Link> to arrange a personal
          appointment.
        </p>
        {/* </div> */}
      </div>
    </CTAContainer>
  )
}

CTA.defaultProps = {
  headline: 'Request a free assessment',
}

export default CTA
