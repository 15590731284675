import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React from 'react'

import menuIconSrc from '../../icons/expand-disc-icon.svg'
import FlagDisc from '../FlagDisc'

const variants = {
  hidden: { scale: 0.85, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
}

const CountryBlock = (props) => {
  const { code, name, services, handleSelect } = props

  // Check whether country has only one service
  const numServices = services.reduce(
    (acc, currentServices) => acc + currentServices.navList.length,
    0,
  )

  const hasSubNav = numServices > 1
  const buttonLink = numServices === 1 ? services[0].navList[0].path : null

  return (
    <Container variants={variants}>
      <Button
        hasSubNav={hasSubNav}
        handleSelect={() => {
          handleSelect(code)
        }}
        servicePagePath={buttonLink}
      >
        <FlagContainer>
          <FlagDisc size="expand" countryCode={code} />
        </FlagContainer>
        <CountryTitle>{name}</CountryTitle>
      </Button>
    </Container>
  )
}

export default CountryBlock

const Button = ({ hasSubNav, children, handleSelect, servicePagePath }) => {
  if (hasSubNav)
    return (
      <CountrySubnavButton onClick={handleSelect}>
        {children}
      </CountrySubnavButton>
    )

  return (
    <CountryButton as={Link} to={servicePagePath}>
      {children}
    </CountryButton>
  )
}

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    padding: 3px 0; // flag disc overlap
    margin-left: 16px;
  }
`

const CountryTitle = styled.h4`
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1;
  margin: 0;
  color: inherit;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-weight: 600;
    margin-top: 12px;
    font-size: 1.9rem;
  }
`

const FlagContainer = styled.div`
  height: 54px;
  width: 54px;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -16px;
    height: 48px;
    width: 48px;
  }
`

const CountryButton = styled.button`
  background: #fff;
  color: ${(p) => p.theme.colors.primary};
  border: 1px solid ${(p) => p.theme.colors.secondary};
  border-radius: 8px;
  text-align: center;
  text-decoration: underline;

  transform-origin: center bottom;

  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    height: 42px;
    padding-left: 44px;
    padding-right: 8px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    flex-direction: column;
    justify-content: center;
    padding: 16px 24px;
    border-width: 2px;

    box-shadow: 0 0 0 0 ${(p) => p.theme.colors.secondary},
      0 2px 24px rgba(0, 0, 0, 0.15); // first shadow for smoother transition
    transition: box-shadow 0.2s, text-decoration-color 0.2s;

    &:focus,
    &:hover,
    &:active {
      outline: none;
      text-decoration-color: inherit;
      box-shadow: 0 0 0 2px ${(p) => p.theme.colors.secondary},
        0 4px 32px rgba(0, 0, 0, 0.2);
    }
  }
`

const CountrySubnavButton = styled(CountryButton)`
  text-decoration-color: ${(p) => p.theme.colors.primary}55;

  &::after {
    display: block;
    content: '';
    height: 28px;
    width: 28px;
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.darkTint}55;
    background-image: url(${menuIconSrc});
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      margin-left: auto;
    }
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
`
