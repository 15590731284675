import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

import ContactInfo from 'components/Header-ContactInfo'
import Nav from 'components/Header-Nav'

import vkgLogoStackedSrc from '../img/vkg-logo-stacked-on-dark.svg'

const Header = () => {
  return (
    <HeaderContainer>
      <LogoBar>
        <div className="container">
          <div className="header__left">
            <Nav />
            <Link className="logoLink" to="/" rel="home">
              <img
                className="logo"
                src={vkgLogoStackedSrc}
                alt="Visa Kings Global logo"
                width="327"
                height="74"
              />
            </Link>
          </div>
          <div className="header__right">
            <ContactInfo />
          </div>
        </div>
      </LogoBar>
    </HeaderContainer>
  )
}

export default Header

const HeaderContainer = styled.header`
  position: relative;
  position: sticky;
  top: 0;
  z-index: 150;
`

const LogoBar = styled.div`
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.primaryIntense},
    ${(p) => p.theme.colors.primary} 50%
  );

  box-shadow: 0px 0px 8px 1px rgba(0, 0, 10, 0.4);
  border-bottom: 2px solid ${(p) => p.theme.colors.secondary};

  > .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 10px;
    padding-bottom: 14px;

    @media (max-width: ${(p) => p.theme.breakpoints.compactNav - 1}px) {
      padding-bottom: 10px;
    }
  }

  .logoLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;

    align-self: stretch;

    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      flex: 1;
      margin-left: 16px;
    }
  }

  .logo {
    height: 38px;
    width: auto;
    margin-bottom: -2px;

    @media (min-width: ${(p) => p.theme.breakpoints.compactNav}px) {
      height: 48px;
      margin-bottom: -3px;
    }
  }

  .header__left {
    flex: 1 0 auto;

    display: flex;
    align-items: flex-end;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-right: 8px;
    }
  }

  .header__right {
    margin-left: auto;
    flex: 0 1 auto;
  }
`
