import styled from '@emotion/styled'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useContext, useState, useCallback } from 'react'

import countries from '../../data/countries'
import { AppContext } from '../context/app-context'
import CountryNav from './country-nav'
import { ServicesPanel } from './services/ServicesPanel'

const initialNavState = { selectedCountry: null }

const Meganav = ({ isVisible, handleClose }) => {
  const appContext = useContext(AppContext)
  const { setOverlayVisible } = appContext

  const [navState, setNavState] = useState(initialNavState)

  const selectCountry = useCallback(
    (selectedCountry) => {
      setNavState({ ...navState, selectedCountry })
    },
    [navState],
  )

  const deselectCountry = useCallback(() => {
    selectCountry(null)
  }, [selectCountry])

  const isCountrySelected = !!navState.selectedCountry

  useEffect(() => {
    if (isVisible) setOverlayVisible(true)
    return () => {
      setOverlayVisible(false)
    }
  }, [isVisible, setOverlayVisible])
  useEffect(() => {
    return () => {
      setNavState(initialNavState)
    }
  }, [isVisible])

  useEffect(() => {
    const escapeKeyHandler = (e) => {
      if (isVisible && e.key === 'Escape') {
        if (isCountrySelected) {
          deselectCountry()
        } else {
          handleClose()
        }
      }
    }

    if (isVisible) window.addEventListener('keydown', escapeKeyHandler)
    return () => {
      window.removeEventListener('keydown', escapeKeyHandler)
    }
  }, [isVisible, isCountrySelected, deselectCountry, handleClose])

  const country = navState.selectedCountry
    ? countries.find(({ code }) => code === navState.selectedCountry)
    : null

  return (
    <AnimatePresence>
      {isVisible && (
        <Wrapper>
          <Container>
            <CountryNav
              key="CountryNav"
              isCountrySelected={isCountrySelected}
              countries={countries}
              handleSelectCountry={selectCountry}
              handleDeselectCountry={deselectCountry}
              handleClose={handleClose}
            />
            <AnimatePresence>
              {navState.selectedCountry && (
                <ServicePanelWrapper>
                  <ServicesPanel
                    key="ServicesPanel"
                    country={country}
                    handleClose={deselectCountry}
                  />
                </ServicePanelWrapper>
              )}
            </AnimatePresence>
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>
  )
}

export default Meganav

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  right: 16px;
`

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 832px; // 800 + 16px * 2 margin
  z-index: 110;
`

const ServicePanelWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  bottom: 0;
`
