import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import HeroCTA from './home-hero-cta-panel'

const HomeHero = () => (
  <Wrapper>
    <Container className="container">
      <Header>
        <h1 className="heading heading--h1 heading--match">
          Immigration experts
        </h1>
        <p className="heading heading--h4">
          Residency, citizenship and visa services for all your global mobility
          needs
        </p>
        <p className="intro">
          Our experienced legal team provides individuals and organisations with
          effective solutions to achieve their personal and business immigration
          goals.
        </p>
      </Header>
      <CTAWrapper>
        <HeroCTA />
      </CTAWrapper>
    </Container>
    <div className="imageWrapper">
      <StaticImage
        src="../img/global-network-hero.jpg"
        alt=""
        placeholder="none"
        layout="constrained"
        width={1600}
        formats={['auto', 'webp', 'avif']}
        loading="eager"
        style={{
          minHeight: 240,
          margin: '-6.5vw auto 0',
        }}
      />
    </div>
  </Wrapper>
)

const Container = styled.div`
  margin-top: 32px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    margin-top: 72px;
  }
  position: relative;

  z-index: 100;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
`

const Header = styled.header`
  color: #fff;
  text-shadow: 0 2px 6px ${(p) => p.theme.colors.primary}bb;
  max-width: 512px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    flex: 0 0 auto;
    margin-right: 12px;
  }

  .heading--h1 {
    margin: 0;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 5rem;
    }
  }

  .heading--h4 {
    font-weight: 400;
    margin-top: 0.3em;
    font-size: 2rem;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 2.9rem;
    }
    margin-bottom: 0;
  }

  .intro {
    margin-top: 1.4em;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 2rem;
    }
  }
`

const CTAWrapper = styled.div`
  @media (min-width: ${(p) => p.theme.breakpoints.large}px) {
    flex: 0 0 auto;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.large - 1}px) {
    margin-top: 32px;
    margin-bottom: 24px;
  }
`

const Wrapper = styled.section`
  content-visibility: auto;
  contain-intrinsic-size: 0 100vh;

  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.primary},
    ${(p) => p.theme.colors.primaryIntense} 240px
  );
  padding-top: 1px;
`

export default HomeHero
