import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import Panel from 'components/panel'

const Header = styled.header`
  position: relative;
  min-height: 440px;
  max-height: 600px;
  padding-top: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b3cee3;
  > .contentWrapper {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin: 0 auto 60px;
    max-width: 510px;
  }
`

const ServicesOverview = () => {
  const { usaBrochure } = useStaticQuery(graphql`
    {
      usaBrochure: file(
        relativePath: {
          eq: "documents/brochures/visa-kings-global-usa-visas.pdf"
        }
      ) {
        publicURL
      }
    }
  `)
  return (
    <article>
      <Header>
        <StaticImage
          src="../img/usa-new-york-hero.jpg"
          alt=""
          layout="fullWidth"
          loading="eager"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: '0.8',
          }}
          imgStyle={{
            objectPosition: '80% 50%',
          }}
        />
        <div className="container contentWrapper">
          <h1 className="heading--h1 title">USA Investor Visas</h1>
          <p className="hook">
            Investment visa programmes allow a person and their immediate family
            to obtain a Green Card and live permanently in the USA, by investing
            in an enterprise that creates jobs for qualifying workers in the
            USA.
          </p>
        </div>
      </Header>
      <InfoWrapper>
        <div className="container">
          <PackagesContainer>
            <div>
              <Panel.Container>
                <Panel.Header>
                  <h2 className="heading heading--h4">
                    EB-5 investor opportunities
                  </h2>
                </Panel.Header>
                <Panel.Content>
                  <p>
                    For those seeking to live and work in the US, the EB-5 Visa
                    provides an opportunity to obtain a Green Card without the
                    usual requirements of family connections, securing a job or
                    running a business.
                  </p>
                </Panel.Content>
              </Panel.Container>
            </div>
            <div>
              <Panel.Container>
                <Panel.Header>
                  <h2 className="heading heading--h4">
                    E-1/E-2 non-immigrant visas
                  </h2>
                </Panel.Header>
                <Panel.Content>
                  <p>
                    The E-1 and E-2 are temporary visas with reduced
                    requirements, based on agreements between the USA and the
                    applicant’s country of nationality. E-2 Visas can be
                    transitioned to EB-5.
                  </p>
                </Panel.Content>
              </Panel.Container>
            </div>
          </PackagesContainer>
          <div
            className="buttonGroup"
            style={{ maxWidth: '340px', margin: '40px auto 0' }}
          >
            <a
              href={usaBrochure.publicURL}
              download="Visa Kings Global USA Visa Brochure.pdf"
              className="button button--large button--expand"
              onClick={brochureDownloadEvent}
            >
              Download USA Brochure
            </a>
          </div>
        </div>
      </InfoWrapper>
    </article>
  )
}

const brochureDownloadEvent = () => {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'brochure-download',
      brochureName: 'USA Overview',
    })
  }
}

const InfoWrapper = styled.div`
  background: ${(p) => p.theme.colors.lightTintDarker};
  padding-top: 16px;
  padding-bottom: 60px;
`

const PackagesContainer = styled('section')`
  display: flex;
  flex-flow: row wrap;
  > * {
    flex: 1 1 50%;
    min-width: 340px;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex; // stretch panels
  }
`

export default ServicesOverview
