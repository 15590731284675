import React from 'react'
// import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/LandingPageLayout'

const StartUpWebinarPage = () => {
  return (
    <Layout>
      Hello World
    </Layout>
  )
}

export default StartUpWebinarPage