import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'
import BusinessInvestorVisasOverview from 'components/uk-business-investor-visas-overview'
import ServiceOverview from 'components/uk-service-overview'

const UkIndexPage = () => (
  <Layout>
    <Helmet title="Visa Services to Live, Work and Study in the UK">
      <meta
        name="description"
        content="Our London-based team offer expert advice and assistance in all areas of UK immigration law."
      />
      <meta
        property="og:title"
        content="Visa Services to Live, Work and Study in the UK"
      />
      <meta
        property="og:description"
        content="Our London-based team offer expert advice and assistance in all areas of UK immigration law."
      />
    </Helmet>
    <ServiceOverview />
    <hr style={{ margin: 0 }} />
    <BusinessInvestorVisasOverview />
    <CallToAction />
  </Layout>
)

export default UkIndexPage
