import React, { createContext, useState } from 'react'

const AppContext = createContext({})

const AppContextProvider = (props) => {
  const { overlayVisible: initialOverlayVisible, children } = props

  const [overlayVisible, setOverlayVisible] = useState(initialOverlayVisible)

  const context = {
    overlayVisible,
    setOverlayVisible,
  }

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}

AppContextProvider.defaultProps = {
  overlayVisible: false,
}

export { AppContext, AppContextProvider }
