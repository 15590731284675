import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React from 'react'

import Block from './CountryNav-CountryBlock'

const gridVariants = {
  visible: {
    transition: { delayChildren: 0.1, staggerChildren: 0.07 },
  },
}

const CountryGrid = styled(motion.div)`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    grid-template-rows: repeat(auto-fill, 48px);
    max-height: ${48 * 4 + 4 * 3}px;
    max-width: 340px;

    overflow: hidden;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    grid-template-columns: repeat(auto-fill, 200px);
    grid-gap: 24px;
    margin-top: 32px;
  }
`

const Blocks = ({ countries, handleSelectCountry }) => (
  <CountryGrid variants={gridVariants} initial="hidden" animate="visible">
    {countries.map((country) => (
      <Block
        key={country.code}
        {...country}
        handleSelect={handleSelectCountry}
      />
    ))}
  </CountryGrid>
)

export default Blocks
