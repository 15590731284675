import React from 'react'

import { theme } from '../config'

const DEFAULT_COLOR = theme.colors.primary

export const FacebookGlyph = ({ color = DEFAULT_COLOR, ...passthrough }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={color}
    {...passthrough}
  >
    <path
      d="M17,1H3C1.9,1,1,1.9,1,3v14c0,1.101,0.9,2,2,2h7v-7H8V9.525h2V7.475c0-2.164,1.212-3.684,3.766-3.684l1.803,0.002v2.605
	h-1.197C13.378,6.398,13,7.144,13,7.836v1.69h2.568L15,12h-2v7h4c1.1,0,2-0.899,2-2V3C19,1.9,18.1,1,17,1z"
    />
  </svg>
)

export const InstagramGlyph = ({ color = DEFAULT_COLOR, ...passthrough }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill={color}
    {...passthrough}
  >
    <path
      d="M18.94586,6.28931a6.60561,6.60561,0,0,0-.41833-2.18463,4.41189,4.41189,0,0,0-1.03809-1.59412,4.41189,4.41189,0,0,0-1.59412-1.03809,6.60561,6.60561,0,0,0-2.18463-.41833C12.75073,1.01038,12.44427,1,10,1s-2.75073.01038-3.71069,0.05414a6.60561,6.60561,0,0,0-2.18463.41833A4.41189,4.41189,0,0,0,2.51056,2.51056,4.41189,4.41189,0,0,0,1.47247,4.10468a6.60561,6.60561,0,0,0-.41833,2.18463C1.01038,7.24927,1,7.55573,1,10s0.01038,2.75073.05414,3.71069a6.60561,6.60561,0,0,0,.41833,2.18463,4.41189,4.41189,0,0,0,1.03809,1.59412,4.41109,4.41109,0,0,0,1.59412,1.03809,6.60561,6.60561,0,0,0,2.18463.41833C7.24927,18.98969,7.55573,19,10,19s2.75073-.01031,3.71069-0.05414a6.60561,6.60561,0,0,0,2.18463-.41833,4.60208,4.60208,0,0,0,2.6322-2.6322,6.60561,6.60561,0,0,0,.41833-2.18463C18.98962,12.75073,19,12.44427,19,10S18.98962,7.24927,18.94586,6.28931Zm-1.61993,7.34747a4.97824,4.97824,0,0,1-.30994,1.67114A2.98017,2.98017,0,0,1,15.30792,17.016a4.9786,4.9786,0,0,1-1.67114.30994C12.68787,17.3692,12.40326,17.37836,10,17.37836s-2.68787-.00916-3.63678-0.05243A4.9786,4.9786,0,0,1,4.69208,17.016a2.78769,2.78769,0,0,1-1.03485-.67322A2.78769,2.78769,0,0,1,2.984,15.30792a4.97824,4.97824,0,0,1-.30994-1.67114C2.6308,12.68774,2.62164,12.40314,2.62164,10s0.00916-2.68774.05243-3.63678A4.9786,4.9786,0,0,1,2.984,4.69208a2.78769,2.78769,0,0,1,.67322-1.03485A2.78769,2.78769,0,0,1,4.69208,2.984a4.9786,4.9786,0,0,1,1.67114-.30994C7.31226,2.6308,7.59686,2.62164,10,2.62164s2.68774,0.00916,3.63678.05243a4.9786,4.9786,0,0,1,1.67114.30994,2.78769,2.78769,0,0,1,1.03485.67322A2.78769,2.78769,0,0,1,17.016,4.69208a4.9786,4.9786,0,0,1,.30994,1.67114c0.04327,0.949.05243,1.2337,0.05243,3.63678S17.3692,12.68774,17.32593,13.63678ZM10,5.37836A4.62164,4.62164,0,1,0,14.62164,10,4.62169,4.62169,0,0,0,10,5.37836ZM10,13a3,3,0,1,1,3-3A3,3,0,0,1,10,13Zm5.88422-7.8042a1.08,1.08,0,1,1-1.08-1.08A1.08,1.08,0,0,1,15.88422,5.1958Z"
      transform="translate(-1 -1)"
    />
  </svg>
)

export const YouTubeGlyph = ({ color = DEFAULT_COLOR, ...passthrough }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill={color}
    {...passthrough}
  >
    <path d="m32 7c-31.4496 0-32 2.78090909-32 24.5 0 21.7190909.5504 24.5 32 24.5s32-2.7809091 32-24.5c0-21.71909091-.5504-24.5-32-24.5zm10.256 25.5627273-14.368 6.6690909c-1.2576.5790909-2.288-.07-2.288-1.4509091v-12.5618182c0-1.3777273 1.0304-2.03 2.288-1.4509091l14.368 6.6690909c1.2576.5854546 1.2576 1.54 0 2.1254546z" />
  </svg>
)

export const LinkedInGlyph = ({ color = DEFAULT_COLOR, ...passthrough }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill={color}
    {...passthrough}
  >
    <path d="m9.84615385 0h44.30769235c5.4378806 0 9.8461538 4.40827323 9.8461538 9.84615385v44.30769235c0 5.4378806-4.4082732 9.8461538-9.8461538 9.8461538h-44.30769235c-5.43788062 0-9.84615385-4.4082732-9.84615385-9.8461538v-44.30769235c0-5.43788062 4.40827323-9.84615385 9.84615385-9.84615385zm9.70112175 14.4383013c0-2.8099359-2.0435897-5.10897438-5.1089743-5.10897438s-5.10897438 2.55448718-5.10897438 5.36442308c0 2.5544872 2.04358978 4.8535256 5.10897438 4.8535256 3.3208333 0 5.1089743-2.2990384 5.1089743-5.1089743zm-10.21794868 40.8717949h10.21794868v-33.2083334h-10.21794868zm34.74102568-32.6974359c-5.3644231 0-8.4298077 3.0653846-9.7070513 5.1089743h-.2554487l-.5108975-4.3426282h-9.1961538c0 2.8099359.2554487 6.1307692.2554487 9.9625v21.9685898h10.2179487v-18.136859c0-1.0217949 0-1.788141.2554487-2.5544872.7663462-1.788141 2.0435898-4.0871795 4.8535257-4.0871795 3.576282 0 5.1089743 3.0653846 5.1089743 7.1525641v17.6259616h10.2179488v-18.9032052c0-9.4516025-4.8535257-13.7942307-11.2397436-13.7942307z" />
  </svg>
)

export const ChevronGlyph = ({ color = DEFAULT_COLOR, ...passthrough }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill={color}
    {...passthrough}
  >
    <path
      d="m1.74879078 15.9433739c2.40561204-2.492445 5.75470954-2.6880405 8.69551512 0l21.562229 20.9398912 21.562229-20.9398912c2.9408055-2.6880405 6.2954205-2.492445 8.6844802 0 2.405612 2.4868566 2.2511232 6.6893648 0 9.0253334-2.2400883 2.3359687-25.9044691 25.1591647-25.9044691 25.1591647-1.1972886 1.2462225-2.7697644 1.872128-4.3422401 1.872128-1.5724758 0-3.1449515-.6259055-4.353275-1.872128 0 0-23.65334592-22.823196-25.90446912-25.1591647-2.25664065-2.3359686-2.40561204-6.5384768 0-9.0253334z"
      transform="matrix(0 -1 1 0 0 64)"
    />
  </svg>
)
