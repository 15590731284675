import { Link } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const CyprusPage = () => (
  <Layout>
    <Helmet title="Cyprus Residency &amp; Citizenship by Investment Services">
      <meta
        name="description"
        content="We guide clients wishing to relocate within the
          EU through investment and effectively prepare and manage
          applications to make the entire process fast and efficient."
      />
    </Helmet>
    <div className="container compact" style={{ paddingBottom: 72 }}>
      <h1 className="heading heading--h1 heading--center">
        Residency &amp; Citizenship by Investment for Cyprus
      </h1>

      <p className="hook">
        Anyone who has visited Cyprus knows what a wonderful place it is. As
        part of the European Union it also offers very attractive travel and
        residency benefits.
      </p>
      <hr className="subtle" />
      <p>
        The good news for non-residents outside the EU is that Cyprus is
        extremely welcoming of foreign investment. The Cypriot government
        operates reliable Residency and Citizenship by Investment "Golden Visa"
        programmes providing an assured, expedited immigration process.
      </p>
      <p>
        The Residency programme's investment requirements start at €300,000 and
        allow applicants to qualify through property investment.
      </p>

      <h2 className="heading heading--h2 heading--center">Find out more</h2>
      <div style={{ maxWidth: 320, margin: '12px auto' }}>
        <Link
          className="button button--large button--expand"
          to="/cyprus/residency-by-investment/"
        >
          Cyprus
          <br />
          Residency by Investment
        </Link>
        <Link
          className="button button--large button--expand"
          to="/cyprus/citizenship-by-investment/"
          style={{ marginTop: 12 }}
        >
          Cyprus <br />
          Citizenship by Investment
        </Link>
      </div>

      <div>
        <h2 className="heading heading--h2 heading--center">How we can help</h2>
        <p>
          Visa Kings Global guide clients wishing to relocate within the
          European Union through investment and effectively prepare and manage
          applications to make the entire process fast and efficient.
        </p>
        <p>
          We’re always happy to help so if you’d like to speak with one of our
          qualified and experienced advisors on the best way to gain citizenship
          by investment in Cyprus or residency by investment then please speak
          to our team.
        </p>
      </div>
    </div>
    <CallToAction />
  </Layout>
)

export default CyprusPage
