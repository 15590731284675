import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const Tier4SponsorPage = () => (
  <Layout>
    <Helmet title="Apply for a UK Tier 4 Sponsor Licence">
      {/* <meta name="description" content="" /> */}
      {/* <meta property="og:title" content="" /> */}
      {/* <meta property="og:description" content="" /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <article>
          <header style={{ marginTop: 40 }}>
            <h1 className="heading heading--h1 heading--center">
              Apply for a UK Tier 4 Sponsor Licence
            </h1>
          </header>
          <section>
            <h2>
              Are you an educational organisation currently sponsoring
              international students?
            </h2>
            <p>
              The popularity of studying in the UK, from young people across the
              world, is ever increasing and for academic institutions to take
              advantage of the demand they must be a Tier 4 sponsor. The formal
              sponsorship process can be confusing, but we will liaise directly
              with your student immigration office so that your international
              students will have a smooth transition.
            </p>
            <div>
              <h3>We have a variety of services available to you</h3>
              <ul>
                <li>
                  Processing paperwork to help you issue students’ Confirmation
                  of Acceptance for Studies (CAS) quickly and efficiently
                </li>
                <li>
                  Determining applicants’ eligibility for an Academic Technology
                  Approval Scheme (ATAS), if they are pursuing science,
                  engineering, and technology course
                </li>
                <li>
                  Taking your applicants through any screening, interviewing, or
                  government registrations that they must finalise before and
                  during the visa process, so they arrive at the start of the
                  term with complete documentation
                </li>
                <li>
                  Helping you renew your Tier 4 licence every four years as
                  required
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2>
              Is your academic institution looking to offer courses to
              international students for the first time?
            </h2>
            <p>
              If you are an education provider exploring the option of obtaining
              the Tier 4 Sponsor Licence, we can guide you expertly through the
              paperwork.
            </p>
            <div>
              <h3>Here’s how we can help</h3>
              <ul>
                <li>
                  We partner with your institution to facilitate your
                  application for a licence as a Tier 4 General or Tier 4 Child
                  sponsor
                </li>
                <li>
                  During the Probationary Sponsor period, we ensure that you
                  will successfully demonstrate your commitment to your
                  sponsorship duties
                </li>
                <li>
                  As a new sponsor, we help you apply for and pass your annual
                  Basic Compliance Assessment so that you can retain your
                  licence
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2>
              Has your academic institution surrendered or had its licence
              revoked?
            </h2>
            <p>
              If so, we are here to get you right back on the Tier 4 sponsor
              list.
            </p>
            <div>
              <h3>Our action plan may include</h3>
              <ul>
                <li>
                  Clarifying and helping you plan to implement the educational
                  quality standards required to receive and maintain the licence
                </li>
                <li>
                  Ensuring that all of your key personnel are fulfilling their
                  professional duties, as well as complying with immigration
                  rules
                </li>
                <li>
                  Guiding you through the reapplication process, in the case of
                  any civil penalties or fines being levied against your
                  institution
                </li>
              </ul>
            </div>
          </section>
        </article>
      </div>
    </ContentSection>
    <CallToAction />
    <TestimonialsSection />
  </Layout>
)

export default Tier4SponsorPage
