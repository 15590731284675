import React from 'react'
import { Helmet } from 'react-helmet'

import Header from 'components/business-entrepreneur-visa/header'
import IomUk from 'components/business-entrepreneur-visa/iom-uk'
import KeyBenefits from 'components/business-entrepreneur-visa/key-benefits'
import TestimonialsSection from 'components/home-testimonials-section'
import Layout from 'components/layouts/PrimaryLayout'
import ConciergeServices from 'components/packages/concierge-services-section'
import CallToAction from 'components/service-enquiry-section'

const BusEntrepreneurVisaPage = () => (
  <Layout>
    <Helmet title="Business Entrepreneur Visa: Live &amp; Work in the British Isles">
      <meta
        name="description"
        content="The Business Entrepreneur Visa has similar eligibility requirements to the now ended Tier 1 (Entrepreneur) visa and features a number of key advantages."
      />
    </Helmet>
    <Header />
    <KeyBenefits />
    <CallToAction
      enquiryType={{
        category: 'businessUK',
        services: ['businessEntrepreneurVisa'],
      }}
    />
    <IomUk />
    <ConciergeServices />
    <TestimonialsSection />
  </Layout>
)

export default BusEntrepreneurVisaPage
