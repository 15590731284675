import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'
import CallToAction from 'components/speak-your-lang-cta'

const VisaSponsorLicencePage = () => (
  <Layout>
    <Helmet title="UK Visa Sponsor Licence for Employers: Consulting and Compliance Services">
      {/* <meta name="description" content="" /> */}
      {/* <meta property="og:title" content="" /> */}
      {/* <meta property="og:description" content="" /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <article>
          <header style={{ marginTop: 40 }}>
            <h1 className="heading heading--h1 heading--center">
              UK Visa Sponsor Licence
              <br /> for Employers
            </h1>
          </header>
          <section>
            <h2 className="heading heading--h2">
              Are you a UK business looking to recruit talent from outside the
              UK and the EEA?
            </h2>
            <p>
              If a business needs to employ non-UK or non-EEA nationals, it is
              required to hold a PBS (points-based system) Sponsor Licence.
              Obtaining a PBS Sponsor Licence will allow you to apply for a
              number of certificates of sponsorship, granting you access to the
              rich and diverse global workforce.
            </p>
            <div>
              <h3 className="heading heading--h3">
                We offer a full consultancy service for PBS Sponsor Licence
                holders. This includes
              </h3>
              <ul className="list">
                <li>
                  Step by step assistance with applying for new employees to
                  immigrate to the UK under your licence
                </li>
                <li>
                  Maintaining and updating your business details with the Home
                  Office as needed, including regular checks of any new
                  requirements or information requests
                </li>
                <li>
                  Keeping track of your licence expiry date to avoid penalties
                  and ensure your employees continue to be lawfully employed
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Not sure which visa your employee requires?
            </h2>
            <p>
              Our immigration experts will advise you on which visa application
              to submit, based on the individual circumstances of each employee.
            </p>
            <div>
              <h3 className="heading heading--h3">
                There are two main options
              </h3>
              <ul className="list">
                <li>
                  Tier 2 visa - This General visa category allows employees to
                  enter the UK workforce on a long-term basis to fill positions
                  in a wide range of skilled occupations. The job in question
                  must meet the minimum skill level requirements described in
                  the Immigration Rules, or be registered in the current
                  Shortage Occupation List
                </li>
                <li>
                  Tier 5 visa - Also known as the ‘temporary workers’ visa. This
                  category allows entry to work in the UK under a number of
                  specific circumstances, including charity workers;
                  sportspersons or creative workers; diplomats; religious
                  workers; government-authorised exchange programmes; and the
                  Youth Mobility Scheme
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Would your business like a PBS Sponsor Licence?
            </h2>
            <p>
              If your organisation is planning to register for the PBS Sponsor
              Licence, we can streamline the process for you.
            </p>
            <div>
              <h3 className="heading heading--h3">We can offer</h3>
              <ul className="list">
                <li>
                  Gathering all required paperwork and completing the
                  application process
                </li>
                <li>
                  Following up on application status and providing additional
                  information, as requested
                </li>
                <li>
                  Ensuring that your organisation is internally prepared to
                  recruit and relocate new staff to the UK
                </li>
              </ul>
            </div>
          </section>
        </article>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'workUK',
        services: ['sponsorLicence'],
      }}
    />
    <TestimonialsSection />
    <CallToAction />
  </Layout>
)

export default VisaSponsorLicencePage
