import React from 'react'
import { Helmet } from 'react-helmet'

import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const LegalNoticesPage = () => (
  <Layout>
    <Helmet title="Legal Notices">
      <meta
        name="description"
        content="Important information affecting all users of this website."
      />
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <header style={{ textAlign: 'center' }}>
          <h1 className="heading heading--h1">Legal Notices</h1>
          <p className="hook">
            Important information affecting all users of this website.
          </p>
        </header>
        <BodyContent />
      </div>
    </ContentSection>
    <CallToAction />
  </Layout>
)

export default LegalNoticesPage

const BodyContent = () => (
  <div>
    <p>
      Visa Kings Global is a brand name of Canfields Law Limited whose address
      is Bentinck House, 3-8 Bolsover Street, London W1W 6AB. Canfields Law
      Limited is authorised and regulated by the Solicitors Regulation Authority
      (ID 634801).
    </p>
    <p>
      Visa Business Strategies Limited (registered company No. 11545235) promote
      Visa Kings Global services under licence from Canfields Law Limited.
    </p>
    <p>
      Visa Business Strategies is not authorised or regulated by the Solicitors
      Regulation Authority and does not provide legal services, immigration, tax
      or investment advice. Any legal or immigration advice on matters referred
      to on this website must be obtained from solicitors such as those at
      Canfields Law Limited. Further information about Canfields Law Limited can
      be found on <a href="http://www.canfieldslaw.co.uk/">their website</a>.
    </p>
    <p>
      No information received from a non solicitor shall be regarded as anything
      other than general guidance and education on matters of interest not
      linked to a particular case or individual and not as legal or immigration
      advice and shall not be relied upon for making any immigration decision.
    </p>
    <p>
      We use the word "team" to include a person who is a director or employee
      of Canfields Law or a third party service provider who plays an active
      role in delivering our immigration advice or support services under the
      overall supervision of solicitors at Canfields Law.
    </p>
    <p>
      Subject to and on the basis aforementioned above by using the services of
      Visa Business Strategies you are accepting these terms.
    </p>
  </div>
)
