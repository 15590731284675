import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Waypoint } from 'react-waypoint'

import { HighlightsList, HighlightBlock } from '../highlight-blocks'
import VideoPlayer from '../video-player'

const KeyBenefits = () => {
  const [blocksVisible, setBlocksVisible] = useState(false)
  return (
    <Container>
      <motion.div
        className="container compact"
        initial="hidden"
        animate={blocksVisible ? 'visible' : 'hidden'}
      >
        <h2 className="heading heading--h1 heading--center">The opportunity</h2>
        <p className="hook">
          The Business Entrepreneur Visa has similar eligibility requirements to
          the now ended Tier 1 (Entrepreneur) visa and features a number of key
          advantages.
        </p>
        <p>
          We have corporate clients based in the British Isles that are looking
          to develop and expand their businesses by recruiting the right
          overseas businesspeople with the right skills, the right business
          acumen and connections to match their ambitions.
        </p>

        <div style={{ marginTop: 40, marginBottom: 54 }}>
          <Waypoint bottomOffset={160} onEnter={() => setBlocksVisible(true)} />
          <motion.header
            variants={{
              hidden: {
                opacity: 0,
                scale: 0.95,
              },
              visible: {
                opacity: 1,
                scale: 1,
              },
            }}
          >
            <h3 className="heading heading--h2 heading--center">
              Reduced requirements.
              <br />
              Excellent chances of success.
            </h3>
          </motion.header>

          <HighlightsList
            variants={{
              visible: { transition: { staggerChildren: 0.3 } },
            }}
          >
            <HighlightBlock
              title="No interviews"
              extra="for application or extensions"
            />
            <HighlightBlock
              title="No bank letters"
              extra="for faster, simpler application"
            />
            <HighlightBlock
              title="No monitoring"
              extra="between visa applications"
            />
          </HighlightsList>
        </div>
        <VideoPlayer
          url="https://youtu.be/3zxKq21m0v0"
          title="How we can offer the Business Entrepreneur Visa Plan"
        />
        <hr className="subtle" />
        <p>
          Through our deep connections with the Isle of Man (part of the British
          Isles) we have a number of different businesses which are looking to
          expand. These businesses are very welcoming of overseas entrepreneurs
          who can make a meaningful contribution to their businesses as well as
          to the Isle of Man itself.
        </p>
        <p>
          We must make it clear that the opportunities are limited by the number
          of suitable corporate applicants and therefore if you are interested
          you need to bear this closely in mind.
        </p>
      </motion.div>
    </Container>
  )
}

const Container = styled.section`
  padding-top: 32px;
  padding-bottom: 80px;
  background: ${(p) => p.theme.colors.lightTintMid};
  border-bottom: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  min-height: 400px;
  display: flex;
  align-items: center;
`

export default KeyBenefits
