import styled from '@emotion/styled'
import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'

import sendIconSrc from '../icons/send-icon.svg'

const ContactPanel = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          telUK
        }
      }
    }
  `)

  const { telUK: telNo } = data.site.siteMetadata

  return (
    <div className={className}>
      <div className="tel">
        <div>{telNo}</div>
      </div>
      <div>
        <Link className="button button--onDark button--expand" to="/contact/">
          <img
            src={sendIconSrc}
            alt=""
            height="64"
            width="64"
            style={{
              height: '.95em',
              width: 'auto',
              display: 'inline',
              margin: '-0.2em 0.5em -0.1em -0.3em',
            }}
          />
          Contact online
        </Link>
      </div>
    </div>
  )
}

const StyledContactPanel = styled(ContactPanel)`
  background: ${(p) => p.theme.colors.primary};
  color: #fff;
  text-align: center;
  border-radius: ${(p) => p.theme.radius}px;

  ${(p) =>
    p.pinned === 'top' &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;`};

  padding: 8px 8px;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ${(p) =>
    p.stack &&
    `
    flex-direction: column;
    align-items: center;`};

  > * {
    margin: 4px 8px;
  }

  .tel {
    font-size: 2.2rem;
    font-weight: 600;
    white-space: nowrap;
    text-align: left;
    position: relative;
    top: -0.1em;
  }

  .language {
    margin-top: 4px;
    margin-bottom: -2px;
    font-size: 1.5rem;
  }
`

export default StyledContactPanel
