import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ConciergeServices from 'components/packages/concierge-services-section'
import ServiceEnquirySection from 'components/service-enquiry-section'
import VideoPlayer from 'components/video-player'

const IrelandEmployerPermitPage = () => (
  <Layout>
    <Helmet title="Ireland Employer Permit Plan: A Route to Live &amp; Work in the UK via Ireland">
      <meta
        name="description"
        content="A bespoke plan leading to freedom to live and work in the United Kingdom and European Union"
      />
      <meta
        property="og:title"
        content="Ireland Employer Permit Plan: A Route to Live &amp; Work in the UK via Ireland"
      />
      <meta
        property="og:description"
        content="A bespoke plan leading to freedom to live and work in the United Kingdom and European Union"
      />
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <header style={{ marginTop: 40 }}>
          <h1 className="heading heading--center">
            <span className="heading--h1">A Route to Live in the UK</span>
            <br />
            <span className="heading--h2">Ireland Employer Permit Plan</span>
          </h1>
          <div style={{ textAlign: 'center', marginTop: 40, marginBottom: 40 }}>
            <h2 className="heading heading--h4 heading--secondary">
              A bespoke plan leading to:
            </h2>
            <p className="hook" style={{ marginTop: 8, fontWeight: 600 }}>
              Freedom to live and work in the United Kingdom
              <br />
              Freedom to live and work in the European Union
            </p>
          </div>
        </header>

        <VideoPlayer url="https://youtu.be/zMCSVYHI0Ew" />

        <section>
          <h2 className="heading heading--h2">How the plan works</h2>
          <p>
            Visa Kings Global is part of Canfields Law which has several years’
            experience in setting up and supporting active trading companies,
            providing Directors, offices and other corporate mentoring services.
            Using that experience Canfields Law will set up a genuine active
            trading companies that reflect the skills of the non-EEA nationals
            seeking residence and citizenship in Ireland.
          </p>
        </section>
        <section>
          <h2 className="heading heading--h2">Forming a company</h2>
          <p>
            Working with their Irish law partners, Canfields Law will
            incorporate an Irish company with qualifying Directors. The
            applicant will need the relevant qualifications, skills and
            experience required and must play an active role as a key member of
            the company. Complying with the requirements will ensure that after
            just two years, the applicant will be permitted to work without the
            need for any employment permit, and without minimum salary levels.
            At this point you will also be able to establish a business or be
            self-employed.
          </p>
        </section>
        <section>
          <h2 className="heading heading--h2">Irish citizenship</h2>
          <p>
            After five years the applicant can apply for Irish citizenship and
            if successful, gain the right to live and work in the EU or the UK
            without restriction.
          </p>
          <p>
            This advantage of being able to live freely within the United
            Kingdom follows from the UK and Ireland being part of the Common
            Trade Area, established almost 100 years ago. By comparison to the
            now-abolished UK Tier 1 (Entrepreneur) visa, this route offers an
            attractive alternative.
          </p>
          <p>
            Spouses and dependent children up to the age of 23 can be admitted
            as well. Spouses should be able to work in Ireland without the need
            for an employment permit.
          </p>
        </section>
        <section>
          <h2 className="heading heading--h2">
            Why start up a business in Ireland?
          </h2>

          <ul className="list">
            <li>Probably the most open economy in the developed world</li>
            <li>
              Nearly 1,000 global companies have made Ireland the hub of their
              European networks
            </li>
            <li>
              Overseas business and entrepreneurs from abroad find a welcome in
              Ireland
            </li>
            <li>
              Young, highly-educated workforce in a dynamic knowledge-based
              economy
            </li>
            <li>Attractive tax regime</li>
            <li>Common language and culture with the United Kingdom</li>
            <li>No interview or language tests</li>
            <li>Excellent schools and health facilities?</li>
            <li>
              Spouses and dependants up to 23 years old accepted and able to
              work
            </li>
            <li>Listed in Forbes 2019 “Best Countries for Business”</li>
          </ul>
        </section>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'businessUK',
        services: ['irelandEmployerPermit'],
      }}
    />
    <ConciergeServices />
    <TestimonialsSection />
  </Layout>
)

export default IrelandEmployerPermitPage
