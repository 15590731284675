import styled from '@emotion/styled'
import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'
import VideoPlayer from 'components/video-player'
import VisaExplanation from 'components/visa-explanation-section'

const Page = () => (
  <Layout>
    <Helmet title="About UK Business and Investor Visas" />
    <VisaExplanation />
    <VideosSection />
    <CallToAction />
  </Layout>
)

export default Page

const VideosContainer = styled('section')`
  background: ${(p) => p.theme.colors.lightTintDarker};
  padding-bottom: 60px;
  padding-top: 32px;
  .heading {
    margin-bottom: 16px;
  }
`

const VideosSection = () => (
  <VideosContainer>
    <div className="container compact">
      <VideoPlayer
        title="The Key to Success in Getting a UK Tier 1 Visa"
        url="https://youtu.be/r_VUPlibZag"
      />
      <VideoPlayer
        title="Building a Business Plan to Support a Tier 1 Entrepreneur Visa"
        url="https://youtu.be/-f7QgpnjeOY"
      />
      <VideoPlayer
        title="About the UK Visa Home Office Genuineness Test"
        url="https://youtu.be/Zi8MB6J2-l8"
      />
      <VideoPlayer
        title="How we Help Tier 1 Visa Applicants Pass the Genuineness Test Interview"
        url="https://youtu.be/O_GfMqcSqFc"
      />
      <VideoPlayer
        title="Our Services for After You Obtain a UK Visa"
        url="https://youtu.be/B-Ts8vVV1Ig"
      />
      <VideoPlayer
        title="Visa Kings Global Concierge Services"
        url="https://youtu.be/M_7w4yPkEjU"
      />
      <VideoPlayer
        title="What is the Risk with a Tier 1 Entrepreneur Visa?"
        url="https://youtu.be/BJ9W8lqBAZU"
      />
      <VideoPlayer
        title="About UK Tier 1 Investor Visas"
        url="https://youtu.be/BzYPiQYw1EY"
      />
    </div>
  </VideosContainer>
)
