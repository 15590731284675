import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import FindingInnovation from 'components/innovator-visa/finding-innovation'
import Layout from 'components/layouts/PrimaryLayout'
// import About from 'components/innovator-visa/about'
import ConciergeServices from 'components/packages/concierge-services-section'
import CallToAction from 'components/start-up-visa/cta'
import Header from 'components/start-up-visa/header'
import KeyInfo from 'components/start-up-visa/key-info'

const StartUpVisaPage = () => (
  <Layout>
    <Helmet title="UK Start-up Visa Legal & Business Immigration Services">
      <meta
        name="description"
        content="Complete UK Start-up visa application, legal and business services package. Get your idea endorsed."
      />
      <meta
        property="og:title"
        content="UK Start-up Visa Legal & Business Immigration Services"
      />
      <meta
        property="og:description"
        content="Complete Start-up visa application, legal and business services package. Get your idea endorsed."
      />
    </Helmet>
    <Header />
    <KeyInfo />
    <FindingInnovation />
    <CallToAction />
    {/* <About /> */}
    <ConciergeServices />
    <TestimonialsSection />
  </Layout>
)

export default StartUpVisaPage
