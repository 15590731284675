import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import ServiceEnquirySection from 'components/service-enquiry-section'

const VisitorVisaPage = () => (
  <Layout>
    <Helmet title="UK Visitor Visa: Services to Visit the UK for Business or Leisure">
      {/* <meta name="description" content="" /> */}
      {/* <meta property="og:title" content="" /> */}
      {/* <meta property="og:description" content="" /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <article>
          <header style={{ marginTop: 40 }}>
            <h1 className="heading heading--h1 heading--accent heading--center">
              UK Visitor Visa
            </h1>
            <p>
              If you are planning to visit the UK and you are from outside the
              European Economic Area (EEA) you are likely to require a visa
              whether your trip is for business or personal reasons.
            </p>
            <p>
              Your circumstances and reason for visiting dictate which visa you
              should apply for. Our team can advise to make sure the right type
              of application is made and also handle the application process for
              ease.
            </p>
          </header>
          <section>
            <h2 className="heading heading--h2">
              Are you visiting the UK for a holiday or business trip?
            </h2>
            <p>
              If you’re visiting the UK for a short holiday, you may need to
              apply for a Visitor visa. Likewise, if you are coming to the UK
              for short-term business, you can take advantage of either the
              Standard Visitor visa or the Permitted Paid Engagement visa. We
              will ensure you apply for the correct one!
            </p>

            <div>
              <h3 className="heading heading--h3">
                How we handle Visitor visas
              </h3>
              <ul className="list">
                <li>
                  First, we will assess your visa eligibility based on the
                  activities you intend to undertake during your stay in the UK.
                </li>
                <li>
                  Our team will liaise with any UK-based organisations you will
                  be affiliated with to ensure that we have all the paperwork
                  required to apply for the appropriate visa.
                </li>
                <li>
                  Finally, we will submit the application for you and follow up
                  on it to make sure that you don’t miss a single day of your
                  holiday or business trip!
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Looking to stay longer than a holiday?
            </h2>
            <p>
              If you are planning to stay in the UK with your family for over
              six months, you will need to apply for a Family visa. Whether
              you’re joining your partner, a dependent, the spouse of a UK/EU
              national, or looking to bring your spouse and children here, we
              can help. Our team is committed to ensuring that your family is
              reunited and settled as smoothly as possible.
            </p>
            <p>
              As with any visa process, the success of your application depends
              on several factors, including the documentation you provide, your
              knowledge of the English language, your current visa status, and
              in some cases, your private life.
            </p>
            <div>
              <h3 className="heading heading--h3">
                We provide a range of services to help demystify the process of
                applying for a Visitor visa
              </h3>
              <ul className="list">
                <li>
                  Reviewing your situation and identifying the best way to get
                  your application approved
                </li>
                <li>
                  Helping you gather the documentation required to submit a
                  successful application
                </li>
                <li>
                  In cases where your visa status changes (death of a spouse,
                  divorce, extension of visit, completion of education), we will
                  guide you through the process of switching over to or from a
                  family visa
                </li>
              </ul>
            </div>
          </section>
        </article>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'personalUK',
        services: ['visitorVisa'],
      }}
    />
    <TestimonialsSection />
  </Layout>
)

export default VisitorVisaPage
