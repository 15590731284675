import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const TeamSection = () => {
  const imageData = useStaticQuery(graphql`
    fragment teamMemberPhoto on File {
      childImageSharp {
        gatsbyImageData(
          height: 120
          aspectRatio: 1
          layout: FIXED
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    query {
      adnan: file(relativePath: { eq: "img/team/adnan-ghani.jpg" }) {
        ...teamMemberPhoto
      }
      clive: file(relativePath: { eq: "img/team/clive-gregory.jpg" }) {
        ...teamMemberPhoto
      }
      harvey: file(relativePath: { eq: "img/team/harvey-shulman.jpg" }) {
        ...teamMemberPhoto
      }
      eddy: file(relativePath: { eq: "img/team/eddy-laou.jpg" }) {
        ...teamMemberPhoto
      }
      edward: file(relativePath: { eq: "img/team/edward-mackewn.jpg" }) {
        ...teamMemberPhoto
      }
      gill: file(relativePath: { eq: "img/team/gill-johnson.jpg" }) {
        ...teamMemberPhoto
      }
      desmond: file(relativePath: { eq: "img/team/desmond-taylor.jpg" }) {
        ...teamMemberPhoto
      }
      jennifer: file(relativePath: { eq: "img/team/jennifer-simmons.jpg" }) {
        ...teamMemberPhoto
      }
      michael: file(relativePath: { eq: "img/team/michael-rodriguez.jpg" }) {
        ...teamMemberPhoto
      }
      muzaffar: file(
        relativePath: { eq: "img/team/muzaffar-saydiganiev.jpg" }
      ) {
        ...teamMemberPhoto
      }
      harry: file(relativePath: { eq: "img/team/harry-angelides.jpg" }) {
        ...teamMemberPhoto
      }
      alan: file(relativePath: { eq: "img/team/alan-saunders.jpg" }) {
        ...teamMemberPhoto
      }
      an: file(relativePath: { eq: "img/team/an-mai-vu.jpg" }) {
        ...teamMemberPhoto
      }
      diego: file(relativePath: { eq: "img/team/diego-fiorillo.jpg" }) {
        ...teamMemberPhoto
      }
      james: file(relativePath: { eq: "img/team/james-udriot.jpg" }) {
        ...teamMemberPhoto
      }
      jamesMugo: file(relativePath: { eq: "img/team/james-mugo.jpg" }) {
        ...teamMemberPhoto
      }
      pauline: file(relativePath: { eq: "img/team/pauline-curham.jpg" }) {
        ...teamMemberPhoto
      }
      stuart: file(relativePath: { eq: "img/team/stuart-veitch.jpg" }) {
        ...teamMemberPhoto
      }
      shani: file(relativePath: { eq: "img/team/shani-randall.jpg" }) {
        ...teamMemberPhoto
      }
      joanne: file(relativePath: { eq: "img/team/joanne-kong.jpg" }) {
        ...teamMemberPhoto
      }
      jamica: file(relativePath: { eq: "img/team/jamica-cheung.jpg" }) {
        ...teamMemberPhoto
      }
      melanie: file(relativePath: { eq: "img/team/melanie-wong.jpg" }) {
        ...teamMemberPhoto
      }
    }
  `)
  return (
    <div style={{ paddingLeft: 8, paddingRight: 8 }}>
      <TeamGrid>
        <TeamMember
          name="Harvey Shulman"
          title="Lawyer"
          img={imageData.harvey.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Jennifer Simmons"
          title="Lawyer"
          img={imageData.jennifer.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Michael Rodriguez"
          title="Lawyer"
          img={imageData.michael.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Melanie Wong"
          title="Lawyer"
          img={imageData.melanie.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Desmond Taylor"
          title="Lawyer"
          img={imageData.desmond.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Edward Mackewn"
          title="Lawyer"
          img={imageData.edward.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Harry Angelides"
          title="Lawyer"
          img={imageData.harry.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Muzaffar Saydiganiev"
          title="Business Executive"
          img={imageData.muzaffar.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="An Mai Vu"
          title="Business Development"
          img={imageData.an.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Diego Fiorillo"
          title="Business Development"
          img={imageData.diego.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="James Udriot"
          title="Business Development"
          img={imageData.james.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Gill Johnson"
          title="Business Development"
          img={imageData.gill.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Eddy Laou"
          title="Business Development"
          img={imageData.eddy.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Alan Saunders"
          title="Business Development"
          img={imageData.alan.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Pauline Curham"
          title="Coordinator"
          img={imageData.pauline.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Stuart Veitch"
          title="Finance Coordinator"
          img={imageData.stuart.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Shani Randall"
          title="Concierge Services"
          img={imageData.shani.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Joanne Kong"
          alternativeName="江曉梅"
          title="Concierge Services"
          img={imageData.joanne.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Jamica Cheung Ching Lai"
          alternativeName="張貞麗"
          title="Concierge Services"
          img={imageData.jamica.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Clive Gregory"
          title="Hong Kong Representative"
          img={imageData.clive.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="Adnan Ghani"
          title="Pakistan Representative"
          img={imageData.adnan.childImageSharp.gatsbyImageData}
        />
        <TeamMember
          name="James Mugo"
          title="Kenya Representative"
          img={imageData.jamesMugo.childImageSharp.gatsbyImageData}
        />
      </TeamGrid>
    </div>
  )
}

const TeamMember = ({ name, alternativeName, title, img }) => (
  <TeamMemberItem>
    <figure>
      <GatsbyImage
        image={img}
        alt={`${name} — ${title} for Visa Kings Global`}
        style={{ display: 'block', background: '#fff' }}
      />
    </figure>
    <div className="info">
      <h4 className="heading heading--h4 heading--accent info__name">
        {name}
        {alternativeName && ` ${alternativeName}`}
      </h4>
      <div className="info__title">{title}</div>
    </div>
  </TeamMemberItem>
)

const TeamGrid = styled.ul`
  margin: 40px auto;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 32px 48px;
  list-style: none;
`

const TeamMemberItem = styled.li`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border-bottom-right-radius: ${(p) => p.theme.radius}px;
  border-top: 4px solid ${(p) => p.theme.colors.secondary};
  padding-right: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);

  figure {
    flex: 0 0 auto;
    margin: 0;
    border-radius: ${(p) => p.theme.radius}px;
    overflow: hidden;
    border: 2px solid #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-top: -8px;
    margin-bottom: -4px;
  }

  .info {
    margin-left: 16px;
    &__name {
      margin: 0;
    }
    &__title {
      color: ${(p) => p.theme.colors.secondary};
      margin-top: 2px;
      font-size: 1.9rem;
      font-weight: 600;
    }
  }
`

export default TeamSection
