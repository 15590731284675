import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React from 'react'

const PillFlash = ({ text, uppercase = false }) => {
  return (
    <StyledPill
      uppercase={uppercase}
      initial={{
        y: 16,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { delay: 0.5, duration: 0.3 },
      }}
    >
      {text}
    </StyledPill>
  )
}
const StyledPill = styled(motion.div)`
  background: linear-gradient(
    ${(p) => p.theme.colors.alert},
    ${(p) => p.theme.colors.alertIntense}
  );
  box-shadow: 0 0 16px 0 ${(p) => p.theme.colors.alert}77;
  border-radius: 999px;
  color: #fff;
  font-size: 1.7rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  display: inline-block;
  padding: 2px 16px 3px;
  ${(p) =>
    p.uppercase &&
    `
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 1.4rem;
  `}
`

export default PillFlash
