import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

interface IProps {
  children: ReactNode
  className?: string
  contentWidth?: string | number
}

export const BannerContent = styled(({ children, className }: IProps) => (
  <div className={className}>
    <div className="container">{children}</div>
  </div>
))`
  padding: 16px 0 24px;
  margin: 48px 0;
  background: ${(p) => p.theme.colors.lightTintDarker};
  > .container {
    max-width: ${(p) => p.contentWidth || '900px'};
  }
`