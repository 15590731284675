import styled from '@emotion/styled'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const ParallaxWrapper = ({ img, offset, children }) => {
  return (
    <Wrapper>
      <div
        style={{
          position: 'absolute',
          top: -offset,
          bottom: -offset,
          left: 0,
          right: 0,
        }}
      >
        <ParallaxContent offset={offset}>
          {img && (
            <GatsbyImage
              style={{
                height: '100%',
                width: '100%',
              }}
              placeholder="blurred"
              image={img.gatsbyImageData}
              alt={img.alt || ''}
            />
          )}
          {!img && children && children}
        </ParallaxContent>
      </div>
    </Wrapper>
  )
}

ParallaxWrapper.defaultProps = {
  offset: 200,
}

const ParallaxContent = ({ offset, children }) => {
  const { scrollYProgress } = useViewportScroll()
  const yOffset = useTransform(scrollYProgress, [0, 1], [-offset, offset])

  return (
    <motion.div style={{ height: '100%', y: yOffset }} children={children} />
  )
}

export default ParallaxWrapper

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 480px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    height: 640px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(255, 255, 255, 0) 50%,
    //   rgba(255, 255, 255, 1)
    // );
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
  }
`
