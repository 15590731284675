import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

import VideoPlayer from './video-player'

const ServicesOverview = () => (
  <Wrapper>
    <div className="container compact">
      <h2 className="heading heading--h2 heading--match heading--accent">
        Global immigration solutions
      </h2>
      <p>
        Visa Kings Global can provide expert legal advice and effective
        management of visa applications for business and personal immigration
        purposes.
      </p>

      <VideoWrapper>
        <VideoPlayer url="https://youtu.be/-gseWmHPChk" light playing />
      </VideoWrapper>

      <div className="buttonGroup">
        <Link to="/uk/" className="button button--large button--expand">
          UK Visa
          <br />
          Services
        </Link>
        <Link
          to="/usa-visa-services/"
          className="button button--large button--expand"
        >
          USA Visa
          <br />
          Services
        </Link>
        <Link
          to="/citizenship/"
          className="button button--large button--expand"
        >
          Citizenship by
          <br />
          Investment
        </Link>
      </div>
    </div>
  </Wrapper>
)

export default ServicesOverview

const VideoWrapper = styled.div`
  // padding: 1px 24px;
  // border-radius: 8px;
  // background: ${(p) => p.theme.colors.primary};
  // border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.4);
`

const Wrapper = styled.section`
  content-visibility: auto;
  contain-intrinsic-size: 0 800px;

  padding-top: 40px;
  padding-bottom: 72px;

  background: linear-gradient(
    to top,
    ${(p) => p.theme.colors.primary},
    ${(p) => p.theme.colors.primaryIntense}
  );
  border-top: 1px solid ${(p) => p.theme.colors.primary};
  color: #fff;
  text-shadow: 0 2px 2px ${(p) => p.theme.colors.primary};
  text-align: center;

  .heading {
    text-align: center;
    margin-bottom: 16px;
  }
  .buttonGroup {
    margin-top: 32px;
  }

  .button {
    padding: 16px 16px;
    line-height: 1.2;
    max-width: 180px;
    background: ${(p) => p.theme.colors.primary};
    border-width: 2px;
    font-weight: 400;
  }
`
