import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'
import BusinessInvestorVisasOverview from 'components/uk-business-investor-visas-overview'
import VisaExplanation from 'components/visa-explanation-section'

const BusInvestorVisasPage = () => (
  <Layout>
    <Helmet title="Visa Routes to Live and Work in the UK Through Business Investment">
      <meta
        name="description"
        content="Comprehensive business immigration visa services for the UK. Routes to Live and Work in the United Kingdom."
      />
      <meta
        property="og:title"
        content="Visa Routes to Live and Work in the UK Through Business Investment"
      />
      <meta
        property="og:description"
        content="Comprehensive business immigration visa services for the UK. Routes to Live and Work in the United Kingdom."
      />
    </Helmet>
    <BusinessInvestorVisasOverview />
    <VisaExplanation teaser />
    <CallToAction />
  </Layout>
)

export default BusInvestorVisasPage
