import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import PillFlash from '../pill-flash'

import HeroPanel from './hero-panel'

export default () => {
  return (
    <Container>
      <StaticImage
        src="../../img/neural-network-map.jpg"
        alt=""
        className="heroImage"
        formats={['auto', 'webp', 'avif']}
        objectFit="contain"
        objectPosition="bottom center"
        loading="eager"
        layout="fullWidth"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <div className="container compact">
        <PillFlash text="No Visa, No Fee" />
        <HeadlineContainer>
          <h1 className="heading heading--h1 heading--match">
            UK Start-up Visa
          </h1>
        </HeadlineContainer>

        <Subhead>Start your first UK business</Subhead>

        <HeroPanel />
      </div>
    </Container>
  )
}

const Container = styled.div`
  background: ${(p) => p.theme.colors.primary};
  text-align: center;
  padding-top: 32px;
  padding-bottom: 120px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-top: 60px;
    padding-bottom: 280px;
    min-height: 50vw;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.large}px) {
    min-height: 0;
    height: 800px;
  }

  position: relative;
  > .container {
    position: relative;
  }
`

const HeadlineContainer = styled.header`
  color: #fff;
  .heading {
    text-shadow: 0 2px 2px ${(p) => p.theme.colors.primary}55;
    font-size: 4.2rem;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 6rem;
    }
    margin-top: -2px;
  }
`

const Subhead = styled.div`
  font-size: 2.2rem;
  margin-top: 24px;
  margin-bottom: 18px;

  font-weight: 600;
  line-height: 1.25;
  color: #fff;

  text-shadow: 0 2px 2px ${(p) => p.theme.colors.primary}55;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-size: 2.4rem;
    margin-top: 48px;
    margin-bottom: 24px;
  }
`
