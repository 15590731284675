import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'
import Tier1Overview from 'components/tier-1-entrepreneur-overview'

const Tier1EntrepreneurVisaPage = () => (
  <Layout>
    <Helmet title="Tier 1 Entrepreneur Visa Services: Live in the UK through Business Investment">
      <meta
        name="description"
        content="New Entrepreneur route for 2020 offering Tier 1 benefits. Our immigration lawyers will advise you of the best visa solutions to meet your immigration goals."
      />
    </Helmet>
    <Tier1Overview />
    <CallToAction />
  </Layout>
)

export default Tier1EntrepreneurVisaPage
