import React from 'react'
import { Helmet } from 'react-helmet'

import TestimonialsSection from 'components/home-testimonials-section'
import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import PillFlash from 'components/pill-flash'
import ServiceEnquirySection from 'components/service-enquiry-section'
import CallToAction from 'components/speak-your-lang-cta'

const SkilledWorkerVisaPage = () => (
  <Layout>
    <Helmet title="UK Skilled Worker Visa: Immigration Services to Work in the UK">
      {/* <meta name="description" content="" /> */}
      {/* <meta property="og:title" content="" /> */}
      {/* <meta property="og:description" content="" /> */}
    </Helmet>
    <ContentSection>
      <div className="container compact">
        <article>
          <header style={{ marginTop: 40 }}>
            <div style={{ textAlign: 'center' }}>
              <PillFlash text="No Visa, No Fee" />
            </div>
            <h1
              className="heading heading--h1 heading--center"
              style={{ marginTop: 12 }}
            >
              UK Skilled Worker Visa
            </h1>
            <p className="hook">
              The UK Skilled Worker visa replaced the Tier 2 (General) work
              visa.
            </p>
            <hr className="subtle" />
            <p>
              As a leading global economy, the United Kingdom is one of the most
              desirable countries in which to work, offering enormous
              opportunities.
            </p>
            <p>
              Long-term employment in the UK normally requires a work visa. We
              can support both employees and, where necessary employers, to
              facilitate such visas in the most effective manner.
            </p>
          </header>
          <section>
            <h2 className="heading heading--h2">
              Have you been offered work in the UK?
            </h2>
            <p>
              Whether you are just beginning your career or taking the next step
              up in your industry, you may need to apply for a Skilled Worker
              visa to work in the UK legally.
            </p>
            <div>
              <h3 className="heading heading--h3">
                We can guide you through every stage of your entry into the UK
                workforce by
              </h3>
              <ul className="list">
                <li>Helping you search for jobs in your industry</li>
                <li>
                  Ensuring you receive a job offer that matches your
                  qualifications
                </li>
                <li>
                  Working directly with your employer to obtain sponsorship
                </li>
                <li>
                  Submitting your visa application paperwork and following it up
                  until you receive your Skilled Worker visa
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h2 className="heading heading--h2">Are you switching jobs?</h2>
            <p>
              You may need to submit a new Skilled Worker visa application if
              you are changing jobs. Your new employer will have to issue a
              Certificate of Sponsorship for you, under which you can apply for
              a new visa. We can further clarify the conditions under which you
              must change your visa, as well as helping you to understand the
              process.
            </p>
          </section>
          <section>
            <h2 className="heading heading--h2">
              Help with securing rented accommodation in the UK
            </h2>
            <p>
              If you are planning to move to a new residence, your landlord will
              request documents to prove that you are allowed to reside in the
              UK.
            </p>
            <div>
              <h3 className="heading heading--h3">We can help by</h3>
              <ul className="list">
                <li>
                  Gathering and organising documentation proving you have
                  permission to reside in the UK
                </li>
                <li>
                  Liaising with your agent or landlord and reviewing tenancy
                  contracts
                </li>
                <li>
                  Ensuring that all required documents are up to date in the
                  case of renewing a tenancy contract
                </li>
              </ul>
            </div>
            <h2 className="heading heading--h2">
              Visiting the UK on a business trip?
            </h2>
            <p>
              If you are visiting the UK for short-term business, you can take
              advantage of either the standard Visitor visa or the Permitted
              Paid Engagement visa. We will ensure you apply for the correct
              one!
            </p>
            {/* TODO Link to Visitor Visa  */}
          </section>
          <section>
            <h2 className="heading heading--h2">Non-sponsored employment</h2>
            <p>
              Under certain circumstances, you may be able to obtain work in the
              UK without sponsorship. These include entry as a Sole
              Representative of an Overseas Business; media employees on
              long-term assignments; applicants seeking an Innovator/Start-up
              visa; and more.
            </p>
            <div>
              <h3 className="heading heading--h3">
                Securing your immigration status under these circumstances can
                be daunting, but our team can expertly clarify the steps you
                need to take by
              </h3>
              <ul className="list">
                <li>
                  Guiding you through the process of applying for Sole
                  Representative or Innovator/Start-up visas
                </li>
                <li>
                  Ensuring that all your paperwork is up to date in order to
                  avoid penalties
                </li>
                <li>
                  Monitoring your eligibility status for any changes that may
                  require you to seek out sponsorship from a PBS (points-based
                  system) licence-holding organisation
                </li>
              </ul>
            </div>
          </section>
        </article>
      </div>
    </ContentSection>
    <ServiceEnquirySection
      enquiryType={{
        category: 'workUK',
      }}
      heading={
        <header style={{ textAlign: 'center' }}>
          <PillFlash text="No Visa, No Fee" />
          <h3 className="heading heading--h2" style={{ marginTop: 12 }}>
            Might this suit you?
          </h3>
        </header>
      }
    ></ServiceEnquirySection>
    <TestimonialsSection />
    <CallToAction />
  </Layout>
)

export default SkilledWorkerVisaPage
