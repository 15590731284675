import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import canfieldsLogoSrc from '../img/canfields-law-logo.svg'
import { ChevronGlyph } from './icons'

const Overview = () => (
  <Container>
    <HeroImage />
    <div className="container" style={{ maxWidth: 952, position: 'relative' }}>
      <Header />
      <Panel>
        <ServicesGrid>
          <ServiceItem
            title="Business and Investor Visas"
            description="Investment-based routes leading to UK residency and citizenship. We offer a range of innovative plans."
            link="/uk/business-investor-visas/"
          />
          <ServiceItem
            title="Work Visas and Sponsorship"
            description="Visa services to allow non-EEA workers to be employed by UK organisations and live in the UK."
            link="/uk/skilled-worker-visa/"
          />
          <ServiceItem
            title="Student Visas"
            description="All assistance needed to make a visa application to attend a UK educational institution or programme."
            link="/uk/student-visa/"
          />
          <ServiceItem
            title="Visitor Visas"
            description="We make sure the right type of application is made and handle the entire application process."
            link="/uk/visitor-visa/"
          />
          <ServiceItem
            title="Family Visas"
            description="If your family intends to stay in the UK for over six months, you will need to apply for a Family visa."
            link="/uk/family-visa/"
          />
          <ServiceItem
            title="Sponsor Licences for Employers"
            description="Obtaining a PBS Sponsor Licence opens your access to the rich and diverse global workforce."
            link="/uk/visa-sponsor-licence-for-employers/"
          />
          {/* <ServiceItem
            title="Extensions and Appeals"
            description="We carefully handle all work required to give your extension or appeal the best chance of success."
            link="/uk/visa-extensions-appeals/"
          /> */}
          {/* <ServiceItem
            title="Asylum"
            description="We offer a Free Asylum Assessment to anyone unable to leave the UK because they fear persecution."
            link="/uk/asylum/"
          /> */}
        </ServicesGrid>
        <PanelFooter>
          <div>
            <img
              src={canfieldsLogoSrc}
              alt="Canfields Law logo"
              width="364"
              height="81"
              style={{
                margin: '0 auto 10px',
                position: 'relative',
                height: 42,
                width: 'auto',
                top: -4,
                right: -8,
              }}
            />
            <Link to="/uk/legal-services/" className="button button--small">
              Our UK legal services
            </Link>
          </div>
          <p>
            As part of Canfields Law, we also offer a wide range of UK legal
            services and are able to handle complex immigration matters that
            span multiple legal areas.
          </p>
        </PanelFooter>
      </Panel>
    </div>
  </Container>
)

const Container = styled.section`
  content-visibility: auto;
  contain-intrinsic-size: 0 1400px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    contain-intrinsic-size: 0 800px;
  }

  min-height: 640px;
  position: relative;
  padding-top: 80px;
  padding-bottom: 240px;
  overflow: hidden;

  background: #d4f1ff;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    padding-bottom: 60vw;
    background: linear-gradient(to top, #d4f1ff 480px, #f1faff 640px);
  }
`

const Panel = styled.div`
  margin-top: 56px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    background: #fff;
    border-radius: 6px;
    border: 1.5px solid ${(p) => p.theme.colors.secondary};
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
`

const PanelFooter = styled.div`
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding: 16px;
    flex-direction: row;
    background: ${(p) => p.theme.colors.lightTintMid};
    border-top: 1px solid ${(p) => p.theme.colors.lightTintDarker};
    text-align: left;
  }

  p {
    font-size: 1.7rem;
    max-width: 440px;
  }
`

const ServicesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 40px 24px;
  padding: 0 0 40px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding: 28px 32px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 32px 24px;
  }
`

const ServiceItem = ({ title, description, link }) => {
  return (
    <Link to={link}>
      <ServiceItemTitle className="heading heading--h4 heading--secondary">
        {title}{' '}
        <ChevronGlyph
          height="64"
          width="64"
          style={{ height: '0.6em', width: 'auto' }}
        />
      </ServiceItemTitle>
      <div>{description}</div>
    </Link>
  )
}

const Header = () => {
  return (
    <header style={{ textAlign: 'center' }}>
      <HeadingBlock as="h2">
        <HeadingBlockCountry>UK</HeadingBlockCountry>
        <HeadingBlockText> Immigration Services</HeadingBlockText>
      </HeadingBlock>
      <p
        className="heading heading--h3"
        style={{ maxWidth: 580, margin: '18px auto 24px', lineHeight: 1.3 }}
      >
        Our London-based team offer expert advice and assistance in all areas of
        UK immigration law
      </p>
    </header>
  )
}

const HeadingBlock = styled.h2`
  font-size: 2.3rem;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-size: 2.7rem;
  }
  background: ${(p) => p.theme.colors.primaryIntense};
  border: 1px solid ${(p) => p.theme.colors.primaryIntense};
  color: #fff;
  display: inline-flex;
  align-items: center;
  height: 1.6em;
  border-radius: 5px;
  letter-spacing: -0.02em;
  font-weight: 400;
`

const HeadingBlockCountry = styled.span`
  background: ${(p) => p.theme.colors.alertIntense};
  border: 1px solid ${(p) => p.theme.colors.alertIntense};
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-family: ${(p) => p.theme.fonts.accent};
  font-size: 1.6em;

  padding: 0 0.1em 0.15em.1em;
  margin-left: -0.1em;
  height: 1.25em;
  display: flex;
  align-items: center;
`

const HeadingBlockText = styled.span`
  margin-top: -0.05em;
  margin-left: 0.35em;
  margin-right: 0.4em;
  font-size: 1.05em;
`

const ServiceItemTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 8px;
  text-decoration: underline;

  display: flex;
  align-items: center;

  svg {
    margin-top: 3px;
    margin-left: 6px;
    fill: ${(p) => p.theme.colors.secondary};
  }
`

export default Overview

const HeroImage = () => {
  return (
    <StaticImage
      src="../img/westminster-bridge-hero.jpg"
      alt="Westminster Bridge London"
      placeholder="none"
      layout="constrained"
      width={1600}
      formats={['auto', 'webp', 'avif']}
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: 480,
      }}
    />
  )
}
