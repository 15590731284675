import styled from '@emotion/styled'
import React from 'react'

import useClickOutside from '../../lib/useClickOutside'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: ${(p) => (p.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  background: rgba(220, 225, 230, 0.8);
  backdrop-filter: blur(3px);
`

export const Modal = ({ title, children, visible, handleClose }) => {
  const node = useClickOutside(visible, handleClose)
  return (
    <ModalPanel ref={node}>
      <h1 className="modalTitle heading heading--h4">{title}</h1>
      {children}
      <button className="close" onClick={handleClose}>
        &times;
      </button>
    </ModalPanel>
  )
}

const ModalPanel = styled.div`
  pointer-events: auto;
  position: relative;

  background: #fff;
  min-height: 200px;
  max-height: 100vh;

  width: calc(100% - 24px);
  max-width: 720px;

  border: 1px solid ${(p) => p.theme.colors.secondary};
  border-radius: 5px;
  box-shadow: 0 2px 32px -12px rgba(0, 0, 0, 0.3);

  padding: 12px 16px;

  @media (min-width: 640px) {
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .modalTitle {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 1.8rem;

    color: ${(p) => p.theme.colors.secondary};

    @media (min-width: 640px) {
      font-size: 2.2rem;
    }
  }

  .close {
    position: absolute;
    top: 8px;
    left: 8px;

    height: 30px;
    width: 30px;
    border: 0;
    text-align: center;
    font-size: 2.3rem;
    background: 0;
    border-radius: 5px;

    color: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Courier;
    font-weight: bold;
    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
  }
`
