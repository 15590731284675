import styled from '@emotion/styled'
import React from 'react'

import { ContentSection } from 'components/layouts/ContentSection'

import ClosingPanel from './entrepreneur-closing-panel'
import ExistingClientsPanel from './entrepreneur-existing-clients-panel'
import BenefitsPanel from './plan-benefits-panel'

const OverviewContainer = styled(ContentSection)`
  padding-top: 32px;
  padding-bottom: 16px;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-top: 48px;
    padding-bottom: 64px;
  }

  .whyChoose {
    > .heading {
      text-align: center;
      font-size: 2.6rem;
      margin-bottom: 24px;

      @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
        font-size: 3.1rem;
      }
    }
  }

  > .container {
    outline: 4px solid red;
    background: red;
    position: relative;
    display: none;
  }
`

const AnnouncementContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Overview = ({
  title,
  whyChoose,
  highlight,
  benefits,
  children,
  disclaimer,
}) => (
  <OverviewContainer>
    <div className="container compact">
      <AnnouncementContainer>
        <ClosingPanel />
      </AnnouncementContainer>
      <ExistingClientsPanel />
      <section className="whyChoose">
        <h2 className="heading heading--h3 heading--accent">
          Why choose {title}?
        </h2>
        <div className="whyChoose__text">{whyChoose}</div>
        <BenefitsPanel
          title={title}
          highlight={highlight}
          benefits={benefits}
          footerContent={disclaimer}
        />
        {children}
      </section>
    </div>
  </OverviewContainer>
)

export default Overview
