import styled from '@emotion/styled'
import React from 'react'
import ReactPlayer from 'react-player/youtube'

import { FixedAspectBox } from 'components/util/FixedAspectBox'

const VideoPlayer = ({ title, ...passThrough }) => (
  <VideoContainer>
    {title && <div className="title">{title}</div>}
    <FixedAspectBox relativeWidth={16} relativeHeight={9}>
      <ReactPlayer
        controls
        onPlay={() => {
          if (window.dataLayer)
            window.dataLayer.push({ event: 'video-play', videoTitle: title })
        }}
        {...passThrough}
        height="100%"
        width="100%"
        config={{
          playerVars: {
            rel: 0, // show suggested videos from same channel only
          },
        }}
      />
    </FixedAspectBox>
  </VideoContainer>
)

const VideoContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  .title {
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
`

export default VideoPlayer
