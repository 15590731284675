import { css } from '@emotion/react'
import { darken } from 'polished'

import { theme } from '../../config'

export default css`
  button,
  .button {
    &:active {
      outline: 0;
    }
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  .button {
    display: inline-block;
    background: ${theme.colors.secondary};
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0) 30%
      ),
      ${theme.colors.secondary};

    border-color: ${darken(0.04, theme.colors.secondary)};
    border-width: 1px;
    border-bottom-width: 3px;
    border-style: solid;
    border-radius: ${theme.radius}px;
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    padding: 0.5em 24px;
    white-space: nowrap;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    -webkit-appearance: none;

    &:active {
      background-color: ${theme.colors.secondaryLighter};
    }

    &:focus {
      outline: 0;
      &:not(:active) {
        box-shadow: 0 0 3px 1px ${theme.colors.primary};
      }
    }

    &:disabled {
      color: rgba(255, 255, 255, 0.7);
      > * {
        opacity: 0.7;
      }
    }

    &--small {
      font-size: 1.6rem;
    }
    &--large {
      font-size: 2.2rem;
    }
    &--xlarge {
      font-size: 2.5rem;
      padding: 0.65em 120px;
    }

    &--expand {
      width: 100%;
    }

    &--onDark {
      border-color: rgba(255, 255, 255, 0.2);
      border-width: 1px;
    }

    &--textOnly {
      color: inherit;
      border-color: transparent;
      &,
      &:active {
        background: none;
        box-shadow: 0;
      }
    }
  }

  .buttonGroup {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-left: -8px;
    margin-right: -8px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    > * {
      margin: 8px;
    }
  }
`
