import React from 'react'
import { Helmet } from 'react-helmet'

import HomeHero from 'components/home-hero'
import TestimonialsSection from 'components/home-testimonials-section'
import Layout from 'components/layouts/PrimaryLayout'
import ServicesOverview from 'components/services-overview'
import CallToAction from 'components/speak-your-lang-cta'
import UKServiceOverview from 'components/uk-service-overview'

const HomePage = () => (
  <Layout>
    <Helmet
      title="Visa Kings Global: Immigration &amp; Citizenship Experts"
      titleTemplate="%s"
    >
      <meta
        name="description"
        content="Immigration lawyers offering comprehensive No Visa No Fee solutions for the UK, USA and other attractive countries."
      />
      {/* <meta
        property="og:title"
        content="Visa Kings Global: Immigration &amp; Citizenship Experts"
      />
      <meta
        property="og:description"
        content="Business and personal immigration lawyers offering a range of investment visa packages for the UK, USA and other attractive countries."
      /> */}
    </Helmet>
    <HomeHero />
    <UKServiceOverview />
    <ServicesOverview />
    <TestimonialsSection />
    <CallToAction />
  </Layout>
)

export default HomePage
