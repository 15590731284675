import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'components/layouts/PrimaryLayout'
import ConciergeServices from 'components/packages/concierge-services-section'
import Overview from 'components/packages/kingdom-plan-overview-section'
import Header from 'components/packages/kingdom-plan-page-header'
import {
  BenefitsGroup,
  BenefitListItem as Benefit,
} from 'components/packages/plan-benefits-panel'
import CallToAction from 'components/speak-your-lang-cta'
import VideoPlayer from 'components/video-player'

import conciergeIconSrc from '../../../icons/concierge-icon.svg'
import interviewIconSrc from '../../../icons/interview-icon.svg'
import optionsIconSrc from '../../../icons/options-icon.svg'
import reportIconSrc from '../../../icons/report-icon.svg'

const KingdomEntrepreneurVisaPage = () => (
  <Layout>
    <Helmet title="UK Entrepreneur Visa Service">
      <meta
        name="description"
        content="Tier 1 Entrepreneur immigration package offering a truly tailored service for every applicant."
      />
    </Helmet>
    <Header title="Entrepreneur" subhead="Tier 1 Entrepreneur visa" />
    <Overview
      title="Entrepreneur"
      whyChoose={
        <>
          <p>
            The UK Tier 1 (Entrepreneur) visa category has been an extremely
            effective route for non-EEA nationals wishing to establish in the UK
            through business investment.
          </p>
          <p>
            Visa Kings Global have helped many clients make successful
            applications through the Entrepreneur visa and have therefore made
            significant arrangements so that we may continue to offer clients
            this advantageous visa via the Isle of Man.
          </p>
          <VideoPlayer
            url="https://youtu.be/bRqQNun4NPc"
            title="About the Isle of Man Entrepreneur route"
          />
          <p>
            We provide all the services needed to make a successful visa
            application with minimal effort on your behalf.
          </p>
          <p>
            Every applicant and therefore every application is different so we
            offer truly tailored services for each client.
          </p>
        </>
      }
      highlight="We put everything in place to facilitate a successful Entrepreneur visa application suited to your expertise, goals and requirements."
      benefits={
        <BenefitsGroup>
          <Benefit
            title="A range of business opportunities"
            icon={{ src: optionsIconSrc }}
          >
            Carefully selected for suitability with visa requirements
          </Benefit>
          <Benefit
            title="Assistance creating a professional Business Plan"
            icon={{ src: reportIconSrc }}
          >
            Crucial for a successful application
          </Benefit>
          <Benefit title="Interview training" icon={{ src: interviewIconSrc }}>
            Training to help prepare for the Home Office interview
          </Benefit>
          <Benefit
            title="Comprehensive concierge service"
            icon={{ src: conciergeIconSrc, alt: 'Concierge Services' }}
          >
            Provided through Angel Concierge Services
          </Benefit>
        </BenefitsGroup>
      }
      disclaimer={
        <p>
          No investment advice is given on the merits of any particular
          investment either put forward for consideration or set up by the
          applicant.
        </p>
      }
    />
    <ConciergeServices />
    <CallToAction />
  </Layout>
)

export default KingdomEntrepreneurVisaPage
