import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React from 'react'

import AboutUsMobileNav from './AboutUsMobileNav'
import CloseButton from './CloseButton'
import CountryBlocks from './CountryNav-CountryBlocks'

const CountryNav = ({
  countries,
  handleSelectCountry,
  handleDeselectCountry,
  handleClose,
  isCountrySelected,
}) => {
  return (
    <Panel
      animate={{ scale: isCountrySelected ? 0.98 : 1, opacity: 1 }}
      transition={{ type: 'tween' }}
      initial={{ scale: 0.95, opacity: 0 }}
      exit={{ scale: 0.98, opacity: 0 }}
      onClick={() => {
        if (isCountrySelected) handleDeselectCountry()
      }}
    >
      <Heading
        className="heading heading--accent heading--primary heading--center"
        initial={{ y: 5, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.3 }}
      >
        Immigration services
      </Heading>
      <CountryBlocks
        countries={countries}
        handleSelectCountry={handleSelectCountry}
      />
      <MobileOnly>
        <AboutUsMobileNav />
      </MobileOnly>
      <CloseButton handleClose={handleClose}>Close</CloseButton>
    </Panel>
  )
}

export default React.memo(CountryNav)

const Panel = styled(motion.div)`
  background: ${(p) => p.theme.colors.lightTintDarker};
  padding: 20px 32px 0;
  border-radius: 12px;
  overflow: hidden;
  transform-origin: center bottom;

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding: 32px 40px 64px;
  }
`

const Heading = styled(motion.h2)`
  margin: 0;
  font-size: 2.8rem;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    font-family: inherit;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: ${(p) => p.theme.colors.darkTint}aa;
  }
`

const MobileOnly = styled.div`
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    display: none;
  }
`
