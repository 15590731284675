import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'

const ConsultationBookingPage = () => {
  useEffect(() => {
    window.dataLayer.push({
      event: 'consultation-booked',
    })
  }, [])
  return (
    <Layout>
      <Helmet title="Consultation Booked">
        <meta name="robots" content="noindex" />
      </Helmet>
      <ContentSection>
        <div className="container compact" style={{ textAlign: 'center' }}>
          <header>
            <h1 className="heading heading--h1">Consultation Booked</h1>
            <h2 className="heading heading--h2" style={{ marginTop: 12 }}>
              Thank you
            </h2>
          </header>
          <div>
            <p className="hook">
              Your consultation has been scheduled with our immigration team.
            </p>
            <p>An email has been sent with your consultation details.</p>
          </div>
        </div>
      </ContentSection>
    </Layout>
  )
}

export default ConsultationBookingPage
