import React from 'react'
import useCountdown from '../lib/useCountdown'
import styled from '@emotion/styled'

type CountdownProps = {
  dateString: string
  hidePassed?: boolean
}

const Countdown = ({ dateString, hidePassed }: CountdownProps) => {
  const { remaining, days, hours, minutes, seconds } = useCountdown(dateString)

  if (typeof remaining != 'number' || (hidePassed && remaining < 0)) return null

  return (
    <CountdownContainer>
      <TimeBlock val={days} label="Days" />
      <TimeBlock val={hours} label="Hours" />
      <TimeBlock val={minutes} label="Minutes" />
      <TimeBlock val={seconds} label="Seconds" />
    </CountdownContainer>
  )
}

export default Countdown

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(4, minmax(72px, auto));
  grid-gap: 8px;
`

const TimeBlock = ({ val, label }) => {
  return (
    <Block>
      <div className="value">{val}</div>
      <div className="label">{label}</div>
    </Block>
  )
}

const Block = styled.div`
  text-align: center;
  border: 2px solid #eee;
  background: #fff;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 6px;
  color: ${(p) => p.theme.colors.primary};

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-top: 4px;
    padding-bottom: 5px;
  }

  .value {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.1;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 2.2rem;
    }
  }

  .label {
    text-transform: lowercase;
    font-size: 0.85em;
    line-height: 1.1;
    margin-bottom: 1px;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-bottom: 2px;
    }
  }
`
