import * as Sentry from '@sentry/browser'
import React, { createContext, useState, useEffect } from 'react'

import { IPDATACO_KEY } from '../../config'

const UserContext = createContext({})

const UserContextProvider = ({ children }) => {
  const [locationData, setLocationData] = useState(null)
  // const [gclid, setGclid] = useState(null)
  // const [gaClientId, setGaClientId] = useState(null)

  const context = {
    locationData,
    // gclid,
    // gaClientId,
  }

  useEffect(() => {
    if (!window.fetch) return

    // defer loading location data unless on contact page
    // TODO cache result to localStorage
    const delay = window.location.pathname.includes('contact') ? 0 : 2000

    setTimeout(() => {
      window
        .fetch(`https://api.ipdata.co?api-key=${IPDATACO_KEY}`)
        .then((res) => {
          if (res.ok !== true)
            throw new Error(
              `Couldn't fetch IP: ${res.status} ${res.statusText}`,
            )
          return res.json()
        })
        .then((data) => {
          if (data?.threat.is_anonymous !== true)
            setLocationData({
              ip: data.ip,
              countryCode: data.country_code,
              countryName: data.country_name,
              timezone: {
                name: data.time_zone.name,
                abbr: data.time_zone.abbr,
                offset: data.time_zone.offset,
              },
            })
        })
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setLevel('warning')
            Sentry.captureException(err)
          })
          console.log(err)
        })
    }, delay)
  }, [setLocationData])

  // * Note: prefetch country flag image as will be requested by react-phone-number-input
  return (
    <UserContext.Provider value={context}>
      {/* {context?.locationData?.countryCode && (
        <Helmet>
          <link
            rel="prefetch"
            href={`https://flag.pk/flags/4x3/${context.locationData.countryCode}.svg`}
            as="image"
            crossorigin="anonymous"
          />
        </Helmet>
      )} */}
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserContextProvider }
