import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { useState } from 'react'

import chevronRightIconSrc from '../../../icons/chevron-right-icon.svg'
import FlagDisc from '../../FlagDisc'
import CloseButton from '../CloseButton'
import ServiceGroupSwitcher from './ServiceGroupSwitcher'

export const ServicesPanel = React.memo(({ country, handleClose }) => {
  const { services } = country
  const numServiceGroups = services.length

  const [activeServiceGroupIndex, setActiveServiceGroupIndex] = useState(0)

  const serviceGroup = services[activeServiceGroupIndex]

  return (
    <Panel
      initial={{
        scale: 0.95,
        opacity: 0,
      }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{
        scale: 0.98,
        opacity: 0,
      }}
      transition={{
        type: 'tween',
        duration: 0.15,
      }}
    >
      <Header
        country={country}
        indexPath={serviceGroup.path || country.pagePath}
      />
      {numServiceGroups > 1 && (
        <ServiceGroupSwitcher
          serviceGroups={services}
          activeGroupIndex={activeServiceGroupIndex}
          handleClick={setActiveServiceGroupIndex}
        />
      )}
      <ServicesWrapper>
        <Services servicesList={serviceGroup.navList} />
      </ServicesWrapper>
      <CloseButton handleClose={handleClose} />
    </Panel>
  )
})

const ServicesWrapper = styled.div`
  margin: 0 auto;
  max-width: 640px;
  padding-top: 24px;
  padding-bottom: 32px;
  padding-left: 16px;
  padding-right: 16px;
`

const HeaderWrapper = styled.header`
  background: #fff;
  padding: 24px 0 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.secondary};
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    padding: 16px 0;
    display: flex;
    justify-content: center;
  }
`

const HeaderContainer = styled.div`
  max-width: 640px;
  display: flex;
  align-items: center;
`

const HeaderInner = styled.div`
  margin-top: 2px;
  height: 50px;
  width: 50px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    margin-top: 8px;
    height: 54px;
    width: 54px;
  }
`

const Heading = styled.h2`
  margin: 0;
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    font-size: 2.4rem;
    margin-right: 40px;
  }
  font-size: 3.2rem;
`

const CountryLink = styled(Link)`
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.secondary};
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 0.03em;
`

const Header = ({ country, indexPath }) => {
  return (
    <HeaderWrapper>
      <HeaderContainer className="container">
        <HeaderInner>
          <FlagDisc countryCode={country.code} size="expand" />
        </HeaderInner>

        <div style={{ marginLeft: 12 }}>
          <Heading className="heading heading--accent heading--primary">
            Routes to {country.shortNameDefiniteArticle && 'the '}
            {country.shortName || country.name}
          </Heading>
          {indexPath && (
            <CountryLink to={indexPath}>
              View all {country.shortName} services &gt;
            </CountryLink>
          )}
        </div>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

const Panel = styled(motion.div)`
  background: ${(p) => p.theme.colors.lightTintDarker};
  overflow: hidden;
  border-radius: 12px;
  min-height: 100%;
  transform-origin: bottom center;
`

export const ServicesList = styled(motion.ul)`
  list-style: none;
  display: grid;
  margin-left: auto;
  margin-right: auto;

  grid-gap: 4px 12px;
  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    max-width: 300px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Services = ({ servicesList }) => {
  return (
    <ServicesList
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: { staggerChildren: 0.05, delayChildren: 0.1 },
        },
      }}
    >
      {servicesList.map(({ title, path }) => (
        <motion.li
          key={title}
          variants={{
            hidden: { opacity: 0, x: -20 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <ServiceItem title={title} path={path} />
        </motion.li>
      ))}
    </ServicesList>
  )
}

const ServiceItemWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`

const ServiceItemLink = styled(Link)`
  flex: 1;
  padding: 6px 12px;
  font-size: 1.75rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  text-decoration: underline;
  color: ${(p) => p.theme.colors.primary};
`

export const ServiceItem = ({ title, path }) => {
  return (
    <ServiceItemWrapper>
      <ServiceItemLink to={path}>
        {title}
        <img src={chevronRightIconSrc} alt="" height="12" width="12" />
      </ServiceItemLink>
    </ServiceItemWrapper>
  )
}
