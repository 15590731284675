import { Global } from '@emotion/react'
import 'normalize.css/normalize.css'
import React from 'react'

import '../../fonts/font-face.css'
import baseStyles from '../../lib/styles/base'
import buttonStyles from '../../lib/styles/buttons'
import typographicStyles from '../../lib/styles/typography'

const GlobalStyles = ({ styles = {} }) => {
  const globalStyles = [typographicStyles, baseStyles, buttonStyles, styles]
  return <Global styles={globalStyles} />
}

export default GlobalStyles
