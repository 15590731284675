import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { motion, AnimatePresence } from 'framer-motion'
import { transparentize, desaturate } from 'polished'
import React, { useContext } from 'react'

import { theme } from '../config'
import { AppContext } from './context/app-context'

const overlayColor = transparentize(0.25, desaturate(0.5, theme.colors.primary))
const disableScrollStyle = css`
  body {
    overflow: hidden;
  }
`

const OverlayDiv = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${overlayColor};
  z-index: 100;
`

const Overlay = () => {
  const { overlayVisible } = useContext(AppContext)

  return (
    <>
      {overlayVisible && <Global styles={disableScrollStyle} />}
      <AnimatePresence>
        {overlayVisible && (
          <OverlayDiv
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Overlay
