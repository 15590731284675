import styled from '@emotion/styled'
import React from 'react'

import FlagDisc from '../FlagDisc'

const HeroPanel = () => (
  <Panel>
    <header>
      <Heading className="heading heading--h3">
        You can become a{' '}
        <HeadingFeatureBar>
          British Innovator
          <FlagContainer>
            <FlagDisc size={54} countryCode="GB" />
          </FlagContainer>
        </HeadingFeatureBar>
      </Heading>
    </header>
    <p>
      Our comprehensive legal and business services help you access the many
      benefits of <strong>UK residency</strong> and start your path to{' '}
      <strong>British Citizenship</strong>.
    </p>
  </Panel>
)

const Panel = styled.div`
  max-width: 360px;
  color: ${(p) => p.theme.colors.primary};
  margin-left: auto;
  margin-right: auto;
  padding: 12px 20px;
  background: rgba(251, 255, 248, 0.9)
    linear-gradient(#fff, rgba(255, 255, 255, 0.5) 57%);
  border: 2px solid #fff;
  box-shadow: 0 2px 30px 3px ${(p) => p.theme.colors.primary}22;
  border-radius: 5px;
  text-align: left;
  p {
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 8px;

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      font-size: 1.9rem;
    }
  }
`

const Heading = styled.h2`
  font-size: 2.2rem;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 12px;
`

const HeadingFeatureBar = styled.div`
  max-width: calc(100% + 13px);
  font-size: 2.7rem;
  background: ${(p) => p.theme.colors.secondary};
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin-top: 2px;
  margin-left: -26px;
  padding-left: 26px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 40px;
    background: blue;
    transform: skew(-20deg);

    position: absolute;
    top: 0;
    bottom: 0;
    right: -10px;
    background: ${(p) => p.theme.colors.secondary};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  display: flex;
  align-items: center;
`

const FlagContainer = styled.div`
  position: absolute;
  right: 8px;
  // required for iOS
  top: 50%;
  transform: translateY(-50%);
`

export default HeroPanel
