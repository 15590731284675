import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import countryData from '../data/countries'

const DiscContainer = styled.div`
  border-radius: 999px;
  border: 2px solid ${(p) => p.theme.colors.secondary};
  background: #fff;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  border: 3px solid #fff;
  border-radius: 999px;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1; // safari fix
`

const FlagDisc = ({ countryCode, size = 44 }) => {
  const data = useStaticQuery(graphql`
    {
      # CA: file(relativePath: { eq: "img/flags/square/ca.png" }) {
      #   ...flagPhoto
      # }
      CY: file(relativePath: { eq: "img/flags/square/cy.png" }) {
        ...flagPhoto
      }
      DM: file(relativePath: { eq: "img/flags/square/dm.png" }) {
        ...flagPhoto
      }
      EU: file(relativePath: { eq: "img/flags/square/eu.png" }) {
        ...flagPhoto
      }
      GB: file(relativePath: { eq: "img/flags/square/gb.png" }) {
        ...flagPhoto
      }
      IE: file(relativePath: { eq: "img/flags/square/ie.png" }) {
        ...flagPhoto
      }
      PT: file(relativePath: { eq: "img/flags/square/pt.png" }) {
        ...flagPhoto
      }
      US: file(relativePath: { eq: "img/flags/square/us.png" }) {
        ...flagPhoto
      }
    }

    fragment flagPhoto on File {
      childImageSharp {
        gatsbyImageData(
          height: 44
          aspectRatio: 1
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  `)

  const country = countryData.find(({ code }) => code === countryCode)
  const flagImage = data[countryCode] ?? (country.isEu ? data.EU : null)
  const outerSize = size === 'expand' ? '100%' : size

  if (flagImage === null) return null

  return (
    <DiscContainer style={{ width: outerSize, height: outerSize }}>
      <ImageWrapper>
        <GatsbyImage
          image={getImage(flagImage)}
          alt={country ? `${country.name} flag` : ''}
          style={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      </ImageWrapper>
    </DiscContainer>
  )
}

export default FlagDisc
