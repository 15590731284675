import { css } from '@emotion/react'

import { theme } from '../../config'

export default css`
  html {
    box-sizing: border-box;
  }

  body {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    color: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin-top: 0.3em;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .container {
    max-width: ${theme.breakpoints.large}px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;

    // @media (min-width: ${theme.breakpoints.medium}px) {
    //   padding-left: 32px;
    //   padding-right: 32px;
    // }

    &.collapse {
      padding-left: 0;
      padding-right: 0;
    }

    &.compact {
      max-width: 720px;
    }
    &.compact.collapse {
      max-width: 672px; // 720 - 24 - 24
    }
  }

  figure {
    margin: 0;
  }

  figcaption {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 4px;
    font-weight: 400;
  }

  .imageWrapper {
    display: flex;
  }

  .grecaptcha-badge {
    display: none;
  }
`
