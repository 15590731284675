import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'

import LocationBlock from './location-block'

const Locations = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          offices {
            name
            orgName
            address {
              streetAddress1
              streetAddress2
              locality
              district
              postalCode
            }
            telephone1
            telephone2
          }
        }
      }
    }
  `)
  const { offices } = data.site.siteMetadata
  const mainOffice = offices.find(({ name }) => name === 'London')
  return (
    <div className="locations">
      <LocationBlock
        key={mainOffice.name}
        name={mainOffice.name}
        telephone1={mainOffice.telephone1}
        telephone2={mainOffice.telephone2}
        address={{ orgName: mainOffice.orgName, ...mainOffice.address }}
      />
    </div>
  )
}

export default Locations
