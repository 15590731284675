import { ThemeProvider } from '@emotion/react'
import * as Sentry from '@sentry/browser'
import React from 'react'

import pkg from './package.json'
import { AppContextProvider } from './src/components/context/app-context'
import { ContactContextProvider } from './src/components/context/contact-context'
import { UserContextProvider } from './src/components/context/user-context'
import { theme, SENTRY_DSN, RECAPTCHA_SITE_KEY } from './src/config'

// GA Fix Utility Functions
function getParam(p) {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

// Save GCLID to cookie for later use
function setCookie(name, value, days) {
  const date = new Date()
  date.setDate(date.getDate() + days)
  const expiryString = date.toUTCString()
  document.cookie = `${name}=${value};expires=${expiryString};path=/`
}

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: `${pkg.name}@${pkg.version}`,
      environment: process.env.GATSBY_APP_ENV,
      ignoreErrors: [
        // https://github.com/gatsbyjs/gatsby/issues/18866
        'ChunkLoadError',
        // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
        'Timeout',
      ],
    })
  }

  window.dataLayer = window.dataLayer || []

  // GTM/GA "Rogue Referral" Fix
  // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  window.dataLayer.push({ originalLocation: window.location.href })
}

export const onInitialClientRender = () => {
  const gclid = getParam('gclid')

  if (gclid) {
    const gclsrc = getParam('gclsrc')
    if (!gclsrc || gclsrc.indexOf('aw') !== -1) setCookie('gclid', gclid, 90)
  }

  window.recaptchaObserver = new IntersectionObserver(
    (entries, observer) => {
      if (entries[0].isIntersecting) {
        const s = document.createElement('script')
        s.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
        s.defer = true
        s.async = true
        document.head.appendChild(s)
        observer.unobserve(entries[0].target)
      }
    },
    {
      rootMargin: '-50% 0px 0px 0px',
    },
  )

  if (process.env.NODE_ENV === 'production') {
    console.info(
      '💻📱 LIKE TO CODE?',
      '\nWe have a number of job openings for frontend UX, backend, dev ops, iOS/Android and Unity devs which qualify for residence rights.',
      '\nEmail hello@visakingsglobal.com for more information.',
    )
  }
}

export const wrapRootElement = ({ element }) => (
  <UserContextProvider>
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <ContactContextProvider>{element}</ContactContextProvider>
      </AppContextProvider>
    </ThemeProvider>
  </UserContextProvider>
)

export const onRouteUpdate = () => {
  // TODO test whether GTM plugin still needs delayed update
  // sendRouteUpdate()

  // rAF required to allow react-helmet to update page title
  requestAnimationFrame(() => {
    requestAnimationFrame(sendRouteUpdate)
  })
}

const sendRouteUpdate = () => {
  window.dataLayer.push({
    event: 'delayed-route-update',
    pathWithQuery: window.location.pathname + window.location.search,
  })
}

let nextRoute = ``

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}

window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute
    }
  }
})
