import styled from '@emotion/styled'
import React from 'react'

import KingdomPlan from '../kingdom-plan-panel'
import ClosingPanel from '../packages/entrepreneur-closing-panel'
import ExistingClientsPanel from '../packages/entrepreneur-existing-clients-panel'

const PlansContainer = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 32px 16px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  justify-content: center;
  justify-items: center;
  align-items: center;
`

const Tier1Grid = () => (
  <>
    <PlansContainer>
      <ClosingPanel darkPanel />
      <KingdomPlan
        noKingdom
        title="Business Entrepreneur Visa"
        callout="New for 2020"
        highlights="Route to British Citizenship for business entrepreneurs, with reduced requirements"
        link="/uk/business-entrepreneur-visa/"
      />
    </PlansContainer>
    <hr
      style={{
        marginTop: 80,
        marginBottom: 80,
        background: 'rgba(255,255,255,.2)',
      }}
    />
    <PlansContainer>
      <ExistingClientsPanel />
      <KingdomPlan
        noKingdom
        title="Tier 1 (Entrepreneur) Visa"
        callout="Ended 31/03/20"
        highlights="Everything you need for a successful Entrepreneur visa application in one unmatched package"
        link="/uk/tier-1-entrepreneur-visa/kingdom-entrepreneur/"
      />
    </PlansContainer>
  </>
)

export default Tier1Grid
