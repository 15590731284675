import styled from '@emotion/styled'
import { Form } from 'formik'
import React, { useContext } from 'react'

import webinarForm from '../../forms/webinar-register/client'
import { UserContext } from '../context/user-context'
import Field from './field'
import FormContainer from './form-container'
import TelField from './tel-field'
import { handleSubmit, prewarm } from './webinar-register-handler'

const WebinarRegisterForm = ({ eventId, onSuccess }) => {
  const { locationData } = useContext(UserContext)
  const detectedCountryCode = locationData?.countryCode

  const initialValues = { ...webinarForm.initialValues }

  return (
    <FormContainer
      schema={webinarForm.schema}
      initialValues={initialValues}
      handleSubmit={(formData) =>
        handleSubmit({ eventId, formData, locationData }).then(onSuccess)
      }
      prewarm={prewarm}
    >
      {({ isSubmitting }) => (
        <Form name="webinar-register">
          <FieldContainer>
            <Field
              name="firstName"
              type="text"
              label="First name"
              autoComplete="given-name"
              required
            />
            <Field
              name="lastName"
              type="text"
              label="Last name"
              autoComplete="family-name"
              required
            />
            <Field
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              required
            />
            <Field
              name="tel"
              label="Telephone"
              fieldComponent={TelField}
              detectedCountryCode={detectedCountryCode}
              required
            />
          </FieldContainer>

          <ButtonGroup>
            <SubmitButton
              className="button button--large"
              type="submit"
              disabled={isSubmitting}
            >
              Register
            </SubmitButton>
          </ButtonGroup>
        </Form>
      )}
    </FormContainer>
  )
}

const ButtonGroup = styled.div`
  margin: 16px auto 0;
  display: flex;
  justify-content: center;
`

const SubmitButton = styled.button`
  min-width: 170px;
`

const FieldContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 6px 12px;
  grid-template-columns: repeat(auto-fill, minmax(300px, auto));
`

export default WebinarRegisterForm
