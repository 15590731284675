import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const Panel = styled.div`
  max-width: 320px;
  background: ${(p) => p.theme.colors.primary};
  border: 1px solid ${(p) => p.theme.colors.secondary};
  padding: 26px 24px 32px;
  box-shadow: 0 12px 32px ${(p) => p.theme.colors.primary};
  border-radius: 8px;
  color: #fff;
  text-align: center;

  .heading {
    margin-top: 0;
  }

  .hook {
    margin-bottom: 32px;
  }
`

const CTA = () => {
  return (
    <Panel>
      <h2 className="heading heading--h3 heading--match">
        Free assessment.
        <br />
        No visa, no fee.
      </h2>
      <p className="hook">
        We're here to help with your immigration requirements.
      </p>
      <Link className="button button--onDark" to="/contact/">
        Contact Our Team
      </Link>
    </Panel>
  )
}

export default CTA
