import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const IomUk = () => {
  return (
    <Container className="container collapse">
      <StaticImage
        className="mapImage"
        src="../../img/uk-dot-map-iom.png"
        alt="Map of UK and Ireland with Isle of Man highlighted"
        placeholder="none"
        layout="constrained"
        width={868}
        quality={10}
        formats={['auto', 'webp', 'avif']}
        loading="eager"
        imgStyle={{
          objectPosition: '80% 100%',
          objectFit: 'contain',
        }}
      />
      <div className="container compact">
        <h3 className="heading heading--h2 heading--match">
          Why the Isle of Man
        </h3>
        <p className="hook">
          As part of the British Isles, the Isle of Man represents a unique
          opportunity for starting a new life, with additional benefits for
          businesses trading with the UK, Ireland, and more besides.
        </p>
        <p>
          The Isle of Man is situated just 30 miles (50km) west of the UK
          mainland with excellent transport links by air and sea. Its capital,
          Douglas has a rich history and is known today for being a global
          business hub, home to many international businesses.
        </p>
        <p>
          The Isle of Man has a lot to offer from a financial perspective. With
          efficient well-regulated banks and a straightforward tax regime
          offering low levels of taxation, the island has many attractions for
          entrepreneurs.
        </p>
        <p>
          As a &ldquo;Crown Dependency&rdquo; of the United Kingdom, residents
          of the Isle of Man are granted British Citizenship, making this route
          especially attractive to those planning to settle in the UK.
        </p>
      </div>
    </Container>
  )
}

export default IomUk

const Container = styled.div`
  background: ${(p) => p.theme.colors.primary};
  color: #fff;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    display: flex;
    align-items: center;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.large}px) {
    border-radius: 8px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  > .container {
    flex: 0 1 400px;
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-right: 16px;
      padding-top: 80px;
    }
    @media (min-width: ${(p) => p.theme.breakpoints.large}px) {
      margin-right: 32px;
      flex-basis: 480px;
    }
    padding-bottom: 96px;
    position: relative;
  }

  .mapImage {
    flex: 1 1 auto;
    max-height: 760px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
    }
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      margin-bottom: -200px;
      &::after {
        left: 0;
        height: 260px;
        background: linear-gradient(
          to top,
          ${(p) => p.theme.colors.primary}fa,
          transparent
        );
      }
    }
    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-right: -180px;
      &::after {
        top: 0;

        width: 380px;
        background: linear-gradient(
          to left,
          ${(p) => p.theme.colors.primary}fa,
          transparent
        );
      }
    }
  }
`
