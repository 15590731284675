import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import monogramSrc from '../img/visa-kings-global-monogram-light.svg'
import KingdomPlan from './kingdom-plan-panel'

const Overview = () => (
  <CitizenshipWrapper>
    <StaticImage
      src="../img/ithaca-cyprus.jpg"
      alt="Ithaca Cyprus Citizenship by Investment"
      placeholder="none"
      layout="fullWidth"
      formats={['auto', 'webp', 'avif']}
      loading="eager"
      style={{
        position: 'absolute',
        opacity: 0.3,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    />
    <KingdomPlansHeader>
      <div className="titlePanel">
        <img
          className="monogram"
          src={monogramSrc}
          alt="Visa Kings Global brand mark"
          width="500"
          height="344"
          style={{ height: 40, width: 'auto' }}
        />
        <h1 className="heading heading--h1 heading--match title">
          Second Citizenship
        </h1>
        <div className="subhead heading--h4 heading--secondary">
          Citizenship by investment services
        </div>
      </div>
    </KingdomPlansHeader>
    <KingdomPlansContent>
      <section className="container plansGroup">
        <header className="plansGroup__header container compact collapse">
          <h2 className="heading--h3 plansGroup__heading">
            Second citizenship packages
          </h2>
          <p className="hook">
            A second citizenship by investment provides an opportunity for
            individuals to become a citizen of another country without having to
            live there.
          </p>
        </header>
        <div className="kingdomPlanGrid">
          <KingdomPlan
            noKingdom
            title="Portugal"
            highlights="Safety and stability — ideal for raising families, conducting business or travelling"
            link="/citizenship/second-citizenship-portugal/"
          />
          <KingdomPlan
            noKingdom
            title="Cyprus"
            highlights="Cypriot citizenship by investment provides a fast and efficient second passport"
            link="/cyprus/citizenship-by-investment/"
          />
          <KingdomPlan
            noKingdom
            title="Dominica"
            highlights="One of the most affordable, trusted and flexible in citizenship programmes in the world"
            link="/citizenship/second-citizenship-dominica/"
          />
        </div>
        <div className="container compact collapse">
          <p>
            Our team can also assist with citizenship for other countries and
            provide advice regarding alternatives including Grenada, Saint
            Kitts, Malta, Spain and Greece.
            <br />
            <br />
            Please <Link to="/contact/">enquire for details</Link>.
          </p>
        </div>
      </section>
    </KingdomPlansContent>
  </CitizenshipWrapper>
)

const CitizenshipWrapper = styled('article')`
  padding-bottom: 80px;
  background-color: ${(p) => p.theme.colors.darkTint};
  text-align: center;

  position: relative;
`

const KingdomPlansContent = styled('div')`
  position: relative;
  z-index: 0;
  color: #fff;

  .plansGroup {
    margin-top: 60px;
    &__header {
      margin-bottom: 32px;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
    }

    &__heading {
      font-size: 2.8rem;
      font-weight: 600;
    }

    &__subheading {
      font-size: 2rem;
      margin-top: 8px;
      margin-bottom: 32px;
    }

    p > a {
      text-decoration-color: inherit;
    }
  }

  .kingdomPlanGrid {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(310px, auto));
    justify-content: center;
  }
`

const KingdomPlansHeader = styled('header')`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;

  .monogram {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -2px;
  }

  .subhead {
    margin-top: 6px;
  }
  .titlePanel {
    background: ${(p) => p.theme.colors.primary};

    @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
      margin-top: 60px;
      border-bottom-left-radius: ${(p) => p.theme.radius}px;
      border-bottom-right-radius: ${(p) => p.theme.radius}px;
      border-top: 4px solid ${(p) => p.theme.colors.secondary};
    }
    padding: 40px 48px 40px;
    box-shadow: 0 6px 2px rgba(0, 0, 0, 0.2);

    .title {
      margin: 0.1em 0 0;
    }
  }
`

export default Overview
