import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const Nav = () => {
  return (
    <Container>
      <List>
        <li>
          <Link to="/the-team/">Our Team</Link>
        </li>
        <li>
          <Link to="/uk/legal-services/">UK Legal Services</Link>
        </li>
        <li>
          <Link to="/service-benefits/">Service Benefits</Link>
        </li>
        <li>
          <Link to="/contact/">Contact Details</Link>
        </li>
      </List>
    </Container>
  )
}

const List = styled.ul`
  list-style: none;
  > li:first-of-type {
    margin-top: 0;
  }
  a {
    line-height: 32px;
    font-weight: 500;
  }
`

const Container = styled.div`
  background: ${(p) => p.theme.colors.lightTint};
  border-top: 1px solid #82829255;
  padding: 16px 24px;
  margin: 16px -32px 0;
  text-align: center;
`

export default Nav
