import styled from '@emotion/styled'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  background: ${(p) => (p.light ? '#fff' : p.theme.colors.lightTint)};
  border-bottom-left-radius: ${(p) => p.theme.radius}px;
  border-bottom-right-radius: ${(p) => p.theme.radius}px;

  margin: 40px auto;

  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
`

export const Header = styled.header`
  background: linear-gradient(
    to bottom,
    ${(p) => p.theme.colors.primaryIntense},
    ${(p) => p.theme.colors.primary} 48px
  );

  border-top: 4px solid ${(p) => p.theme.colors.secondary};
  color: #fff;

  .heading {
    margin: 0;
  }

  .highlight {
    margin-top: 16px;
  }

  padding: 24px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-left: 32px;
    padding-right: 32px;

    .highlight {
      margin-top: 12px;
    }
  }
`

export const Footer = styled.footer`
  padding: 20px 24px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  background: #f3f9f3;
  border-top: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  border-bottom-left-radius: ${(p) => p.theme.radius}px;
  border-bottom-right-radius: ${(p) => p.theme.radius}px;
  font-size: 1.7rem;

  > *:first-of-type {
    margin-top: 0;
  }
  > *:last-of-type {
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  padding: 24px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Panel = {
  Container,
  Header,
  Footer,
  Content,
}

export default Panel
