import styled from '@emotion/styled'
import { Link, graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import monogramSrc from '../img/visa-kings-global-monogram-light.svg'
import vkgLogoSrc from '../img/vkg-logo-stacked-on-dark.svg'
import ContactPanel from './contact-panel'

const FooterMainRow = styled.div`
  background: ${(p) => p.theme.colors.darkTint};
  color: #fff;

  > .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer__left {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      align-items: center;
    }
  }

  .companyRegInfo {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 24px;
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      text-align: center;
    }
  }

  .footer__right {
    @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
      margin-top: 32px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .vkgLogoWrapper {
    margin-bottom: 16px;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const CopyrightNotice = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          registeredCompany {
            name
          }
        }
      }
    }
  `)
  const { registeredCompany } = data.site.siteMetadata
  return (
    <div className="companyRegInfo">
      © {new Date().getFullYear()} {registeredCompany.name}.<br />
      All rights reserved.
    </div>
  )
}

const Footer = () => (
  <StyledFooter>
    <FooterMainRow>
      <div className="container">
        <div className="footer__left">
          <FooterNav />
          <CopyrightNotice />
        </div>
        <div className="footer__right">
          <div className="vkgLogoWrapper">
            <img
              src={vkgLogoSrc}
              alt="Visa Kings Global Logo"
              width="327"
              height="74"
              style={{ height: 44, width: 'auto' }}
              loading="lazy"
            />
          </div>
          <ContactPanel stack />
        </div>
      </div>
    </FooterMainRow>
    <div className="container">
      <div className="regulatoryWrapper">
        <div className="legalText">
          <p>
            Information affecting all users of this website can be found on our{' '}
            <Link to="/legal-notices/">Legal Notices</Link> page which you are
            deemed to have read and accepted.
          </p>
        </div>
        <div>
          <Link to="/covid-19/">COVID-19 (Coronavirus) Statement</Link>
        </div>
      </div>
    </div>
    <img
      className="monogram"
      src={monogramSrc}
      alt="Visa Kings Global Monogram"
      width="500"
      height="344"
      style={{ height: 32, width: 'auto' }}
      loading="lazy"
    />
  </StyledFooter>
)

const StyledFooter = styled.footer`
  content-visibility: auto;
  contain-intrinsic-size: 0 720px;
  @media (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    contain-intrinsic-size: 0 500px;
  }

  .monogram {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 60px;
    filter: brightness(0);
    opacity: 0.1;
  }

  .regulatoryWrapper {
    background: #fff;
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 1.5rem;

    .legalText {
      max-width: 460px;
      p {
        margin-top: 0;
      }
    }
  }
`

export default Footer

const NavList = styled.ul`
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  list-style: none;
  grid-gap: 4px 32px;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    grid-gap: 8px 48px;
  }

  li {
    margin: 0;
    line-height: 1.5;
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 400;

    a {
      display: inline-block;
      min-width: 72px;
    }
  }
`

const FooterNav = () => (
  <nav>
    <NavList>
      <li>
        <Link to="/uk/">UK Visa Services</Link>
      </li>
      <li>
        <Link to="/citizenship/">Citizenship</Link>
      </li>
      <li>
        <Link to="/usa-visa-services/">USA Visa Services</Link>
      </li>
      <li>
        <Link to="/service-benefits/">Service Benefits</Link>
      </li>
      <li>
        <Link to="/the-team/">The Team</Link>
      </li>
      <li>
        <a
          href="https://medium.com/visa-kings-global"
          rel="external noopener noreferrer"
          target="_blank"
        >
          Blog
        </a>
      </li>
      <li>
        <Link to="/privacy-policy/">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/legal-notices/">Legal Notices</Link>
      </li>
    </NavList>
  </nav>
)
