const countries = [
  {
    code: 'GB',
    name: 'United Kingdom',
    shortName: 'UK',
    shortNameDefiniteArticle: true,
    fullNameDefiniteArticle: true,
    fullName: 'United Kingdom of Great Britain and Northern Ireland',
    isEu: false,
    pagePath: '/uk/',
    services: [
      {
        title: 'Business',
        pagePath: '/uk/business-investor-visas/',
        navList: [
          {
            title: 'Innovator Visa',
            path: '/uk/innovator-visa/',
            meta: {
              status: 'updated',
            },
          },
          {
            title: 'Start-up Visa',
            path: '/uk/start-up-visa/',
            meta: {
              status: 'updated',
            },
          },
          {
            title: 'Skilled Worker Visa',
            path: '/uk/skilled-worker-visa/',
          },
          {
            title: 'Sole Representative Visa',
            path: '/uk/sole-representative-visa/',
          },
          {
            title: 'Business Entrepreneur Visa',
            path: '/uk/business-entrepreneur-visa/',
            meta: {
              status: 'new',
            },
          },
          {
            title: 'Isle of Man Tech Visa',
            path: '/uk/iom-tech-visa/',
            meta: {
              status: 'new',
            },
          },
          {
            title: 'Ireland Employer Permit Plan',
            path: '/ireland/employer-permit-plan/',
          },
          // {
          //   title: 'Visitor Visas',
          //   path: '/uk/visitor-visa/',
          // },
        ],
      },
      {
        title: 'Personal',
        pagePath: '/uk/',
        navList: [
          {
            title: 'Visitor Visas',
            path: '/uk/visitor-visa/',
          },
          {
            title: 'Student Visas',
            path: '/uk/student-visa/',
          },
          {
            title: 'Family Visas',
            path: '/uk/family-visa/',
          },
        ],
      },
    ],
  },
  {
    code: 'CY',
    name: 'Cyprus',
    fullName: 'Republic of Cyprus',
    fullNameDefiniteArticle: true,
    isEu: true,
    // pagePath: '/cyprus/',
    services: [
      {
        title: 'Investment',
        pagePath: '/cyprus/',
        navList: [
          {
            title: 'Residency by Investment',
            path: '/cyprus/residency-by-investment/',
          },
          {
            title: 'Citizenship by Investment',
            path: '/cyprus/citizenship-by-investment/',
          },
          // {
          //   title: 'Cyprus Start-up Plan',
          //   path: '/cyprus/',
          // },
        ],
      },
    ],
  },
  {
    code: 'IE',
    name: 'Ireland',
    fullName: 'Republic of Ireland',
    fullNameDefiniteArticle: true,
    isEu: true,
    services: [
      {
        title: 'Business',
        navList: [
          {
            title: 'Ireland Employer Permit Plan',
            path: '/ireland/employer-permit-plan/',
          },
        ],
      },
    ],
  },
  {
    code: 'US',
    name: 'United States',
    shortNameDefiniteArticle: true,
    fullNameDefiniteArticle: true,
    fullName: 'United States of America',
    shortName: 'USA',
    pagePath: '/usa-visa-services/',
    isEu: false,
    services: [
      {
        title: 'Business',
        pagePath: '/usa-visa-services/',
        navList: [
          {
            title: 'EB-5/E-1/E-2 Investor Visas',
            path: '/usa-visa-services/',
          },
          // {
          //   title: 'EB-5 Investor Visa',
          //   path: '/usa-visa-services/',
          // },
          // {
          //   title: 'E-1/E-2 Non-Immigrant Visas',
          //   path: '/usa-visa-services/',
          // },
        ],
      },
    ],
  },
  {
    code: 'PT',
    name: 'Portugal',
    fullName: 'Portuguese Republic',
    fullNameDefiniteArticle: true,
    isEu: true,
    services: [
      {
        title: 'Citizenship by Investment',
        pagePath: '/citizenship/second-citizenship-portugal/',
        navList: [
          {
            title: 'Portugal Second Citizenship Plan',
            path: '/citizenship/second-citizenship-portugal/',
          },
        ],
      },
    ],
  },
  {
    code: 'DM',
    name: 'Dominica',
    fullName: 'Commonwealth of Dominica',
    fullNameDefiniteArticle: true,
    isEu: false,
    services: [
      {
        title: 'Citizenship by Investment',
        pagePath: '/citizenship/second-citizenship-dominica/',
        navList: [
          {
            title: 'Dominica Second Citizenship Plan',
            path: '/citizenship/second-citizenship-dominica/',
          },
        ],
      },
    ],
  },
]

export default countries
