import styled from '@emotion/styled'
import React from 'react'

import ServiceEnquiryForm from './service-enquiry-form'

const EnquirySection = ({ heading, ...passthrough }) => {
  return (
    <Wrapper>
      {heading !== false &&
        (typeof heading === 'string' ? (
          <h3 className="heading heading--h2 heading--center">{heading}</h3>
        ) : (
          heading
        ))}
      <ServiceEnquiryForm {...passthrough} />
    </Wrapper>
  )
}

EnquirySection.defaultProps = {
  heading: 'Might this suit you?',
}

export default EnquirySection

const Wrapper = styled.div`
  border-top: 1px solid ${(p) => p.theme.colors.lightTintDarker};
  padding: 54px 0 120px;

  @media (max-width: ${(p) => p.theme.breakpoints.medium - 1}px) {
    background: ${(p) => p.theme.colors.lightTintDarker};
    padding: 32px 0 0;
  }

  .heading {
    margin-bottom: 1em;
  }
`
