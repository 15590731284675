import React from 'react'
import { Helmet } from 'react-helmet'

import { ContentSection } from 'components/layouts/ContentSection'
import Layout from 'components/layouts/PrimaryLayout'
import CallToAction from 'components/speak-your-lang-cta'

const PrivacyPolicyPage = () => (
  <Layout>
    <Helmet title="Privacy Policy" />
    <ContentSection>
      <div className="container compact">
        <header style={{ textAlign: 'center' }}>
          <h1 className="heading heading--h1">Privacy Policy</h1>
        </header>
        <BodyContent />
      </div>
    </ContentSection>
    <CallToAction />
  </Layout>
)

export default PrivacyPolicyPage

const BodyContent = () => (
  <div>
    <p className="hook">
      We value your privacy and care about the way in which your personal data
      is treated. This policy describes what personal data we collect about you,
      how we obtain it, how we use it, on what basis, how long we keep it for,
      who we share it with, how we protect it, and your rights regarding its
      control and processing.
    </p>
    <p>WHO THE SERVICE PROVIDERS ARE:</p>
    <p>
      In this policy ‘Canfields Law Limited’ refers to the service provider,
      which is an English law firm authorised and regulated by the Solicitors
      Regulation Authority. Visa Business Strategies is the promoter of the
      service and so far as applicable the terms of this policy are adopted by
      them.
    </p>
    <p>THE DATA WE COLLECT ABOUT YOU:</p>
    <p>
      Personal data’ is defined as any information relating to an individual,
      whether it relates to their private, professional or public life. It can
      be anything from a name, a home address, a photo, an email address, bank
      details, posts on social networking websites, medical information, social
      services records, records of criminal convictions, or a computer’s IP
      address.
    </p>
    <p>HOW IS YOUR PERSONAL DATA COLLECTED:</p>
    <p>
      We may collect personal data from you in the course of our business,
      including through your use of our website, when you contact or request
      information from us, when you engage our legal services or as a result of
      your relationship with one or more of our staff and clients.
    </p>
    <p>The personal data that we process includes:</p>
    <p>
      Basic data, such as your name (including prefix or title), the company you
      work for, your title or position and your relationship to a person;
    </p>
    <p>
      Contact data, such as your postal address, email address and phone number;
    </p>
    <p>Financial data, such as payment-related information;</p>
    <p>
      Identification and background data provided by you or collected as part of
      our business acceptance, employment or recruitment processes;
    </p>
    <p>
      Technical data, such as data from your visits to our website or in
      relation to materials and communications we send to you electronically;
    </p>
    <p>
      Data you provide to us for the purposes of attending meetings and events,
      including access and dietary requirements;
    </p>
    <p>
      Personal data provided to us by or on behalf of our clients or generated
      by us in the course of providing services to them, which may include
      special categories of data (to include records of criminal convictions);
      and
    </p>
    <p>
      Any other data relating to you which you may provide to us or ask us to
      obtain on your behalf.
    </p>
    <p>HOW WE OBTAIN YOUR PERSONAL DATA:</p>
    <p>We collect data from you:</p>
    <p>
      As part of our business acceptance processes and about you and others as
      necessary in the course of providing legal services;
    </p>
    <p>
      When you provide it to us, or interact with us directly, for instance
      engaging with our staff or registering on one of our websites;
    </p>
    <p>
      When monitoring our technology tools and services, including our websites
      and email communications sent to and from Canfields Law Limited; and
    </p>
    <p>
      We may collect or receive data about you from other sources, such as
      keeping the contact details we already hold for you accurate and up to
      date using publically available sources.
    </p>
    <p>HOW WE USE YOUR PERSONAL DATA:</p>
    <p>
      Canfields Law Limited collects and processes personal data about you in a
      number of ways, including through your use of our websites and in the
      provision of services by us. We use that data:
    </p>
    <p>
      To provide and improve our website, including auditing and monitoring its
      use;
    </p>
    <p>
      To promote our services, including sending legal updates, publications and
      details of events;
    </p>
    <p>To manage and administer our relationship with you and our clients;</p>
    <p>
      To provide and improve our services to you and to our clients, including
      handling the personal data of others on behalf of our clients;
    </p>
    <p>To provide data requested by you;</p>
    <p>
      To fulfill our legal, regulatory and risk management obligations,
      including establishing, exercising or defending legal claims; and
    </p>
    <p>For the purposes of employment and recruitment.</p>
    <p>To manage payments, fees and to collect and recover monies owed to us</p>
    <p>Asking you to leave a review or take a survey</p>
    <p>USE OF OUR WEBSITE:</p>
    <p>
      Some facilities on our website invite you to provide us with personal
      data, such as our email queries facilities. The purpose of these
      facilities is apparent at the point that you provide your personal data
      and we only use that data for those purposes.
    </p>
    <p>
      Our website uses Google Analytics, a web-based analytics tool that tracks
      and reports on the manner in which the website is used to help us to
      improve it. Google Analytics does this by placing small text files called
      ‘cookies’ on your device. The data that the cookies collect, such as the
      number of visitors to the site, the pages visited and the length of time
      spent on the site, is aggregated and therefore anonymous. Please also see
      ‘Marketing and other emails’ below. Our websites use other cookies too.
      Our full cookie policy can be viewed on our websites.
    </p>
    <p>
      You may refuse the use of cookies or withdraw your consent at any time by
      selecting the appropriate settings on your internet browser, but please
      note that this may affect your use and experience of our websites. By
      continuing to use our websites without changing your privacy settings, you
      are agreeing to our use of cookies.
    </p>
    <p>MARKETING AND OTHER EMAILS:</p>
    <p>
      We use personal data to understand whether you read the emails and other
      materials, such as legal publications, that we send to you, click on the
      links to the data that we include in them and whether and how you visit
      our website after you click on that link (immediately and on future
      visits). We do this by using software that places a cookie on your device
      which tracks this activity and records it against your email address.
      Removal of this cookie will not affect your experience on our websites.
      Please see ‘use of websites’ for more information on cookies and how to
      manage and remove them.
    </p>
    <p>
      If you receive marketing emails from us and no longer wish to do so, you
      may unsubscribe at any time either by using the unsubscribe facility in
      the marketing email or emailing our Data Protection Director, Jennifer
      Simmons by email to{' '}
      <a href="mailto:j.simmons@canfieldslaw.co.uk">
        j.simmons@canfieldslaw.co.uk
      </a>{' '}
      with the subject line ‘unsubscribe’.
    </p>
    <p>LEGAL UPDATES EMAILS:</p>
    <p>
      We do not sell, rent or otherwise make personal data commercially
      available to any third party. However, if you would like to receive
      marketing emails and legal updates from us in the future, please visit our
      website at{' '}
      <a href="http://www.canfieldslaw.co.uk">www.canfieldslaw.co.uk</a> and
      fill in your contact details in the "subscribe to our mailing list" box.
    </p>
    <p>MEETINGS, EVENTS AND SEMINARS:</p>
    <p>
      We will collect and process personal data about you in relation to your
      attendance at our offices or at an event or seminar organised by Canfields
      Law Limited or its business partners. We will only process and use special
      categories of personal data about your dietary or access requirements in
      order to cater for your needs and to meet any other legal or regulatory
      obligations we may have. We may share your data with IT and other service
      providers or business partners involved in organising or hosting the
      relevant event.
    </p>
    <p>LEGAL AND OTHER SERVICES:</p>
    <p>
      We collect, create, hold and use personal data in the course of and in
      connection with the services we provide to our clients. We will process
      identification and background data as part of our business acceptance,
      finance, administration and marketing processes, including anti-money
      laundering, conflict, reputational and financial checks. We will also
      process personal data provided to us by or on behalf of our clients for
      the purposes of the work we do for them. The data may be disclosed to
      third parties to the extent reasonably necessary in connection with that
      work. Please also see ‘Who we share your personal data with’ and ‘which
      countries we transfer your personal data to’ below.
    </p>
    <p>USING YOUR PERSONAL DATA:</p>
    <p>We use your personal data on the following bases:</p>
    <p>
      To perform a contract, such as engaging with an individual to provide
      legal or other services;
    </p>
    <p>To comply with legal and regulatory obligations;</p>
    <p>
      For the establishment, exercise or defence of legal claims or proceedings;
      and
    </p>
    <p>
      For legitimate business purposes. This means the interest of Canfields Law
      Limited in conducting and managing our business to enable us to give you
      the best service and the best and most secure experience. We make sure we
      consider and balance any potential impact on you (both positive and
      negative) and your rights before we process your personal data for our
      legitimate interests. We do not use your personal data for activities
      where our interests are overridden by the impact on you (unless we have
      your consent or are otherwise required or permitted to by law). Please see
      ‘How we use your personal data’ for more detail.
    </p>
    <p>HOW LONG WE KEEP YOUR PERSONAL DATA:</p>
    <p>
      Your personal data will be retained in accordance with our data retention
      policy which categorises all of the data held by Canfields Law Limited and
      specifies the appropriate retention period for each category of data.
      Those periods are based on the requirements of applicable data protection
      laws and the purpose for which the data is collected and used, taking into
      account legal and regulatory requirements to retain the data for a minimum
      period, limitation periods for taking legal action, good practice and
      Canfields Law Limited’ business purposes.
    </p>
    <p>
      If we provide legal services to you we will usually retain your personal
      data for 6 years, starting from when we cease to act for you. After 6
      years your personal data is destroyed, unless you have asked us to store
      other important legal documents for you.
    </p>
    <p>WHO WE SHARE YOUR PERSONAL DATA WITH:</p>
    <p>
      We may share your personal data with certain trusted third parties in
      accordance with contractual arrangements in place with them, including:
    </p>
    <p>
      Suppliers to whom we outsource certain support services such as word
      processing, photocopying, and translation;
    </p>
    <p>Our professional advisers and auditors;</p>
    <p>IT service providers to Canfields Law Limited;</p>
    <p>
      Third parties engaged in the course of the services we provide to clients
      and with their prior consent, such as barristers, expert witnesses,
      medical agencies, costs drafting agencies and technology service
      providers; and
    </p>
    <p>Third parties involved in hosting or organising events or seminars.</p>
    <p>
      Where necessary, or for the reasons set out in this policy, personal data
      may also be shared with regulatory authorities, courts, tribunals,
      government agencies and law enforcement agencies. While it is unlikely, we
      may be required to disclose your data to comply with legal or regulatory
      requirements. We will use reasonable endeavors to notify you before we do
      this, unless we are legally restricted from doing so.
    </p>
    <p>
      If in the future we re-organise or transfer all or part of our business,
      we may need to transfer your data to new Canfields Law Limited entities or
      to third parties through which the business of Canfields Law Limited will
      be carried out.
    </p>
    <p>
      Canfields Law Limited may use social media sites such as Facebook,
      LinkedIn, Instagram and Twitter. If you use these services, you should
      review their privacy policy for more information on how they deal with
      your personal data.
    </p>
    <p>
      We do not sell, rent or otherwise make personal data commercially
      available to any third party, except with your prior permission.
    </p>
    <p>HOW WE PROTECT YOUR PERSONAL DATA:</p>
    <p>
      We use appropriate security measures to prevent your personal data from
      being lost, used or accessed in an unauthorised way, altered or disclosed.
      We have procedures to deal with any suspected personal data breach and
      will notify you and any applicable regulator of a breach where we are
      legally required to do so.
    </p>
    <p>INTERNATIONAL TRANSFERS:</p>
    <p>
      In order to provide our legal services we do not generally need to
      transfer your personal data to locations outside the European Economic
      Area (‘the EEA’) for the purposes set out in this privacy policy.
    </p>
    <p>
      Where our third party service providers process personal data outside the
      EEA in the course of providing services to us, our written agreement with
      them will include appropriate measures to ensure that your personal data
      remains protected and secure in accordance with applicable data protection
      laws.
    </p>
    <p>YOUR LEGAL RIGHTS:</p>
    <p>
      The European Union’s General Data Protection Regulations and other
      applicable data protection laws provide certain rights for data subjects.
    </p>
    <p>
      You are entitled to request details of the data we hold about you and how
      we process it. You may also have a right in accordance with applicable
      data protection law to have it amended or deleted, to restrict our
      processing of that data, to stop unauthorised transfers of your personal
      data to a third party and, in some circumstances, to have personal data
      relating to you transferred to another organisation. You may also have the
      right to make a complaint in relation to our processing of your personal
      data with the Information Commissioner’s Office.
    </p>
    <p>
      If you object to the processing of your personal data, or if you have
      provided your consent to processing and you later choose to withdraw it,
      we will respect that choice in accordance with our legal obligations.
    </p>
    <p>
      Your objection (or withdrawal of any previously given consent) could mean
      that we are unable to perform the actions necessary to achieve the
      purposes set out above (see ‘How we use your personal data’) or that you
      may not be able to make use of the legal services offered by us. Please
      note that even after you have chosen to withdraw your consent we may be
      able to continue to process your personal data to the extent required or
      otherwise permitted by law, in particular in connection with exercising
      and defending our legal rights or meeting our legal and regulatory
      obligations.
    </p>
    <p>KEEPING YOUR DATA UP TO DATE:</p>
    <p>
      We must ensure that your personal data is accurate and up to date.
      Therefore, please advise us of any changes to your data as soon as
      possible.
    </p>
    <p>ENQUIRIES, COMPLAINTS &amp; REQUESTS:</p>
    <p>
      Canfields Law Limited has a Data Protection Director (‘DPD’). All
      enquiries, complaints and requests relating to personal data should be
      directed to the firm’s DPD, who is Jennifer Simmons. The DPD can be
      contacted at the firm’s offices by telephone (020 7483 8644), by post to
      Canfields Law Limited, Bentinck House, 3-8 Bolsover Street, London W1W
      6AB, or by email to{' '}
      <a href="mailto:j.simmons@canfieldslaw.co.uk">
        j.simmons@canfieldslaw.co.uk
      </a>
    </p>
    <p>
      Data controller: The firm’s IT systems are located in England and
      controlled by Canfields Law Limited. Canfields Law Limited is the data
      controller in relation to your personal data.
    </p>
    <p>
      Policy updates: This policy was last updated on 8 May 2018. Any changes to
      this policy will be notified to you as promptly as possible in accordance
      with our legal obligations.
    </p>
  </div>
)
